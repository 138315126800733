import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UserPerfil from "../../services/UserPerfil";
import VisitanteService from "../../services/visitante";
import {
  getItemsComprasPerfil,
  getItemsComprasPerfilProdutos,
} from "../../services/ComprasService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import CardComprasUserModal from "./compras/CardComprasUserModal";
import CardComprasUserModalGeral from "./compras/CardComprasUserModalGeral.js";
import BotaoComprvativo from "./compras/botaoVerComprovativo";
import visitante from "../../services/visitante";
import ListaLoteCompra from "./compras/ListaLoteCompra";
import { Link } from "react-router-dom";
const ComprasUserInfo = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const logado = user?.codigo;
  const { global } = useParams();
  // if(!logado){
  //   navigate("/login")
  // }

  useEffect(() => {
    VerOTipo();
    getItemsCompra();
    getItemsCompraProduto();
  }, []);

  const [verItemsDoCarrinho, setverItemsDoCarrinho] = useState([]);
  const getItemsCompra = async () => {
    await getItemsComprasPerfil(global, logado)
      .then((response) => {
        setverItemsDoCarrinho(response.data);
        // console.log(response.data[0].artigo.lote.geral);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const [verItemsDoCarrinhoProduto, setverItemsDoCarrinhoProduto] = useState(
    []
  );
  const getItemsCompraProduto = async () => {
    await getItemsComprasPerfilProdutos(global, logado)
      .then((response) => {
        setverItemsDoCarrinhoProduto(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const [verTipo, setverTipo] = useState();
  const VerOTipo = (data) => {
    setverTipo(data);
  };

  const [IdDoItem, setIdItem] = useState();
  const todos = [];
  const getIdItem = (data) => {
    setIdItem(data);
    todos.push(data);
  };

  return (
    <>
      <div>
        <div id="laranja_cima_text_info_compra" style={{ padding: "10px" }}>
          <h2>Items deste carrinho</h2>
          <BotaoComprvativo global={global} />
        </div>
        <div>
          <table className="responsiveTable">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th style={{ borderRadius: "0" }}>Imagem</th>
                <th>Descrição</th>
                <th>Qtd.</th>
                <th>V. Unitário</th>
                <th>V. Total</th>
                <th style={{ borderRadius: "0" }}>Ver mais</th>
              </tr>
            </thead>
            <tbody>
              {verItemsDoCarrinho.map((items, index) => (
                <ListaLoteCompra
                  index={index}
                  especie={items.artigo.lote.especie.especieAnimal}
                  preco={items.artigo.preco}
                  total={items.total}
                  loteCodigo={items.artigo.lote.codigo}
                  loteGeral={items.artigo.lote.geral}
                  loteExistente={items.artigo.lote.existente}
                  tipo={VerOTipo}
                  idItem={getIdItem}
                />
              ))}
              {verItemsDoCarrinhoProduto.map((items, index) => (
                <tr
                  className="verComprasInfoUser"
                  style={{
                    backgroundColor: "#fff",
                    color: "#854a28",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <td data-label="Imagem" className="qEp" id="fff">
                    
                  </td>
                  <td data-label="Lote de animais" className="qEp" id="fff">
                    Lote de {items.artigoProduto.produto.nome}
                  </td>
                  <td data-label="Quantidade" className="qEp" id="fff">
                    {items.quantidade}
                  </td>
                  <td data-label="Valor Unitário" className="qEp" id="fff">
                    {items.artigoProduto.preco}Kz
                  </td>
                  <td data-label="Valor Total" className="qEp" id="fff">
                    {items.total}Kz
                  </td>
                  <td data-label="Detalhes" className="qEp" id="fff">
                    <Link
                      to="/perfilComprasInfoItemsProdutos"
                      className="btn_ver_comp_user"
                    >
                      Ver
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ComprasUserInfo;
