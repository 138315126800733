import React, { useState, useEffect, useContext } from "react";
import VisitanteService from "../../services/visitante";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";

const ComprasUser = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const logado = user?.codigo;
  // if(!logado){
  //   navigate("/login")
  // }

  const [VerCompras, setVerCompras] = useState([
    {
      descricao: "Bovinos",
      fazenda: "Fazenda Borges",
      valorInicial: 5000,
      posicao: 4,
    },
    {
      descricao: "Caprinos",
      fazenda: "Fazenda Agrossol",
      valorInicial: 2600,
      posicao: 1,
    },
    {
      descricao: "Bovinos",
      fazenda: "Fazenda Borges",
      valorInicial: 89000,
      posicao: 4,
    },
 
  ]);


  const [itensPorPage, setitensPorPage] = useState([5]);
  const [correntPage, setcorrentPage] = useState([0]);

  const pages = Math.ceil(VerCompras.length / itensPorPage);
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerCompras.slice(startItens, endItens);

  return (
    <>
      <div>
        <table>
          <tr>
            <th>Imagem</th>
            <th>Especie</th>
            <th>Fazenda</th>
            <th>Valor Inicial</th>
            <th>Sua Posição</th>
            <th></th>
          </tr>
          {correntItens.map((compra) => (
            <tr>
              <td>
                <div className="ver_img_table_compras"></div>
              </td>
              <td>{compra.descricao}</td>
              <td>{compra.fazenda}</td>
              <td>{compra.valorInicial}Kz</td>  
              <td>{compra.posicao}ª</td>
              <td>
                <button className="btn_ver_comp_user">Ver mais</button>
              </td>
            </tr> 
          ))}
        </table>
        <section className="sect" style={{marginTop:'auto',  width:'100%' }}>
        {Array.from(Array(pages), (VerCompras, index) => {
          return (
            <button
              className="pagB"
              value={index}
              onClick={(e) => setcorrentPage(Number(e.target.value))}
            >
              {index + 1}
            </button>
          );
        })}
      </section> 
      </div>
    </>
  );
};

export default ComprasUser;
