//import "./styles.css"
import Menu from "../../components/menu/NavBar";
import InfoUser from "../../components/perfil/InfoUser";
import MenuPerfil from "../../components/perfil/MenuPerfil";
import LeilaoUser from "../../components/perfil/LeilaoUser";
const PerfilUserLeilao = () => {
  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar"></section>

      <section className="card_user">
        <div className="card_user2">
          <InfoUser />
          <div className="card_filho2">
            <div className="O_menu_do_usuario">
              <MenuPerfil />
            </div>
            <h1 className="h1_text_user">Leilões em que participou</h1>
            <div className="corpo_das_informacoes">
              <LeilaoUser />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PerfilUserLeilao;
