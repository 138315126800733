import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import LeilaoService from "../../services/leilao";
import Menu from "../../components/menu/NavBar";
import Cart from "../../components/vendas/Cart";
import Inscrever from "../../components/inscrever/Inscrever";
import Swal from "sweetalert2";
export function LeilaoItem() {
  const { codigo } = useParams();
  const navigate = useNavigate();

  const [Leilao, setLeilao] = useState([]);
  const [LotesRapidos, setLotesRapidos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    VerOLeilaoItem();
  });

  const VerOLeilaoItem = async () => {
    setLoading(true);
    // Pegar os animais
    await LeilaoService.getLance(codigo).then((response) => {
      setLeilao(response.data);
      // console.log(response.data);
    });
  };

  LeilaoService.getVerLoteRapidoPorId(codigo).then((response) => {
    setLotesRapidos(response.data);
  });

  const [ins, setins] = useState("");

  const verIncrito = (data) => {
    setins(data);
    console.log(data);
  };
  const inscrever = (estado, codigo) => {
    if (estado === "") {
      Swal.fire(
        "A sua inscricação está em processo de validação",
        "Por favor aguarde um momento enquanto validamos a sua inscrição",
        "warning"
      );
      return;
    }
    if (estado === "noExist") {
      Swal.fire(
        "Não está inscrito neste leilão",
        "Inscreva-se primeiro para poder dar lance neste leilão",
        "warning"
      );
    } else if (estado === "Reprovado") {
      Swal.fire(
        "A sua inscrição foi reprovada",
        "A sua inscrição não foi aceite",
        "warning"
      );
    } else if (estado === "Aprovado") {
      navigate("/lance/" + codigo);
    }
    console.log(estado);
  };
  //
  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-center">Leilão da Fazenda</h1>
          <Inscrever estadoIns={verIncrito} />
        </div>
      </section>

      <section className="animais">
        {loading && !Leilao && <p style={{ color: "red" }}></p>}
        <div className="m-2 ani_p">
          {Leilao.map((ver) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemAnimal/${ver.lote.imgUrl})`,
                }}
              ></div>
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {ver.lote.quantidade} animais da especie{" "}
                  {ver.lote.especie.especieAnimal}
                </h2>

                <p className="">
                  Valor Inicial:{" "}
                  {ver.preco.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}{" "}
                  Kz
                </p>
                {ins !== "Aprovado" ? (
                  <button
                    className="button botao_para_saber_mais"
                    onClick={() => inscrever(ins, ver.codigo)}
                    style={{ background: "#9ba236" }}
                  >
                    Dar lance
                  </button>
                ) : (
                  // <Inscrever estadoIns={verIncrito} />
                  <Link
                    to={`/lance/${ver.codigo}`}
                    className="button botao_para_saber_mais is-link"
                    style={{ background: "#9ba236" }}
                  >
                    Dar Lance
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default LeilaoItem;
