import Menu from "../../components/menu/NavBar";
import InfoUser from "../../components/perfil/InfoUser";
import MenuPerfil from "../../components/perfil/MenuPerfil";
import { Link } from "react-router-dom";
// import Compras from "../../components/perfil/Compras";
// import ComprasUser from "../../components/perfil/ComprasUser";
import ComprasUserInfo from "../../components/perfil/ComprasUserInfo";

const PerfilUserComprasInfo = () => {

  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar"></section>

      <section className="card_user">
        <div className="card_user2">
          <InfoUser />
          <div className="card_filho2">
            <div className="O_menu_do_usuario">
              <MenuPerfil />
            </div>
            <div className="informacao_da_compra_div">
              <Link to="/perfil" className="btn_voltar_info_compra">
                <div className="seta_branca_voltar"></div>
                <div className="seta_branca_voltar"></div>
                <div className="seta_branca_voltar"></div>
              </Link>{" "}
              <h1 className="h1_text_user">Informações da compra</h1>
              <div></div>
            </div>
            <div className="corpo_das_informacoes">
              <ComprasUserInfo />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PerfilUserComprasInfo;
