import { useEffect, useState } from "react";
import Menu from "../../components/menu/NavBar";
import usePagination from "../../components/pagination/Pagination";
import noticias from "../../services/noticias";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PesquisarNoticiasCard from "../../components/noticias/PesquisarNoticiasCard";
import { Pagination } from '@mui/material';



const Noticias = () => {
  const [VerNoticia, setVerNoticia] = useState([]);

  //PAGINACAO
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(VerNoticia.length / PER_PAGE);
  const _DATA = usePagination(VerNoticia, PER_PAGE);

  const VerAsNoticias = async () => {
   await noticias
      .getNoticias()
      .then((response) => {
        setVerNoticia(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    VerAsNoticias();
  }, []);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];


  return (
    <>
      <Menu />
      <main id="main">
        {/* <!-- ======= Blog Section ======= --> */}
        <div className="eri">
          <p>
            Saiba de todas as novidades da Cooperativa CWAM
          </p>
        </div>
        {/* Nova Noticia  */}
        <div className="principal">
          <div className="lado">
            {/*  */}
            {VerNoticia.map((noticias) => (
              <article
                className="entry"
                style={{ backgroundColor: "#fff", borderRadius: "10px" }}
              >
                <div
                  className="entry-img"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  <img
                    src={`${noticias.imgUrl}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <h2 className="entry-title">{noticias.titulo}</h2>

                <div className="entry-meta">
                  <ul>
                  <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <a href="#">CWAM</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <a href="#">{noticias.titulo}</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>
                      <time>
                      {new Date(  noticias.date).getDate() === new Date().getDate() &&
                          new Date(  noticias.date).getMonth() === new Date().getMonth() &&
                          new Date(  noticias.date).getFullYear() ===
                          new Date().getFullYear() ? (
                          <>
                            Hoje às {new Date(  noticias.date).getHours()}:
                            {new Date(  noticias.date).getMinutes()} (
                            {new Date(new Date() - new Date(  noticias.date)).getHours() - 1 >
                              0 ? (
                              <>
                                {new Date(new Date() - new Date(  noticias.date)).getHours() -
                                  1}
                                h
                              </>
                            ) : (
                              ""
                            )}{" "}
                            {new Date(new Date() - new Date(  noticias.date)).getMinutes()} min
                            atrás )
                          </>
                        ) : (
                          <>
                            {new Date(  noticias.date).getDate()} de {" "}
                            {meses[new Date(  noticias.date).getMonth()]} de{" "}
                            {new Date(  noticias.date).getFullYear()} às{" "}
                            {new Date(  noticias.date).getHours()}:
                            {new Date(  noticias.date).getMinutes()}
                          </>
                        )}
                      
                       </time>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                  <p style={{ lineHeight: '1.5', wordBreak: 'break-word', color: "#111" }} dangerouslySetInnerHTML={{ __html: noticias.conteudo }}></p>
                  <div className="read-more">
                    <Link to={`/viewnoticias/${noticias.codigo}`}>
                      Ver mais
                    </Link>
                  </div>
                </div>
              </article>
            ))}
            {/*  */}

            {/*  */}
            <nav className="blog-pagination justify-content-center d-flex" id="navigateP">
              <ul className="pagination">
                <li className="page-item">
                  <Pagination
                    count={count}
                    size="small"
                    // className="page-link"
                    page={page}
                    variant="text"
                    color="success"
                    shape="circular"
                    onChange={handleChange}
                  />
                  {/*  */}
                </li>
              </ul>
            </nav>
            {/* </div> */}
          </div>

          <div className="direita">
            <PesquisarNoticiasCard />
          </div>
        </div>
        {/*  */}
      </main>
      {/* <!-- End #main --> */}
    </>
  );
};

export default Noticias;
