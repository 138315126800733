import api from "./api";

export const visitanteEmail = async (email) => {
  try {
    return await api.get("visitanteEmail/" + email);
  } catch (error) {
    console.log(error.message);
  }
};
export const createPassworReset = async (data) => {
  try {
    return await api.post("criar_passwordReset", data);
  } catch (error) {
    console.log(error.message);
  }
};

export const enviarCodigoVisitante = async (codigo, visitante) => {
  try {
    return await api.get(`verificarPass/${codigo}/${visitante}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const putPasswordReset = async (visitante, data) => {
  try {
    return await api.put(`passwordReset/${visitante}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
