import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import CooperadoresService from "../../service/CooperadoresService";
// import * as messages from "../../components/message/toastr";


const TabCooperador = () => {
  // Buscar por ID
  const { codigo } = useParams();
  //SALVAR
  const [cooperador, setCooperador] = useState({
    imgUrl: "",
    nome: "",
    email: "",
    telefone: "",
    bi: "",
    senha: "",
    endereco: "",
    website: "",
  });
  const [imgUrl, setImgUrl] = useState("");

  const { nome, email, telefone, bi, senha, endereco, website } = cooperador;
  //
  const onInputChange = (e) => {
    setCooperador({ ...cooperador, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateCooperador = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("nome", nome);
    formData.append("email", email);
    formData.append("telefone", telefone);
    formData.append("bi", bi);
    formData.append("senha", senha);
    formData.append("endereco", endereco);
    formData.append("website", website);

    //
    // if (codigo) {
    //   await CooperadoresService.update(formData)
    //     .then((response) => {
    //       messages.mensagemSucesso("Actualizado com sucesso", response.data);
    //       // retrievecooperadores();
    //     })
    //     .catch((error) => {
    //       messages.mensagemErro("Erro ao actualizar", error);
    //     });
    // } else {
    //   //
    //   await CooperadoresService.create(formData)
    //     .then((response) => {
    //       messages.mensagemSucesso("Salvo com sucesso", response.data);
    //       // retrievecooperadores();
    //     })
    //     .catch((error) => {
    //       messages.mensagemErro("Erro ao salvar", error);
    //     });
    // }
  };
  //
  // useEffect(() => {
  //   loadCooperador();
  // }, []);
  //
  // const loadCooperador = () => {
  //   if (codigo) {
  //     CooperadoresService.get(codigo)
  //       .then((response) => {
  //         setCooperador(response.data);
  //       })
  //       .catch((error) => {
  //         messages.mensagemErro("Erro ao buscar dados");
  //       });
  //   }
  // };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return <h3 style={{ color: "#fff" }}>Actualizando </h3>;
    } else {
      return <h3 style={{ color: "#fff" }}>Cadastrar </h3>;
    }
  };
  //
  //
  return (
    <div>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateCooperador(e)}
      >
        {/* COOPERADOR */}
        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">
                Carregar Foto
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="imgUrl"
                className="form-control cc-cvc"
                onChange={(e) => showPreview(e)}
                hidden
              ></input>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                name="nome"
                type="text"
                className="form-control cc-exp"
                placeholder="Nome"
                value={nome}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <input
                name="email"
                type="email"
                className="form-control cc-exp"
                placeholder="Email"
                value={email}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                type="text"
                name="bi"
                className="form-control cc-exp"
                placeholder="BI"
                value={bi}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="input-group">
              <input
                id=""
                name="telefone"
                type="text"
                class="form-control cc-cvc"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                name="endereco"
                type="text"
                class="form-control"
                placeholder="Endereço"
                value={endereco}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <input
                name="website"
                type="text"
                className="form-control"
                placeholder="Website ( Opcional )"
                value={website}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="fa fa-asterisk"></i>
                </div>
                <input
                  name="senha"
                  type="password"
                  class="form-control"
                  placeholder="Senha"
                  value={senha}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-asterisk"></i>
              </div>
              <input
                name=""
                type="password"
                class="form-control"
                placeholder="Confirmar Senha"
              />{" "}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span className="lar la-save">Salvar</span>
          </button>
        </div>
        {/*  */}
        {/* FIM COOPERADOR */}
      </form>
    </div>
  );
};

export default TabCooperador;
