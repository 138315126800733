import React, { useState, useEffect } from "react";
import {Link, useParams } from "react-router-dom";
import SobreAnimalService from "../../../services/sobreAnimal";
import Menu from "../../../components/menu/NavBar";
// import "./styles.css";

const SobreAnimais = () => {
  //
  const [sobreAnimal, setSobreAnimal] = useState({
    peso: "",
    sexoAnimal: "",
    nome: "",
    imgUrl: "",
    raca: "",
    idade: "",
    proviniente: "",
  });

  const { codigo } = useParams();
  
  //
  useEffect(() => {
    loadOurService();
  });
  //
  const loadOurService = () => {
    if (codigo) {
      SobreAnimalService.getCodigo(codigo).then((response) => {
        setSobreAnimal(response.data);
        // console.log(response.data, "SOBRE ANAIMAIS")
      });
    }
  };
  //
  return (<>
     <Menu />
    {/* <div id="borda_img_p"></div> */}
    <section className="fundo_entrar">
      <div className="welcome-leilao">
        <h1 className="title has-text-centeder">
          Mais detalhes sobre o animal
        </h1>
      </div>
    </section>
    <section className="corpo f_corpo">
      <div className="fotografiaAnimal">
        <img className="img-animal"
          src={`${sobreAnimal.imgUrl}`}
          alt="" />
         </div>
      <div className="conteudo">
        <h2 className="detalhes_animal">Detalhes do Animal</h2>

        <div className="legenda_do_lance">
          <p>Raça: {sobreAnimal.raca.racaAnimal}</p>
          <p>Idade: <strong>{sobreAnimal.idade} </strong></p>
          <p>Sexo: <strong>{sobreAnimal.sexoAnimal}</strong></p>
          <p>Peso: <strong>{sobreAnimal.peso} kg</strong></p>
          <p>Fazenda: <strong></strong></p>
          <Link to="/animais" className="button is-link" style={{ background: '#9ba236', marginTop:"10px"}}>Voltar</Link>
        </div>
      </div>
    </section>
    
    <section className="descricao">
      <div className="info_animal">
        <h1>
          {sobreAnimal.nome}
        </h1>
        <p>
          {sobreAnimal.descricao}
        </p>
      </div>
    </section>

  </>);

};

export default SobreAnimais;
