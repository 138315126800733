import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import { getLoteGeralCompras, getRacas}  from "../../services/ComprasService";
import ProdutoService  from "../../services/vendas";

const ComprasUserInfoItemsProdutos = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const logado = user?.codigo;
  const { lote } = useParams();
  // if(!logado){
  //   navigate("/login")
  // }

  useEffect(() => {
    getProdutos()
  });

  const [VerProdutos, setVerProdutos] = useState([]);
  const getProdutos = async () => {
    await ProdutoService.getProdutoVendaCodigo(lote)
      .then((response) => {
        setVerProdutos(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return ( 
    <>
      <div className="info_doLote">       
        <div className="imgDOGeral">
          {/* <img src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemCooperador/${VerProdutos?.produto?.imgUrl}`} alt="" style={{objectFit: "cover", width: "100%", height: "100%", borderRadius: "10px"}} /> */}
          <img src={`${VerProdutos?.produto?.imgUrl}`} alt="" style={{objectFit: "cover", width: "100%", height: "100%", borderRadius: "10px"}} />
        </div>
        <div className="Legaenda_doLoteGeralInfo">
          <h1>Detalhes</h1>
          <table style={{backgroundColor: "transparent"}} className="divVerLoteGeralCompra">
            <tr style={{backgroundColor: "transparent"}}>
              <td>Decrição: </td> 
              <td>{VerProdutos?.produto?.nome}</td>
            </tr>
            <tr style={{backgroundColor: "transparent"}}>
              <td>Categoria: </td> 
              <td>{VerProdutos?.produto?.categoria?.nome}</td>
            </tr>
            <tr>
              <td>quantidade: </td>
              <td>{VerProdutos.quantidade_fixa}</td>
            </tr>
            <tr>
              <td>Preço: </td>
              <td>{VerProdutos.preco}Kz</td>
            </tr>
            <tr>
              <td>Descrição: </td>
              <td>{VerProdutos?.produto?.descricao}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default ComprasUserInfoItemsProdutos;
