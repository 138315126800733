import axios from "axios";

const url = "https://webcwam-polished-dust-7716.fly.dev/api/";
const getAll = () => {
  return axios.get(url + "/formationNormals");
};

const create = (data) => {
  return axios.post(url+"cadastrar_cooperador", data);  
};

const enviarMensagem = (data) => {
  return axios.post(url+"enviar_mensagem", data);  
};
const createCarrinho = (data) => {
  // return axios.post("https://webcwam-polished-dust-7716.fly.dev/api/create_cart", data);
  return axios.post(url+"criar_carrinho", data);
};


//Imagem
const getImagem = (imagem) => {
  return axios.get(url+`cadastrar_cooperador/${imagem}`);
};
//
const getCodigo = (codigo) => {
  return axios.get(url+`animal/${codigo}`);
};
// informações sobre a fazenda

const getCodigoFazenda = (codigo) => {
  return axios.get(url+`fazenda/${codigo}`);
};

// -----------
const getLance = (codigo) => {
  return axios.get(`https://webcwam-polished-dust-7716.fly.dev/api/ItemPLeilao/${codigo}`);
};

// Aumentar a Quantidade do item no carrinho
const updateQtdCartAument = (codigo) => {
  return axios.get(url+`carrinho_qtd_aument/${codigo}`);
};
// Aumentar a Quantidade do item no carrinho
// const GetvalorCart = () => {
//   return axios.get(url+`carrinho/visitante=1`);
// };

//--------------------- Leilão -------------------------
const getAnimalItem = (codigo) => {
  return axios.get(url+`ItemDetail/${codigo}`);
};
const getLicitadores = (cod_item) => {
  return axios.get(url+`lances/todoslances/${cod_item}`);
};

const getUltimoLance = (cod_item) => {
  return axios.get(url+`lances/max/${cod_item}`);
};

const postLance = (data) => {
  return axios.post(url+`lances`, data);
};


// ---------------- // ------------------- // -----------------

const getLeilao = () => {
  return axios.get(url+`leilaos`);
};

// Diminuir a quantidade do item no carrinho
const updateQtdDiminuir = (codigo) => {
  return axios.get(url+`carrinho_qtd_diminuir/${codigo}`);
};

//
const remove = (codigo) => {
  return axios.delete(url+`cadastrar_cooperador/${codigo}`);
};
const removeItemCart = (codigo) => {
  return axios.delete(url+`carrinho/${codigo}`);
};
//
const sendComprovativo = (data) => {
  return axios.post(url+`criar_comprovativo`,data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getAll, create, createCarrinho, getCodigo, getImagem, updateQtdDiminuir, updateQtdCartAument, remove, removeItemCart, sendComprovativo, getLeilao, getLance,getAnimalItem, getLicitadores, getUltimoLance, postLance, getCodigoFazenda, enviarMensagem };