import React, { useContext } from "react";
import Menu from "../../components/menu/NavBar";
import InfoUser from "../../components/perfil/InfoUser";
import MenuPerfil from "../../components/perfil/MenuPerfil";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import Swal from "sweetalert2";
// import Compras from "../../components/perfil/Compras";
// import ComprasUser from "../../components/perfil/ComprasUser";
import ComprasUserInfoItems from "../../components/perfil/ComprasUserInfoItems";

const PerfilUserComprasInfo = () => {
  const { logout, user } = useContext(AuthContext);
  const handleLogout = () => {
    Swal.fire({
      text: "Deseja mesmo Sair?",
      showCancelButton: true,
      confirmButtonColor: "#9ba235",
      cancelButtonColor: "#b46739",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };
  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar"></section>

      <section className="card_user" style={{ width: "100%" }}>
        <div
          className="card_user2"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {/* <InfoUser /> */}
          <div className="card_filho2" style={{ width: "100%" }}>
            <div className="O_menu_do_usuario">
              <div className="menu_do_user">
                <ul>
                  <li>
                    <Link to={`/perfil`} className="menu_color_link_a">
                      Compras
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={`/perfilLeilao`} className="menu_color_link_a">
                      Leilão
                    </Link>
                  </li> */}
                  <li>
                    <Link to={`/perfilDados`} className="menu_color_link_a">
                      Perfil
                    </Link>
                  </li>
                </ul>
                <Link to="" onClick={handleLogout} className="sair_user">
                  sair
                </Link>
              </div>{" "}
            </div>
            <div className="informacao_da_compra_div">
              <Link to="/perfil" className="btn_voltar_info_compra">
                <div className="seta_branca_voltar"></div>
                <div className="seta_branca_voltar"></div>
                <div className="seta_branca_voltar"></div>
              </Link>{" "}
              <h1 className="h1_text_user">Informações do lote05</h1>
              <div></div>
            </div>
            {/* #999f55d3 */}
            <div className="corpo_das_informacoes" style={{backgroundColor: "transparent", boxShadow: "0 0 0 transparent", overflow: "hidden", height: "580px"}}>
              <ComprasUserInfoItems />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PerfilUserComprasInfo;
