import { useEffect, useState } from "react";
import EntregaService from "../../services/entregas";

function CardEntregaProduto(props) {
  const env = (d) => {
    props.mandarPai(d);
  };

  const env2 = (d2) => {
    if (d2 !== undefined) {
      props.mandarPai2(d2);
    } else {
      d2 = 0;
    }
  };
  const env3 = (d2) => {
    if (d2 !== undefined) {
      props.mandarPai3(d2);
    } else {
      d2 = 0;
    }
  };

  const env4 = (d2) => {
    if (d2 !== undefined) {
      props.mandarPai4(d2);
    } else {
      d2 = 0;
    }
  };
  const env5 = (d2) => {
    if (d2 !== undefined) {
      props.mandarPai5(d2);
    } else {
      d2 = 0;
    }
  };

  const [novo, setNovo] = useState([]);

  // Ver a distancia de cada provincia
  // const verDes = () => {
  //   switch (props.chave) {
  //     case 0:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 1:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env2(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 2:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env3(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 3:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env4(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 4:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env5(response.data * props.precoTransporte);
  //       });
  //       break;

  //     default:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env5(response.data * props.precoTransporte);
  //       });
  //       break;
  //   }
  // };
  const verDes = () => {
    switch (props.chave) {
      case 0:
        EntregaService.getInicialFinal(
          props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
            .codigo,
          props.provinciaCodigo
        ).then((response) => {
          setNovo(response.data);
          env(response.data * props.precoTransporte);
        });
        break;

      case 1:
        EntregaService.getInicialFinal(
          props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
            .codigo,
          props.provinciaCodigo
        ).then((response) => {
          setNovo(response.data);
          env2(response.data * props.precoTransporte);
        });
        break;

      case 2:
        EntregaService.getInicialFinal(
          props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
            .codigo,
          props.provinciaCodigo
        ).then((response) => {
          setNovo(response.data);
          env3(response.data * props.precoTransporte);
        });
        break;

      case 3:
        EntregaService.getInicialFinal(
          props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
            .codigo,
          props.provinciaCodigo
        ).then((response) => {
          setNovo(response.data);
          env4(response.data * props.precoTransporte);
        });
        break;

      case 4:
        EntregaService.getInicialFinal(
          props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
            .codigo,
          props.provinciaCodigo
        ).then((response) => {
          setNovo(response.data);
          env5(response.data * props.precoTransporte);
        });
        break;

      default:
        EntregaService.getInicialFinal(
          props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
            .codigo,
          props.provinciaCodigo
        ).then((response) => {
          setNovo(response.data);
          env5(response.data * props.precoTransporte);
        });
        break;
    }
  };
  // const verDes = () => {
  //   switch (props.chave) {
  //     case 0:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 1:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env2(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 2:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env3(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 3:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env4(response.data * props.precoTransporte);
  //       });
  //       break;

  //     case 4:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env5(response.data * props.precoTransporte);
  //       });
  //       break;

  //     default:
  //       EntregaService.getInicialFinal(
  //         props.VerDist[props.chave].artigoProduto.produto.fazenda.municipio.provincia
  //           .codigo,
  //         props.provinciaCodigo
  //       ).then((response) => {
  //         setNovo(response.data);
  //         env5(response.data * props.precoTransporte);
  //       });
  //       break;
  //   }
  // };
  const total = props.precoTransporte * novo;
  useEffect(() => {
    verDes();
  });

  return (
    <>
      <tr>
        {/* <td>{props.ProdutoNome}</td>
        <td>{props.animalNome}</td>
        <td>{props.provinciaDestino}</td>
        <td>{props.modelo}</td> */}
        <td>{props.fazendaNome}</td>
        <td>{props.ProdutoNome}</td>
        <td>{props.provinciaDestino}</td>
        <td>{props.modelo}</td>
        <td>
          {novo}
          Km
        </td>
        <td>Produto</td>
        <td>
          {total.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          })}
          kz
        </td>
      </tr>
    </>
  );
}
export default CardEntregaProduto;
