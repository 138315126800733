import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EntregaService from "../../services/entregas";
import CardEntrega from "../../components/entregas/CardEntrega";
import CardEntregaProduto from "../../components/entregas/CardEntregaProduto";
import { AuthContext } from "../../contexts/Auth";
import Menu from "../../components/menu/NavBar";
import VendaService from "../../services/vendas";

function PaiEntrega() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const userlogado = user?.codigo


  const [VerItemCart, setVerItemCart] = useState([]);
  const [VerItemCarProduto, setVerItemCarProduto] = useState([]);
  const [verTransporteUser, setverTransporteUser] = useState();

  const [TransporteUser, setTransporteUser] = useState([]);
  const [Origem, setOrigem] = useState([]);
  const [Origem3, setOrigem3] = useState([]);



  useEffect(() => {
    VerGlobal()
  });

  const [codGlobal, setcodGlobal] = useState()
  const VerGlobal = async () => {
    await VendaService.getCarrinhoGlobal(userlogado)
      .then((response) => {
        verExisteOUserTotal(response.data[0].codigo)
      })
  }
  const verExisteOUserTotal = async (id) => {
    await EntregaService.VerUserExisteTransporte(id).then(
      (response) => {
        setTransporteUser(response.data);
        VerOsItemsCart(response.data.codigo);
        VerOsItemsCartProduto(response.data.codigo);
        setverTransporteUser(response.data.codigo);
      }
    );
  };

  const VerOsItemsCart = async (id) => {
    await EntregaService.getUserCart(userlogado)
      .then((response) => {
        setVerItemCart(response.data);
        setOrigem(response.data);
        console.log(response.data[0]);
        EntregaService.VerTransporteUser(id).then((response) => {
          setTransporteUser(response.data);
        });
      })
      .catch(() => {
        // Swal.fire(
        //   "Erro de Conexão",
        //   "Por favor verifique a sua internet",
        //   "error"
        // );
      });
  };

  const VerOsItemsCartProduto = (id) => {
    EntregaService.getUserCartProduto(userlogado)
      .then((response) => {
        setVerItemCarProduto(response.data);
        setOrigem3(response.data);
        EntregaService.VerTransporteUser(id).then((response) => {
          setTransporteUser(response.data);
        });
      })
      .catch(() => {
        // Swal.fire(
        //   "Erro de Conexão",
        //   "Por favor verifique a sua internet",
        //   "error"
        // );
      });
  };

  const SolicitarTransporte = (preco) => {
    const data = {
      totalTransporte: preco,
    };
    AddTotalTransporte(verTransporteUser, data);
  };

  const AddTotalTransporte = async (cod, data) => {
    await EntregaService.cadAddTotal(cod, data)
      .then(() => {
        navigate("/pagamentos");
      })
      .catch((err) => {
        Swal.fire({
          title: "Algo de Errado" + err,
          icon: "error",
        });
      });
  };

  const ContinuarSemTransporte = () => {
    Swal.fire({
      title: "Deseja mesmo sair sem o serviço de transporte?",
      text: "Se continuar, não vai poder ter o serviço de transporte",
      showCancelButton: true,
      confirmButtonColor: "#9ba235",
      cancelButtonColor: "#b46739",
      confirmButtonText: "Sim, continuar sem transporte",
      cancelButtonText: "Continuar com transporte",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/pagamentos");
      }
    });
  };

  // ////////////////////-------------------------
  const [dadoFA, setdadoFA] = useState(0);
  const dadosFilhoA = (dados) => {
    setdadoFA(dados);
  };
  const [dadoFA2, setdadoFA2] = useState(0);
  const dadosFilhoA2 = (dados2) => {
    setdadoFA2(dados2);
  };
  const [dadoFA3, setdadoFA3] = useState(0);
  const dadosFilhoA3 = (dados) => {
    setdadoFA3(dados);
  };
  const [dadoFA4, setdadoFA4] = useState(0);
  const dadosFilhoA4 = (dados2) => {
    setdadoFA4(dados2);
  };
  const [dadoFA5, setdadoFA5] = useState(0);
  const dadosFilhoA5 = (dados) => {
    setdadoFA5(dados);
  };

  // ////////////////////-------------------------
  const [dadoFP, setdadoFP] = useState(0);
  const dadosFilhoP = (dados) => {
    setdadoFP(dados);
  };
  const [dadoFP2, setdadoFP2] = useState(0);
  const dadosFilhoP2 = (dados2) => {
    setdadoFP2(dados2);
  };
  const [dadoFP3, setdadoFP3] = useState(0);
  const dadosFilhoP3 = (dados) => {
    setdadoFP3(dados);
  };
  const [dadoFP4, setdadoFP4] = useState(0);
  const dadosFilhoP4 = (dados2) => {
    setdadoFP4(dados2);
  };
  const [dadoFP5, setdadoFP5] = useState(0);
  const dadosFilhoP5 = (dados) => {
    setdadoFP5(dados);
  };
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Serviço de entrega</h1>
        </div>
      </section>

      <div>
        <div>
          <h1
            style={{
              fontSize: "25px",
              color: "#fff",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            Total de Transporte
          </h1>
        </div>
        <div className="doisFinal" id="mostrar-total-d-transporte">
          <div className="tabela_final">
            <div className={`verde_total_entrega`}>
              <div className="tabela_final_t">
                <table>
                  <tr>
                    <th style={{ borderTopLeftRadius: "10px" }}>Fazenda</th>
                    {/* <th>Origem</th> */}
                    <th>Produto</th>
                    <th>Destino</th>
                    <th>Transporte</th>
                    <th>Distância</th>
                    <th>Categoria</th>
                    <th style={{ borderTopRightRadius: "10px" }}>
                      Valor da entrega
                    </th>
                  </tr>
                  {VerItemCart.map((animais, index) => (
                    <CardEntrega
                      fazendaNome={animais.artigo.lote.fazenda.nome}
                      fazendaCodigo={animais.artigo.lote.fazenda.codigo}
                      animalNome={
                        animais.artigo.lote.fazenda.municipio.provincia.nome
                      }
                      provinciaDestino={TransporteUser.municipio.provincia.nome}
                      provinciaCodigo={
                        TransporteUser.municipio.provincia.codigo
                      }
                      modelo={TransporteUser.transportador.modelo}
                      precoTransporte={
                        TransporteUser.transportador.precoTransporte
                      }
                      totalTransporte={TransporteUser.totalTransporte}
                      VerDist={Origem}
                      chave={index}
                      mandarPai={dadosFilhoA}
                      mandarPai2={dadosFilhoA2}
                      mandarPai3={dadosFilhoA3}
                      mandarPai4={dadosFilhoA4}
                      mandarPai5={dadosFilhoA5}
                    />
                  ))}

                  {/* {VerItemCarProduto.map((animais, index) => (
                    <CardEntregaProduto
                      ProdutoNome={animais.artigoProduto.produto.nome}
                      animalNome={
                        animais.artigoProduto.produto.municipio.provincia.nome
                      }
                      provinciaDestino={TransporteUser.municipio.provincia.nome}
                      provinciaCodigo={
                        TransporteUser.municipio.provincia.codigo
                      }
                      modelo={TransporteUser.transportador.modelo}
                      precoTransporte={
                        TransporteUser.transportador.precoTransporte
                      }
                      totalTransporte={TransporteUser.totalTransporte}
                      VerDist={Origem3}
                      chave={index}
                      mandarPai={dadosFilhoP}
                      mandarPai2={dadosFilhoP2}
                      mandarPai3={dadosFilhoP3}
                      mandarPai4={dadosFilhoP4}
                      mandarPai5={dadosFilhoP5}
                    />
                  ))} */}



                  {VerItemCarProduto.map((animais, index) => (
                    <CardEntregaProduto
                      // fazendaNome={animais.artigo_produto.produto.fazenda.nome}
                      // fazendaCodigo={animais.artigo_produto.produto.fazenda.codigo}
                      ProdutoNome={animais.artigoProduto.produto.nome}
                      provinciaDestino={TransporteUser.municipio.provincia.nome}
                      provinciaCodigo={
                        TransporteUser.municipio.provincia.codigo
                      }
                      modelo={TransporteUser.transportador.modelo}
                      precoTransporte={
                        TransporteUser.transportador.precoTransporte
                      }
                      totalTransporte={TransporteUser.totalTransporte}
                      VerDist={Origem3}
                      chave={index}
                      mandarPai={dadosFilhoP}
                      mandarPai2={dadosFilhoP2}
                      mandarPai3={dadosFilhoP3}
                      mandarPai4={dadosFilhoP4}
                      mandarPai5={dadosFilhoP5}
                    />
                  ))}
                  <tr className="g_total-1">
                    <td colSpan={5}></td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      Total de transporte:
                    </td>
                    <td>
                      {(
                        dadoFA +
                        dadoFA2 +
                        dadoFA3 +
                        dadoFA4 +
                        dadoFA5 +
                        dadoFP +
                        dadoFP2 +
                        dadoFP3 +
                        dadoFP4 +
                        dadoFP5
                      ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      Kz
                    </td>
                  </tr>
                </table>
                <div className="total_trnasporte">
                  {/* <h1>Total de transporte</h1> */}
                  <div className="tot_letra_transporte">
                    <p>
                      <button
                        onClick={() =>
                          SolicitarTransporte(
                            dadoFA +
                            dadoFA2 +
                            dadoFA3 +
                            dadoFA4 +
                            dadoFA5 +
                            dadoFP +
                            dadoFP2 +
                            dadoFP3 +
                            dadoFP4 +
                            dadoFP5
                          )
                        }
                      >
                        Continuar com transporte
                      </button>
                      <button
                        style={{
                          backgroundColor: "#888",
                          // marginLeft: "20px",
                        }}
                        onClick={() => ContinuarSemTransporte()}
                      >
                        Continuar sem transporte
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PaiEntrega;
