import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ServicosServices from "../../services/servicos";
import { Link } from "react-router-dom";
import Menu from "../../components/menu/NavBar";
import './styles.css'

export function Servicos() {
  const [VerServicos, setVerServicos] = useState([]);

  const [itensPorPage, setitensPorPage ] = useState([8])
  const [correntPage, setcorrentPage ] = useState([0])

  const pages = Math.ceil(VerServicos.length/ itensPorPage)
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerServicos.slice(startItens, endItens)


  const verOsAnimais = async () => {
    ServicosServices.getServicos()
      .then((response) => {
        setVerServicos(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    verOsAnimais();
  }, []);
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Serviços CWAM</h1>
        </div>
      </section>

      <section className="animais" style={{ width: "100%", margin: "0" }}>
        <div className="m-2 ani_p">
          {correntItens.map((servico) => (
    
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  // backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${servico.imgUrl})`,
                  backgroundImage: `url(${servico.imgUrl})`,
                  borderRadius: "20px",
                }}
              ></div>
              {/* <img className="img-logo-empresa" src={`https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${animais.imgUrl}`} alt="" /> */}
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {servico.titulo}
                </h2>
                <span
                  
                  className="button botao_para_saber_mais"
                  style={{ background: "transparent", cursor: "default", border: "none", outline: "none" }}
                >
                  
                </span>
                <p className="mb-1"></p>
              </div>
            </div>
          ))}
        </div>
        </section>

        <section className="sect">
        {Array.from(Array(pages), (VerServicos, index)=> {
            return(
              <button className="pagB" value={index} onClick={(e) => setcorrentPage(Number(e.target.value))}>{index + 1}</button>
            )
        }
        )}
      </section>
    </>
  );
}

export default Servicos;
