import React, { useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const MenuPerfil = () => {
  const { logout, user } = useContext(AuthContext);
  const handleLogout = () => {
    Swal.fire({
      text: "Deseja mesmo Sair?",
      showCancelButton: true,
      confirmButtonColor: "#9ba235",
      cancelButtonColor: "#b46739",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };
  return (
    <>
      <div className="menu_do_user">
        <ul>
          <li><Link to={`/perfil`} className="menu_color_link_a">Compras</Link></li>
          {/* <li><Link to={`/perfilLeilao`} className="menu_color_link_a">Leilão</Link></li> */}
          <li>
            <Link to={`/perfilDados`} className="menu_color_link_a">
              Perfil
            </Link>
          </li>
        </ul>
        <Link to="" onClick={handleLogout} className="sair_user">
          sair
        </Link>
      </div>
    </>
  );
};

export default MenuPerfil;
