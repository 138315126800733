import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Cart from "../../components/vendas/Cart";
import CardVenda from "../../components/vendas/cardVenda";
import CardProduto from "../../components/vendas/cardProdutos";
import VendaService from "../../services/vendas";
import Menu from "../../components/menu/NavBar";
import "./styles.css";

const Venda = () => {
  const [VerAnimais, setVerAnimais] = useState([]);
  // const [VerArtigoAnimal, setVerArtigoAnimal] = useState([]);
  const [VerProdutos, setVerProdutos] = useState([]);

  const [itensPorPage, setitensPorPage] = useState([4]);
  const [correntPage, setcorrentPage] = useState([0]);

  const pages = Math.ceil(VerAnimais.length / itensPorPage);
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerAnimais.slice(startItens, endItens);

  useEffect(() => {
    VerLoteVenda();
    // VerAnimaisVenda();
    VerProdutosVenda();
  }, []);

  // Listar Os Lotes que estão no artigo
  const VerLoteVenda = async () => {
    await VendaService.getAnivalVenda().then((response) => {
      setVerAnimais(response.data);
    });
  };

  // Listar Os Produto que estão no artigo
  const VerProdutosVenda = async () => {
    await VendaService.getProdutosVenda()
      .then((response) => {
        setVerProdutos(response.data);
        console.log(response.data, "TODOS OS PRODUTOS")
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };
  const estiloH1 = {
    color: "#fff",
    fontSize: "30px",
    textAlign: "center",
    margin: "20px 0",
  };

  return (
    <>
      <Menu />
      <Cart />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">MARKETPLACE</h1>
        </div>
      </section>

      <h1 style={estiloH1}></h1>
      <section className="animais" style={{ width: "100%", margin: "0" }}>
        <div className="m-2 ani_p">
          {VerAnimais.map((animais, index) => (
            <CardVenda
              codigo={animais.codigo}
              imgUrl={`${animais.lote.imgUrl}`}
              especie={animais.lote.especie.especieAnimal}
              quantidade={animais.lote.quantidade}
              preco={animais.preco}
              item={animais}
              key={animais.codigo}
            />
          ))}
        </div>
      </section>
      {/* */}

      <h1 style={estiloH1}>Os nossos Produtos</h1>
      <section className="animais" style={{ width: "100%", margin: "0" }}>
        <div className="m-2 ani_p">
          {VerProdutos.map((produto) => (
            <CardProduto
              codigo={produto.codigo}
              imgUrl={`${produto.produto.imgUrl}`}
              nome={produto.produto.nome}
              preco={produto.preco}
              quantidade={produto.quantidade_fixa}
              item={produto}
              key={produto.codigo}
            />
          ))}
        </div>
      </section>

      <section className="sect">
        {Array.from(Array(pages), (VerAnimais, index) => {
          return (
            <button
              className="pagB"
              value={index}
              onClick={(e) => setcorrentPage(Number(e.target.value))}
            >
              {index + 1}
            </button>
          );
        })}
      </section>
    </>
  );
};

export default Venda;