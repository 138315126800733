import api from "./api";

const setCarrinho = (codigo) => {
  return api.put("carrinho_visitante/" + codigo);
};

const setCarrinhoState = (codigo, data) => {
  return api.put(`carrinhoGlobal/${codigo}`, data);
};

const setEntregaState = (codigo, data) => {
  return api.put(`totalState/${codigo}`, data);
};
const sendComprovativo = (data) => {
  return api.post(`criar_comprovativo`,data);
};

export default { setCarrinho, setCarrinhoState, setEntregaState, sendComprovativo };