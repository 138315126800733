import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import { Link } from "react-router-dom";
import leilaoExclusivoService from "../../services/leilaoExclusivo";
import Menu from "../../components/menu/NavBar";

export function LeilaoItem() {
  const { codigo } = useParams();
  const [Leilao, setLeilao] = useState([]);
  useEffect(() => {
    VerLeilaoItem()
  });

  // Pegar os animais
  const VerLeilaoItem = async () => {
    await leilaoExclusivoService.getItemLeilaoExclusivo(codigo).then((response) => {
      setLeilao(response.data);
    });
  };

  return (
    <>
    <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Leilão Exclusivo da CWAM</h1>
        </div>
      </section>

      <section className="animais">
        <div className="m-2 ani_p">
          {Leilao.map((ver) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemAnimal/${ver.animal.imgUrl})`,
                  // backgroundImage: `url(${ver.animal.imgUrl})`,
                }}
              ></div>
              {/* <img className="img-logo-empresa" src={`https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${animais.imgUrl}`} alt="" /> */}
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {ver.animal.nome}
                </h2>

                <p className="">Proveniente: {ver.proveniente}</p>
                <p className="">
                  Raça:
                  {ver.animal.raca}
                </p>
                <p className="mb-5">
                  Peso:
                  {ver.animal.peso}
                  kg
                </p>
                <Link
                  to={`/lanceExclusivo/${ver.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Dar Lance
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default LeilaoItem;
