import { Link } from "react-router-dom";
import Slider from "./simple";
import Menu from "./menu/NavBar";

const Home = () => {
  //console.log(loggedUser)
  return (
    <>
      <Menu />
      <Slider />
      {/* <div id="borda_img"></div> */}
      <div id="conteudo">
        <div className="meio" style={{ background: "#ccc" }} id="meio-home">

          <div className="MeioContent">
          </div> 

          <div className="is-flex is-flex-direction-row is-justify-content-space-between m-auto mm">
            <div className="mp m-2">
              <div className="m_laranja is-flex is-justify-content-center">
                <div
                  id="tresimg1"
                  className="m_img mr-auto ml-auto mt-20 mb-4"
                ></div>
              </div>
              <div className="m_letra verde_escuro p-2 m-t quadrado quadrado-1">
                <p>
                  A Missão da CWAM consiste na valorização do setor do agro-negócio de modos a contribuir para o crescimento de desenvimento sustentavel e nível reguinal e nacional.
                </p>
              </div>
            </div>
            <div className="mp m-2">
              <div className="m_laranja is-flex is-justify-content-center">
                <div id="tresimg2" className="m_img mr-auto ml-auto mt-10 mb-4">
                  <img src="" alt="" width="100%" height="100%" />
                </div>
              </div>
              <div className="m_letra verde_escuro p-2 m-t quadrado quadrado-1">
                <p>
                  A visão da CWAM consiste na possibilidade de oferecer aos consumidores angolanos uma produção agrícola diversificada e de qualidade assim como gerar oportunidades no agro-negócio.
                </p>
              </div>
            </div>
            <div className="mp m-2">
              <div className="m_laranja is-flex is-justify-content-center">
                <div id="tresimg3" className="m_img mr-auto ml-auto mt-10 mb-4">
                  <img src="" alt="" width="100%" height="100%" />
                </div>
              </div>
              <div className="m_letra verde_escuro p-2 m-t quadrado quadrado-1">
                <p>
                  Os valores de CWAM consistem na possibilidade de criar oportunidades aos seus associados, promovendo um ambeinte de negócio e sustentabilidade de mercado atravéz do agro-negócio.
                </p>
              </div>
            </div>
          </div>

        </div>

        <section className="section vindo">
          <div className="ima letra vindo_sombra">
            <div className="is-flex-direction-column vindo_letra">
              <div>
                <h1>BEM-VINDO</h1>
                <h1 style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  COOPERATIVA WAPOMA AGRO-PECUÁRIA E MINEIRA (CWAM)
                </h1>
              </div>
              <p>
                É uma cooperativa empresarial com sede no Lubango, com
                cooperados nas Províncias da Huíla, Cunene, Namibe Cuando
                Cubango, principalmente.
              </p>
              <div className="btbt">
                <Link to="/fazendas" className="button mx-auto botao">
                  Ver Fazendas
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/*  
        <section className="section tts">
          <div className="cg">
            <div className="c_img1 c_img">
              <div className="llr laranja">
                Ao visitar a CWAM, podes encontrar diversos atrativos e tipos
                de negócios que podem de alguma forma impulsionar o nicho de
                criadores de gado.
              </div>
              <div className="c_baixo">
                <Link to="/noticias">
                  <p state={{ color: "#fff" }}>Noticias</p>
                </Link>
              </div>
            </div> 
            <div className="c_img2 c_img">
              <div className="llr">
                Acompanhe todos os leilões da CWAM, de forma muito mais
                interativa, agradável e segura, beneficiando de enormes
                propostas oferecidas pelos seus Associados.
              </div>
              <div className="c_baixo">
                  <p state={{ color: "#fff" }}>Leilão</p>
              
              </div>
            </div>
            <div className="c_img3 c_img">
              <div className="llr laranja">
                Para além daquilo que a CWAM já pode fazer, também lhe é
                oferecido uma vasta gama de serviços aos quais todos os
                cooperados podem ter acesso, desde campanhas de vacinção e
                outros.
              </div>
              <div className="c_baixo">
                <p state={{ color: "#fff" }}>Produtos em Destaque</p>
              </div>

            </div>
          </div>
        </section> */}

        <div class="PaiCards">
          <div class="FilhoCard">
            <div className="IMG_Noticia">
              {/* <img src="./imgs/CWAM/IMG_9698.jpg" /> */}
            </div>
            <div>
              {/* <h4>Descricao do card</h4> */}
              <div class="FundoDescricao">
                <p>A Cooperativa CWAM tem sido destaque recentemente com suas inovações e contribuições para o setor agrícola. Com uma visão voltada para o desenvolvimento sustentável e a valorização dos produtores rurais, a CWAM tem se destacado no mercado com seus produtos de alta qualidade e práticas agrícolas responsáveis.</p>
              </div>
            </div>
            <div class="btnCard">
              <button>
                <Link to="/noticias">
                  <p state={{ color: "#fff" }}>Noticias</p>
                </Link>
              </button>
            </div>
          </div>

          <div class="FilhoCard">
            <div className="IMG_Produtos">
              {/* <img src="./imgs/CWAM/IMG_9695.jpg" /> */}
            </div>
            <div>
              {/* <h4>Descricao do card</h4> */}
              <div class="FundoDescricao">
                <p>
                  A Cooperativa CWAM destaca-se por sua ampla variedade de produtos agrícolas, todos produzidos com alto padrão de qualidade e seguindo práticas sustentáveis. Entre os produtos em destaque da cooperativa, podemos destacar:
                  Banana de Qualidade: A CWAM é conhecida pela produção de bananas de alta qualidade.
                </p>
              </div>
            </div>
            <div class="btnCard">
              <button>
                <Link to="/venda">
                  <p state={{ color: "#fff" }}>Produtos em destaque</p>
                </Link>
              </button>
            </div>
          </div>
        </div>

        <div className="segmentacao">
          <h2>Segmentação</h2>
          <div className="ProdutosCWAM">
            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM4"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM2"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM3"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM1"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM5"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM6"></div>
            </div>
          </div>


          <div className="ProdutosCWAM">
            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM7"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM8"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM9"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM10"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM11"></div>
            </div>

            <div className="Fundo_produtoCWAM4">
              <div className="produtoCWAM12"></div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Home;
