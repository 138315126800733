import api from "./api";


const geVisitanteFoto = () => {
    return api.get("visitanteFotos");
};

const getInformacaoUser = (email) => {
    return api.get(`visitanteEmail/${email}`)
}

const getHistoricoLeilao = (codigo) => {
    return api.get(`user/${codigo}`);
};

const getHistoricoCompras = (codigo) => {
    return api.get(`comprovativoVisitante/${codigo}`);
};
const minhasCompras = (codigo) => {
    return api.get(`minhaCompraCarrinhoGlobal/${codigo}`);
};

const alterarFoto = (data) => {
    return api.post("visitanteFoto", data);
};

const updateFoto = (data) => {
    return api.put("visitanteFoto", data);
};

const alterarFotoCodigo = (codigo) => {
    return api.get(`visitanteFoto/${codigo}`);
};

// const alterarFotoCodigo2 = (codigo) => {
//     return api.get("visitanteFoto/"+codigo);
// };

const alterarFotoCodigo2 = (codigo) => {
    return api.get("https://webcwam-polished-dust-7716.fly.dev/api/visitanteFotoVisitante/"+codigo);
};

const EditarVisitante = (codigo, data) => {
    return api.put(`criar_visitante_img/${codigo}`, data);
};

// const deleteFotoVisitante = (codigo) => {
//     return api.delete(`deleteVisitanteFoto/${codigo}`);
// };

const deleteFotoVisitante = (codigo) => {
    return api.delete(`deleteFotoVisitante/${codigo}`);
};

export default { getHistoricoCompras, getHistoricoLeilao, minhasCompras, geVisitanteFoto, getInformacaoUser, alterarFoto, alterarFotoCodigo, alterarFotoCodigo2, EditarVisitante, updateFoto, deleteFotoVisitante }