import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import UserPerfil from "../../services/UserPerfil";
import VisitanteService from "../../services/visitante";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import LocalizacaoService from "../../services/localizacao";
import * as yup from "yup";

const UserDetalhes = (props) => {
  const { user } = useContext(AuthContext);
  const logado = user?.codigo;
  const [verificado, setVerificado] = useState("verifica");

  const [VerProfile, setVerProfile] = useState({});

  const [codigo, setcodigo] = useState(0);

  const [VerPais, setVerPais] = useState([]);
  const VerOsPaises = async () => {
    await LocalizacaoService.getPais().then((response) => {
      setVerPais(response.data);
    });
  };

  const verGender = ["Masculino", "Feminino"];
  const [newPerfil, setnewPerfil] = useState({});
  const onInputChange = (e) => {
    setnewPerfil({ ...newPerfil, [e.target.name]: e.target.value });
  };

// 
  const {
    nome,
    sobrenome,
    email,
    telefone,
    bi,
    gender,
    pais,
    // senha
    // senhaantiga,
    // novasenha,
  } = newPerfil;

  const VerAsInformacaoUser = async () => {
    VisitanteService.infoVisitante(logado).then((response) => {
      setnewPerfil(response.data);
      if (response.data.is_verify === true) {
        setVerificado("verificado");
      }
      if (response.data.is_verify === false) {
        setVerificado("nao");
      }
    });
    // .catch(() => {
    //   Swal.fire({
    //     title: "Erro em estabelecer a Conexão",
    //     icon: "error",
    //   });
    // });
  };

  useEffect(() => {
    VerAsInformacaoUser();
    VerOsPaises();
  }, []);

  const editar = async (e) => {
    e.preventDefault();
    if (!(await validate())) return;
    const data = newPerfil;
    await VisitanteService.EditarVisitante(logado, data)
      .then((response) => {
        Swal.fire("Dados Editados com sucesso", "", "success");
        VerAsInformacaoUser();
      })
      .catch(() => {
        Swal.fire("Ocorreu um erro ao editar os dados", "", "error");
      });
  };

  const n = 1;

  //VAlidacao
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  async function validate() {
    let schema = yup.object().shape({
      // pais: yup.string("Selecione o país").required("Selecione o país"),
      gender: yup.string("Selecione o sexo").required("Selecione o sexo"),
      // senha: yup
      //   .string("O campo senha obrigatório!")
      //   .required("O campo senha obrigatório!")
      //   .min(6, "A senha deve ter no mínimo 6 caracteres!"),
      // conf_senha: yup
      //   .string("O campo confirmar senha obrigatório!")
      //   .required("O campo confirmar senha obrigatório!")
      //   .min(6, "A senha deve ter no mínimo 6 caracteres!"),
      email: yup
        .string("O campo email obrigatório!")
        .required("O campo email obrigatório!")
        .email("e-mail inválido!"),
      bi: yup
        .string("Por favor o seu Bilhete de Identidade")
        .required("Por favor o seu Bilhete de Identidade"),
      telefone: yup
        .string("Por favor insera o seu número de telefone!")
        .required("Por favor insere o seu número de telefone"),
      sobrenome: yup
        .string("Por favor insera o seu sobre-nome")
        .required("Por favor insera o seu sobre-nome"),
      nome: yup
        .string("Por favor insera o seu nome")
        .required("Por favor insera o seu nome"),

      /**Validacao do nascimento */

      // nascimento: yup
      //   .string("Por favor insera a sua data de Nascimento")
      //   .required("Por favor insera a sua data de Nascimento"),
    });
    try {
      await schema.validate(newPerfil);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }

  return (
    <>
      <div>
        <div className="Laranja_dados_user">
          <h2>Dados Pessoais</h2>
        </div>
        {verificado === "nao" ? (
          <div className="verificar_email">
            O seu endereço de email ainda não foi verificado, por favor
            verifique o seu email
          </div>
        ) : (
          ""
        )}
        {status.type === "error" ? (
          <div className="alert_campos">
            <p>{status.mensagem}</p>
          </div>
        ) : (
          ""
        )}
        <form onSubmit={(e) => editar(e)}>
          <div className="flex_form_btn_user">
            <div className="flex_form_user">
              {/* <div className="grid_form_user"> */}
              <div>
                <input
                  type="text"
                  placeholder="Seu Nome"
                  name="nome"
                  value={nome}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Seu Sobrenome"
                  name="sobrenome"
                  value={sobrenome}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Seu email"
                  name="email"
                  value={email}
                  onChange={(e) => onInputChange(e)}
                  disabled
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Seu telefone"
                  name="telefone"
                  value={telefone}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              {/* </div> */}
              {/* <div className="grid_form_user"> */}
              <div>
                <input
                  type="text"
                  placeholder="Seu nº do Bilhete de identidade"
                  name="bi"
                  value={bi}
                  onChange={(e) => onInputChange(e)}
                  disabled
                />
              </div>
              <div>
                <select
                  id="select_genero_perfil"
                  name="gender"
                  onChange={(e) => onInputChange(e)}
                >
                  {verGender.map((Ogender) => (
                    <option value={Ogender} selected={Ogender === gender}>
                      {Ogender}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  id="select_genero_perfil"
                  name="pais"
                  onChange={(e) => onInputChange(e)}
                >
                  {VerPais.map((paises) => (
                    <option value={paises.id} selected={paises.id === pais}>
                      {paises.nome}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div>
                <input
                  type="text"
                  placeholder="Senha Antiga"
                  name="senha"
                  value={senhaantiga}
                  onChange={(e) => onInputChange(e)}
                  
                />
              </div> */}
              {/* <div>
                <input
                  type="text"
                  placeholder="Nova senha"
                  name="novasenha"
                  value={novasenha}
                  onChange={(e) => onInputChange(e)}
                  
                />
              </div> */}
              {/* </div> */}
            </div>
            <div className="dois_user_form_btn">
              <div></div>
              <div>
                <button type="submit" className="editar_btn_user">
                  Editar Dados
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserDetalhes;
