import React, { useEffect, useState } from "react";
import AgendaService from "../../services/agendas";
// import { Link } from "react-router-dom"
import Swal from "sweetalert2";
import Menu from "../../components/menu/NavBar";
import "./styles.css";

export function Agenda() {
  const [VerAgenda, setVerAgenda] = useState([]);

  const [itensPorPage, setitensPorPage] = useState([8]);
  const [correntPage, setcorrentPage] = useState([0]);

  const pages = Math.ceil(VerAgenda.length / itensPorPage);
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerAgenda.slice(startItens, endItens);

  const VerAsAgendas = async () => {
    AgendaService.getAgenda()
      .then((response) => {
        setVerAgenda(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    VerAsAgendas();
  }, []);
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">AGENDAS</h1>
        </div>
      </section>

      <section className="animais" style={{ width: "100%", margin: "0" }}>
        <div className="m-2 ani_p">
          {correntItens.map((agenda) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  // backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemCooperador/${agenda.imgUrl})`,
                  backgroundImage: `url(${agenda.imgUrl})`,
                  borderRadius: "20px",
                }}
              ></div>
              {/*  */}
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                  dangerouslySetInnerHTML={{__html:agenda.descricao}}
                >
                </h2>
                <span
                  className="button botao_para_saber_mais"
                  style={{ background: "transparent", cursor: "default", border: "none", outline: "none" }}
                >
                </span>
                <p className="mb-1"></p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="sect">
        {Array.from(Array(pages), (VerAgenda, index) => {
          return (
            <button
              className="pagB"
              value={index}
              onClick={(e) => setcorrentPage(Number(e.target.value))}
            >
              {index + 1}
            </button>
          );
        })}
      </section>
    </>
  );
}
export default Agenda;
