import { useEffect, useState } from "react";
import { getTotalDeCompras } from "../../../services/ComprasService";
import { Link } from "react-router-dom";

function CardComprasUser(props) {
  useEffect(() => {
    verOTotal();
    verEstado();
  });

  const [estados, setestado] = useState("");
  const verEstado = () => {
    if (props.estado === "PENDENTE") {
      setestado("Pendente");
    } else if (props.estado === "ACEITE") {
      setestado("Aceite");
    } else if (props.estado === "NEGADO") {
      setestado("Negado");
    }
  };

  const [verTotal, setverTotal] = useState(0);
  const verOTotal = async () => {
    await getTotalDeCompras(props.id)
      .then((response) => {
        setverTotal(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
    
    <tbody>
      <tr>
        <td>
          <div className="ver_img_table_compras"></div>
        </td>
        <td data-label="Carrinho"  className="qEp" id="fff">Carrinho {props.index}</td>
        <td data-label="Valor" className="qEp" id="fff">{verTotal}Kz</td>
        <td data-label="Estado" className="qEp" id="fff">
          <span
            className={
              estados === "Pendente"
                ? "pendenteCart"
                : "" || estados === "Aceite"
                ? "aceiteCart"
                : "" || estados === "Negado"
                ? "negadoCart"
                : ""
            }
          >
            {estados}
          </span>
        </td>
        <td data-label="Detalhes" className="qEp" id="fff">
          <Link to={`/perfilcomprainfo/${props.global}`}>
            <button className="btn_ver_comp_user">Ver mais</button>
          </Link>
        </td>
      </tr>
      </tbody>
    </>
  );
}
export default CardComprasUser;
