import api from "./api";

const getAnivalVenda = async () => {
  return await api.get("Artigo");
};
const getArtigoAnimalVenda = async () => {
  return await api.get("ArtigoAnimal");
};
const getProdutosVenda = async () => {
  return await api.get("ArtigoProduto");
};
const getAnivalVendaCodigo = async (codigo) => {
  return await api.get(`artigo/${codigo}`);
};

// 
const getProdutoVendaCodigo = async (codigo) => {
  return await api.get(`artigoproduto/${codigo}`);
};
//

const getItemCardAny = async (codigo) => {
  return await api.get("carrinhoAnimal_visitante/"+codigo);
};
const getItemCard2 = async (codigo) => {
  return await api.get("carrinho_visitante/" + codigo);
};

const getProdutosCarrinho = async (codigo) => {
  return await api.get("carrinhoProduto_visitante/" + codigo);
};

// Aumentar a Quantidade do item no carrinho
const GetvalorCart = async (codigo) => {
  return await api.get(`carrinho/visitante/`+codigo);
};
const GetvalorCart2 = async (codigo) => {
  return await api.get(`carrinhoAnimal/visitante=` + codigo);
};
const GetvalorCart3 = async (codigo) => {
  return await api.get(`carrinhoAnimal/visitante=` + codigo);
};
const GetTotalValorProduto = async (codigo) => {
  return await api.get(`carrinhoProduto/visitante/` + codigo);
};
// Deletar o Item do Carrinho
const removeItemCart = async (codigo) => {
  return await api.delete(`carrinho/${codigo}`);
};
const removeItemCartProduto = async (codigo) => {
  return await api.delete(`carrinhoProduto/${codigo}`);
};
const removeItemCartAnimal = async (codigo) => {
  return await api.delete(`carrinhoAnimal/${codigo}`);
};
// Aumentar a Quantidade do item no carrinho
const updateQtdCartAument = async (codigo) => {
  return await api.get(`carrinho_qtd_aument/${codigo}`);
};
const updateQtdCartAumentProduto = async (codigo) => {
  return await api.get(`carrinhoProduto_qtd_aument/${codigo}`);
};
const updateQtdCartAumentAnimal = async (codigo) => {
  return await api.get(`carrinhoAnimal_qtd_aument/${codigo}`);
};

// Diminuir a quantidade do item no carrinho
const updateQtdDiminuir = async (codigo) => {
  return await api.get(`carrinho_qtd_diminuir/${codigo}`);
};
const updateQtdDiminuirProduto = async (codigo) => {
  return await api.get(`carrinhoProduto_qtd_diminuir/${codigo}`);
};
const updateQtdDiminuirAnimal = async (codigo) => {
  return await api.get(`carrinhoAnimal_qtd_diminuir/${codigo}`);
};
const carrinhoTotal = async (codigo) => {
  return await api.get(`carrinho/total/${codigo}`);
};
const createCarrinhoAnimal = async (data) => {
  return await api.post("criar_carrinho", data);
};
const createCarrinhoGlobal = async (data) => {
  return await api.post("carrinhoGlobal", data);
};
// Veririficar se o animal já existe no carrinho
const VerificarExisteAnimal = async (artigo, global) => {
  return await api.get(`visitanteCarinhoVerificar/${artigo}/${global}`);
};

// Veririficar se o produto já existe
const VerificarExisteProduto = async (artigo, global) => {
  return await api.get(`carrinhoProdutoVerificar/${artigo}/${global}`);
};
const getCarrinhoGlobal = async (codigo) => {
  return await api.get("carrinhoGlobal/" + codigo);
};
const createCarrinhoProduto = async (data) => {
  return await api.post("criar_carrinhoProduto", data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getItemCardAny,
  getItemCard2,
  getAnivalVendaCodigo,
  getProdutoVendaCodigo,
  GetvalorCart,
  GetvalorCart2,
  removeItemCartAnimal,
  GetvalorCart3,
  GetTotalValorProduto,
  removeItemCart,
  removeItemCartProduto,
  updateQtdCartAument,
  updateQtdCartAumentProduto,
  updateQtdCartAumentAnimal,
  updateQtdDiminuir,
  updateQtdDiminuirProduto,
  updateQtdDiminuirAnimal,
  getAnivalVenda,
  carrinhoTotal,
  getProdutosVenda,
  getProdutosCarrinho,
  createCarrinhoAnimal,
  createCarrinhoProduto,
  VerificarExisteAnimal,
  VerificarExisteProduto,
  createCarrinhoGlobal,
  getCarrinhoGlobal,
  getArtigoAnimalVenda
};
