import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EntregaService from "../../services/entregas";
import { AuthContext } from "../../contexts/Auth";
import Menu from "../../components/menu/NavBar";
import VendaService from "../../services/vendas";


function Entregas() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const userlogado = user?.codigo;
  // const userlogado = 1;
  // Sobre o botão

  // ----------------------------------------------
  const [Transporte, setTransporte] = useState([]);
  const [PesquisarTransporte, setPesquisarTransporte] = useState("");
  const [escolhaTransporte, setescolhaTransporte] = useState([]);

  const [DisplayTransporte, setDisplayTransporte] = useState(false);
  const [DisplayTrans, setDisplayTrans] = useState(false);
  const [DisplayDestino_trans, setDisplayDestino_trans] = useState(false);
  const [DisplayTudo, setDisplayTudo] = useState(false);

  function DisplayDoTransporte() {
    setDisplayTransporte((DisplayTransporte) => true);
    setDisplayTrans((DisplayTrans) => true);
  }

  function DisplayDoDestino_transporte() {
    setDisplayDestino_trans((DisplayDestino_trans) => true);
  }

  function DisplayTotalTudo() {
    setDisplayDestino_trans((DisplayDestino_trans) => false);
    setDisplayTrans((DisplayTrans) => false);
    setDisplayTudo((DisplayTudo) => true);
  }

  let toggleClassCheck = DisplayTrans
    ? "display_aparecer"
    : "display_desaparecer";
  let desaparecer_destino = DisplayTransporte ? "display_desaparecer" : "";
  let desaparecer_destino_transporte = DisplayDestino_trans
    ? "display_aparecer"
    : "display_desaparecer";
  let aparecerTotal_tudo = DisplayTudo
    ? "display_aparecer"
    : "display_desaparecer";
  //const DisplayTransporte;

  useEffect(() => {
    VerGlobal()
    VerOsTransportes();
    verExisteUser();
  });

  const [codGlobal, setcodGlobal] = useState()
  const VerGlobal = async () => {
    await VendaService.getCarrinhoGlobal(userlogado)
    .then((response)=>{
      setcodGlobal(response.data[0].codigo)
    })
  }
  const VerOsTransportes = async () => {
    EntregaService.getTranportes()
      .then((response) => {
        setTransporte(response.data);
      })
      // .catch(() => {
      //   Swal.fire(
      //     "Erro de Conexão",
      //     "Por favor verifique a sua internet",
      //     "error"
      //   );
      // });
  };

  const onInputChange = (e) => {
    setPesquisarTransporte({
      ...PesquisarTransporte,
      [e.target.name]: e.target.value,
    });
    console.log(PesquisarTransporte.pesquisar);
    if (
      PesquisarTransporte.pesquisar === "" ||
      PesquisarTransporte.pesquisar === " "
    ) {
      VerOsTransportes();
      PesquisarTransporte.pesquisar = "";
      return;
    }
    EntregaService.getPesquisaTransporte(e.target.value)
      .then((response) => {
        setTransporte(response.data);
      })
      // .catch(() => {});
  };

  const SelecionarTransporte = (cam, nome, preco, id) => {
    Swal.fire({
      title: "Transporte Selecionado: " + nome,
      text: "Valor do frete: " + preco + "Kz por Km",
      imageUrl: cam,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      showCancelButton: true,
      confirmButtonColor:  "#9ba235", 
      cancelButtonColor: "#b46739",
      confirmButtonText: "Escolher Transporte",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setescolhaTransporte({
          id: id,
          nome: nome,
          preco: preco,
        });
        Swal.fire({
          title: "Transporte Selecionado",
          icon: "success",
        });
        DisplayDoDestino_transporte();
      }
    });
  };

  const TransporteSelecionado = () => {
    Swal.fire(
      "Este transporte já foi selecionado",
      "Se deseja mudar de transporte, selecione outro",
      "warning"
    );
  };
  
  const addTransporte = async (data) => {
    await EntregaService.createTransporte(codGlobal, data)
      .then((response) => {
        console.log(response)
        navigate("/entrega_final");
      })
      .catch((err) => {
        Swal.fire({
          title: "Erro: " + err,
          icon: "error",
        });
      });
  };
  const VerOTotalDeTudo = () => {
    const data = {
      transportador: {
        codigo: escolhaTransporte.id,
      },
    };
    addTransporte(data);
  };

  const VerOInicioFinal = (codigo1, codigo2) => {
    EntregaService.getInicialFinal(codigo1, codigo2).then((response) => {});
  };

  // var arr = [19, 2];
  // var soma = arr.reduce(function(soma, i) {
  //     return soma + i;
  // });
  // console.log(soma);
  // -------------------
  // const totTudo = [];
  // var soma = 0;
  // for(var i = 0; i < totTudo.length; i++) {
  //     soma += totTudo[i];
  // }
  // console.log(soma);

  const [VerExiste, setVerExiste] = useState([]);
  const [id_user, setid_user] = useState([]);

  const verExisteUser = async () => {
    await EntregaService.VerUserDestino(userlogado).then((response) => {
      setVerExiste(response.data);
      setid_user(response.data[0].codigo);
    });
  };

  return ( 
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Serviço de entrega</h1>
        </div>
      </section>
      
        <div>
          <div>
            <h1 
              style={{
                fontSize: "25px",
                color: "#fff",
                textAlign: "center",
                marginTop: "15px",
              }}
            >
              Selecione o transporte que deseja e o destino da entrega
            </h1>
          </div>
          <div className="doisFinal">
            <div className="tabela_final">
              {/* ------------------------------------- Entregas ------------------------------------------ */}

              <div
                style={{ marginLeft: "30px" }}
                className={`transporte_and_input`}
              >
                <div className="pai_pesquisa">
                  <input
                    type="text"
                    placeholder="Pesquise um Transporte..."
                    onChange={(e) => onInputChange(e)}
                    name="pesquisar"
                  />
                  <div className="img_pesquisa"></div>
                </div>
                <div className="tabela_final_t">
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ borderTopLeftRadius: "10px" }} className="ocultar-img">
                            Imagem
                          </th>
                          <th>Automóvel</th>
                          <th>Distância</th>
                          <th>Valor</th>
                          <th style={{ borderTopRightRadius: "10px" }}>Selecionar</th>
                        </tr>
                      </thead>
                      {Transporte.length === 0 ? (
                        <tr>
                          <td colSpan={5}>Nenhum transporte encontrado</td>
                        </tr>
                      ) : (
                        Transporte.map((transporte) => (
                          <tr key={transporte.codigo}>
                            <td className="ocultar-img">
                              <div
                                className="img_cart_img_final"
                                style={{
                                  backgroundImage: `url(${transporte.imgUrl})`,
                                }}
                              ></div>
                              {/* <div
                                className="img_cart_img_final"
                                style={{
                                  backgroundImage: `url(${transporte.imgUrl})`,
                                }}
                              ></div> */}
                            </td>
                            <td>{transporte.modelo}</td>

                            <td>1Km</td>
                            <td>{transporte.precoTransporte} kz</td>
                            <td>
                              {escolhaTransporte.id === transporte.codigo ? (
                                <button
                                  className="btn_escolher"
                                  style={{ backgroundColor: "#333" }}
                                  onClick={() => TransporteSelecionado()}
                                >
                                  Selecionado
                                </button>
                              ) : (
                                <button
                                  className="btn_escolher"
                                  onClick={() =>
                                    SelecionarTransporte(
                                      // `https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemTransporte/${transporte.imgUrl}`,
                                      `${transporte.imgUrl}`,
                                      transporte.modelo,
                                      transporte.precoTransporte,
                                      transporte.codigo
                                    )
                                  }
                                >
                                  Escolher
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </table>
                  </div>
                </div>
              </div>
              {/* ------------------------------------- Total Destino Transporte ------------------------------------------ */}

              <div
                className={`entrega_final destino_transporte ${desaparecer_destino_transporte}`}
              >
                <div
                  className="ver_loc_entrega"
                  style={{ marginBottom: "20px" }}
                >
                  <h1 className="info_entrega">Transporte Selecionado</h1>
                  <h2
                    style={{
                      padding: "10px",
                      fontSize: "19px",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Trasnporte: {escolhaTransporte.nome} - 1KM -{" "}
                    {escolhaTransporte.preco}Kz
                  </h2>
                  <div className="form_destino_botao">
                    <div></div>
                    <button
                      type="submit"
                      className="btn_laranja"
                      onClick={() => VerOTotalDeTudo()}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </>
  );
}
export default Entregas;
