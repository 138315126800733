import api from "./api";

const getPais = () => {
    return api.get("Pais");
};

// --------------- Pesquisar Provincias e Municicpios -----------------------------
const getPesquisaProvincias = (codigo) => {
    return api.get(`provinciaPais/${codigo}`);
};
// --------------- Pesquisar Provincias e Municicpios -----------------------------
const VerDestinoUser = (codigo) => {
    return api.get(`destinoByVisitante/${codigo}`);
};

const getPesquisaMunicipios = (codigo) => {
    return api.get(`municipioProvincia/${codigo}`);
};

const getDadosMunicicpio = (codigo) => {
    return api.get(`verProvincia/${codigo}`);
};

export default {
    getPais,
    getPesquisaProvincias,
    getPesquisaMunicipios,
    getDadosMunicicpio,
    VerDestinoUser
}