import Menu from "../../components/menu/NavBar";
import noticias from "../../services/noticias";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";

const Politicas = () => {
  const [VerNoticia, setVerNoticia] = useState([]);

  const VerAsNoticias = async () => {
    noticias
      .getNoticias()
      .then((response) => {
        setVerNoticia(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };
  
  useEffect(() => {
    VerAsNoticias();
  }, []);
  return (
    <>
      <Menu />
      <main id="main">
        
          <div className="fundo_termos">
            <div className="margem">
              <h1>
                   Política de Privacidade
              </h1>
              <p>
              Queremos que compreendas quais são as informações que a C recolhe
               e como as utilizamos e partilhamos. É por isso que te incentivamos 
               a ler a nossa Política de Privacidade. Isto ajuda-te a utilizar os
                serviços da C da forma que é mais adequada para ti.
                Na Política de Privacidade, explicamos como recolhemos e utilizamos as informações.
              </p>
            </div>
              <div className="margem">
              <h1>
                 Que informações recolhemos?
              </h1>
              <p>
              As informações sobre ti que recolhemos e tratamos dependem da forma como utilizas os nossos serviços.
               Por exemplo, se fores um cooperador da C, as informações que recolhemos são diferentes das que 
               iríamos recolher de um user normal.
              </p>
            </div>

            <div className="margem">
              <h1>
              O que acontece no caso de não nos deixares recolher determinadas informações?
              </h1>
              <p>
              São necessárias algumas informações para os nossos serviços funcionarem. As outras informações são opcionais.
               No entanto, sem elas, a qualidade da tua experiência pode ser afetada.
              </p>
            </div>
          </div>
     
      </main>
    </>
  );
};

export default Politicas;
