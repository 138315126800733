import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cart from "../../../components/vendas/Cart";
import SobreCompras from "../../../services/sobreCompras";
import Swal from "sweetalert2";
import VendaService from "../../../services/vendas";
import Menu from "../../../components/menu/NavBar";
import { AuthContext } from "../../../contexts/Auth";

const SobreProduto = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const logado = user?.codigo;

  //Botao Salvar
  const salvarCarrinho = async (id, preco) => {
    const data = {
      artigoProduto: {
        codigo: id,
      },
      visitante: {
        codigo: logado,
      },
      total: preco,
    };
    // const { artigo, visitante } = data;
    await VendaService.createCarrinhoProduto(data)
      .then((response) => {
        if (response.data === "carrinhoProduto_exist") {
          Swal.fire({
            width: 300,
            icon: "warning",
            text: "Este Produto já foi adicionado, verifique o seu carrinho",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (response.data === "saved") {
          Swal.fire({
            width: 300,
            icon: "success",
            text: "Adicionado ao carrinho ",
            showConfirmButton: false,
            timer: 1500,
          });
          //Cart.VerOsAnimaisCarrinho()
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          width: 300,
          icon: "error",
          text: "É necessário autenticar para fazer compras " + id,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/login");
      });
  };
  //
  const [VerProduto, setVerProduto] = useState([]);
  const [VerPreco, setVerPreco] = useState([]);
  const [VerArtigoCodigo, setVerArtigoCodigo] = useState([]);

  const { codigo } = useParams();

  //
  useEffect(() => {
    loadOurService();
  }, []);
  //
  const loadOurService = async () => {
    if (codigo) {
     await VendaService.getProdutoVendaCodigo(codigo).then((response) => {
        setVerArtigoCodigo(response.data.codigo);
        // setVerPreco(response.data.preco)
        setVerProduto(response.data.produto);
        setVerPreco(response.data.preco);
        // setVerFazenda(response.data.animal.fazenda)
      });
    }
  };
  //
  return (
    <>
      <Menu />

      <Cart />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Detalhes do Produto</h1>
        </div>
      </section>
      <section className="corpo f_corpo">
        <div className="fotografiaAnimal">
          <img
            className="img-animal"
            src={`${VerProduto.imgUrl}`}
            alt=""
          />
        </div>
        <div className="conteudo">
          <div style={{display: "flex", justifyContent: "space-between"}}>
          <h2>Detalhes do Produto</h2>
          <h2>
            {VerPreco} kz
          </h2>
          </div>

          <div className="legenda_do_lance">
            {/* <p>Produto: {VerProduto.nome}</p> */}
            {/* <p>Tipo: {VerProduto.nome}</p> */}
            <p>Produto: {VerProduto.nome}</p>

            {/* <p>Idade: <strong>{VerProduto.idade} anos</strong></p>
                    <p>Sexo: <strong>{VerProduto.sexoAnimal}</strong></p>
                    <p>Peso: <strong>{VerProduto.peso}</strong></p>
                    <Link to={`/sobre_fazenda/${VerFazenda.codigo}`}>
                    <p>Fazenda: <strong>{VerFazenda.nome}</strong></p>
                    
                        </Link> */}
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <a
                onClick={() => salvarCarrinho(VerArtigoCodigo, VerPreco)}
                className="button is-link venda_botao"
                style={{ background: "#B46739", width: "60%" }}
              >
                COMPRAR AGORA
              </a>
              <Link
                to="/venda"
                className="button is-link venda_botao"
                style={{ background: "#B46739", width: "30%" }}
              >
                VOLTAR
              </Link>
            </p>
          </div>
        </div>
      </section>

      <section className="descricao">
        <div className="info_animal">
          <h1>{VerProduto.nome}</h1>
          <p>{VerProduto.descricao}</p>
        </div>
      </section>
    </>
  );
};

export default SobreProduto;
