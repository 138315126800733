import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CooperadorService from "../../../services/cooperador";
import Menu from "../../../components/menu/NavBar";

const SobreCooperador = () => {
  const { codigo } = useParams();
  const [VerFazendas, setVerFazendas] = useState([]);

  const [VerCooperador, setVerCooperador] = useState({
    imgUrl: "",
    nome: "",
    email: "",
    endereco: "",
  });

  //
  useEffect(() => {
    PerfilCooperador();
    VerAsFazendas();
  }, []);
  //
  const PerfilCooperador = () => {
    if (codigo) {
      CooperadorService.getPerfilCooperador(codigo).then((response) => {
        setVerCooperador(response.data);
      });
    }
  };

  const VerAsFazendas = async () => {
    CooperadorService.getFazendaCooperador(codigo).then((response) => {
      setVerFazendas(response.data);
    });
  };

  //
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section
        className="fundo_entrar_fazenda"
        style={{
          backgroundImage: `url(${VerCooperador.imgUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder"></h1>
        </div>
      </section>
      {/*  */}
      <section className="corpoCope f_corpoCope">
        <div className="perfil_cooperador">
          <div
            className="img_perfil_cooperador"
            style={{
              backgroundImage: `url(${VerCooperador.imgUrl})`,
            }}
          ></div>
          <div className="leg_perfil">
            <p style={{ fontSize: "25px" }}>{VerCooperador.nome}</p>
            <p>{VerCooperador.email}</p>
            <p>{VerCooperador.endereco}</p>
            <p>Nº de Fazendas: {VerFazendas.length}</p>
            <Link
              to="/cooperadores"
              className="button is-link"
              style={{ background: "#9ba236" }}
            >
              Voltar
            </Link>
          </div>
        </div>
      </section>

      <h1 className="h1_sobre_fazenda" style={{ marginTop: "-160px" }}>
        Fazendas
      </h1>
      <section className="animais">
        <div className="m-2 ani_p">
          {VerFazendas.map((fazenda) => (
            <div className="am_p" key={fazenda.codigo}>
              <div
                className="img_do_cooperador"
                style={{
                  backgroundImage: `url(${fazenda.logo})`,
                }}
              ></div>
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {fazenda.nome}
                </h2>
                <table>
                  <tr>
                    <td>Província:</td>
                    <td>{fazenda.localizacao}</td>
                  </tr>
                  <tr>
                    <td>Cooperador:</td>
                    <td>{fazenda.cooperador.nome}</td>
                  </tr>
                </table>
                <Link
                  to={`/sobre_fazenda/${fazenda.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Entrar na Fazenda
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default SobreCooperador;
