import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CooperadoresService from "../../services/cooperador";
import CardCooperador from "../../components/cooperador/cardCooperador";
import Menu from "../../components/menu/NavBar";
import "./styles.css";
import Loading from "../../components/loading/loading";

const Cooperador = () => {
  const [VerCooperadores, setVerCooperadores] = useState([]);
  const [loading, setLoading] = useState(true);

  const [itensPorPage, setitensPorPage] = useState([8]);
  const [correntPage, setcorrentPage] = useState([0]);

  const pages = Math.ceil(VerCooperadores.length / itensPorPage);
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerCooperadores.slice(startItens, endItens);

  const VerOsCooperadores = async () => {
    await CooperadoresService.getCooperadores()
      .then((response) => {
        setVerCooperadores(response.data);
        setLoading(true);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    VerOsCooperadores();
    
  }, []);

 
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder txt-inicio-coop">
            Nossos Cooperadores
          </h1>
        </div>
      </section>
        <Loading/>
      <section className="animais">
        <div className="m-2 ani_p">
          {/*  */}
          {correntItens.map((cooperador) => (
            <CardCooperador
              codigo={cooperador.codigo}
              imgUrl={`${cooperador.imgUrl}`}
              nome={cooperador.nome}
              sobrenome={cooperador.sobrenome}
              // email={cooperador.email}
              item={cooperador}
              key={cooperador.codigo}
            />
          ))}
        </div>
      </section>
      <section className="sect">
        {Array.from(Array(pages), (VerOsCooperadores, index) => {
          return (
            <button
              className="pagB"
              value={index}
              onClick={(e) => setcorrentPage(Number(e.target.value))}
            >
              {index + 1}
            </button>
          );
        })}
      </section> 
    </>
  );
};

export default Cooperador;
