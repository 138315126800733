import api from "./api";

const getFazendas = () => {
    return api.get("fazendas");
};
const getCodigoFazenda = (codigo) => {
    return api.get(`fazenda/${codigo}`);
};
const getAnimalFazenda = (codigo) => {
    return api.get(`FazendaAnimais/${codigo}`);
};


export default { getFazendas, getCodigoFazenda, getAnimalFazenda }