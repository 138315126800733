/* eslint-disable import/no-anonymous-default-export */
import api from "./api";

const getTranportes = () => {
  return api.get("transportadores");
};
const VerTransporteUser = (codigo) => {
  return api.get("totalEntrega/" + codigo);
};
// Verificar se o Usuário possui entregas
const VerUserExisteTransporte = (codigo) => {
  return api.get("verifiByvisitanteExiste/" + codigo);
};

const getPesquisaTransporte = (data) => {
  return api.get(`transportadorMarca/${data}`);
};
const postPesquisa = () => {
  return api.get("pesquisa");
};

const getPais = () => {
  return api.get("pais");
};
const getTotalEntregas = () => {
  return api.get("TotalEntrega");
};
const getVerTotalEntregaVisitante = (codigo) => {
  return api.get("TotalEntrega/CarrinhoGlobal/"+codigo);
};

const getUserCart = (codigo) => {
  return api.get(`carrinhofarm/${codigo}`);
};
const getUserCartAnimais = (codigo) => {
  return api.get(`carrinhoAnimalfarm/${codigo}`);
};
const getUserCartProduto = (codigo) => {
  return api.get(`carrinhoProdutofarm/${codigo}`);
};
/***** */
const getUserCartTrans = (codigo) => {
  return api.get(`carrinhofazenda/${codigo}`);
};
/*** */
const VerUserDestino = (codigo) => {
  return api.get(`listaV/${codigo}`);
};
const getUserCartEntregas = (codigo) => {
  return api.get(`carrinho_visitante/${codigo}`);
};

// --------------- Pesquisar Provincias e Municicpios -----------------------------
const getPesquisaProvincias = (codigo) => {
  return api.get(`provincia/${codigo}`);
};

const getPesquisaMunicipios = (codigo) => {
  return api.get(`municicpio/${codigo}`);
};
/* **************** DISTANCIA ENTRE AS PROVINCIA ******************** */
const getInicioFinal = (codigo) => {
  return api.get(`distancia/${codigo}`);
};
/* **************** FIM DISTANCIA ENTRE AS PROVINCIA ******************** */
const getInicialFinal = (codigo1, codigo2) => {
  return api.get(`distInicialFinal/${codigo1}/${codigo2}`);
};
const cadAddTotal = (codigo1, data) => {
  return api.put(`totalEPreco/${codigo1}`, data);
};
const createPostFazendaValor = (data) => {
  return api.post(`criar_fazendaValor`, data);
};

const createDestino = (data) => {
  return api.post(`criar_totalEntrega`, data);
};
const putDestino = (codigo, data) => {
  return api.put(`totalEntregaMunicipio/${codigo}`, data);
};
const createTransporte = (codigo, data) => {
  return api.put(`totalEntrega/${codigo}`, data);
};
const VerValorEntrega = (data) => {
  return api.post(`criar_transporteChoosed`, data);
};

export default {
  getTranportes,
  getPesquisaTransporte,
  postPesquisa,
  getPais,
  getPesquisaProvincias,
  getPesquisaMunicipios,
  getUserCart,
  getUserCartAnimais,
  getUserCartProduto,
  getInicialFinal,
  createDestino,
  putDestino,
  createTransporte,
  VerTransporteUser,
  getUserCartEntregas,
  VerValorEntrega,
  VerUserDestino,
  cadAddTotal,
  VerUserExisteTransporte,
  getVerTotalEntregaVisitante,
  getUserCartTrans,
  getTotalEntregas,
  getInicioFinal,
  createPostFazendaValor
};
