import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import { getVerMinhasComprasCodigo } from "../../services/ComprasService";
import CardComprasUser from "./compras/CardComprasUser"
const ComprasUser = (props) => {
  const { user } = useContext(AuthContext);

  const logado = user?.codigo;

  useEffect(() => {
    VerCompra();
  });

  const [global, setglobal] = useState(0)
  const [VerCompras, setVerCompras] = useState([]);
  const VerCompra = async () => {
    await getVerMinhasComprasCodigo(logado).then((response) => {
      setVerCompras(response.data);
    });
  };

  //
  return (
    <>
      <div>
        <table style={{ backgrounColor: "grey" }} className="responsiveTable">
          <thead>
          <tr>
            <th scope="col">Imagem</th>
            <th scope="col" style={{ minWidth: "200px" }}>Descrição</th>
            <th scope="col">Preço</th>
            <th scope="col">Estado</th>
            <th scope="col">Ver Comprovativo</th>
          </tr>
          </thead>
        {VerCompras.map((compra, index) => (
            <CardComprasUser index={index + 1} id={compra.codigo} estado={compra.statusCarrinhoGlobal} global={compra.codigo}/>
          ))}
        </table>
        {/* <section className="sect" style={{ marginTop: "40%", width: "100%" }}>
          {Array.from(Array(pages), (VerOsCooperadores, index) => {
            return (
              <button
                className="pagB"
                value={index}
                onClick={(e) => setcorrentPage(Number(e.target.value))}
              >
                {index + 1}
              </button>
            );
          })}
        </section> */}
      </div>
    </>
  );
};

export default ComprasUser;
