import api from "./api";

const getLeilaoExclusivo = () => {
    return api.get("LeilaoExclusivo");
};

const getItemLeilaoExclusivo = (codigo) => {
    return api.get(`ItemExclusivoPLeilao/${codigo}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getLeilaoExclusivo, getItemLeilaoExclusivo }