import api from "./api";

const getAnimalItem = (codigo) => {
    return api.get(`ItemDetail/${codigo}`);
};
const getLoteRapido = (codigo) => {
    return api.get(`loteRapidoByLote/${codigo}`);
};
const getRacaLoteRapido = (codigo) => {
    return api.get(`RacasByLote/${codigo}`);
};
const getLoteExistente = (codigo) => {
    return api.get(`loteAnimal/${codigo}`);
};
const getPostionUser = (codigo) => {
    return api.get(`lances/posicaoLancesvalidos/${codigo}`);
};

const getLicitadores = (cod_item) => {
    return api.get(`lances/todoslances/${cod_item}`);
};

const getUltimoLance = (cod_item) => {
    return api.get(`lances/max/${cod_item}`);
};

const postLance = (data) => {
    return api.post(`lances`, data);
};

export default { getLoteExistente, getAnimalItem, getLicitadores, getUltimoLance, postLance, getLoteRapido, getRacaLoteRapido, getPostionUser }