import AppRoutes from "./AppRoutes";
import "toastr/build/toastr.min.js";
import "toastr/build/toastr.css";
import "./App.css";

function App() {
  return (
    <>

      <AppRoutes />
    </>
  );
}
export default App;