import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../components/menu/NavBar";
// import * as yup from "yup";

import {
  createPassworReset,
  visitanteEmail,
  enviarCodigoVisitante,
  putPasswordReset
} from "../services/email";

export function ForgetPassword() {
  //   const navigate = useNavigate()

  //Email do input
  const [Oemail, setOemail] = useState();
  const VerEmail = (e) => {
    if (e.target.value === undefined) {
      return;
    }
    setOemail(e.target.value);
  };
  const [VerCodigoForm, setVerCodigoForm] = useState();
  const getCodigoForm = (e) => {
    if (e.target.value === undefined) {
      return;
    }
    setVerCodigoForm(e.target.value);
  };
  // Pegar o codigo do visitante
  const [visitante, setVisitante] = useState({});
  const verVisitante = async (e) => {
    e.preventDefault();
    // if (!(await validate())) return;
    // if (conf_senha !== senha) {
    //   setStatus({
    //     type: "error",
    //     // mensagem: "As senhas são diferentes",
    //     mensagem: "senha e confirmar senha são diferentes",
    //   });
    //   return;
    // }
    await visitanteEmail(Oemail)
      .then((response) => {
        if (response.data === null) {
          Swal.fire({
            title: "O endereço de email inserido não existe",
            text: "Verifique se inseriu corretamente o endereço de email ou crie uma conta",
            icon: "warning",
            confirmButtonColor: "#686d25",
          });
        } else {
          setVisitante(response.data.codigo);
          enviarEmail(response.data.codigo);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const [verificar, setverificar] = useState(true);
  // const [verificar, setverificar] = useState(false);
  const enviarEmail = async (codigo) => {
    const data = {
      visitante: {
        codigo: codigo,
      },
    };
    console.log(data);
    await createPassworReset(data)
      .then((response) => {
        Swal.fire({
          title: "Email enviado com sucesso",
          text: "Enviamos a nova senha para o seu email",
          icon: "success",
          confirmButtonColor: "#686d25",
        });
        setverificar(false);
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const [verPassReset, setverPassReset] = useState(false);

  const enviarCodigo = async (e) => {
    e.preventDefault();

    await enviarCodigoVisitante(VerCodigoForm, visitante).then((response) => {
      if (response.data === "Unauthorized") {
        Swal.fire({
          title: "O código introduzido está incorreto",
          text: "Verifique o código que enviamos no seu endereço de email",
          icon: "warning",
          confirmButtonColor: "#686d25",
        });
      } else {
        console.log("Autorizado");
        // Swal.fire({
        //   title: "Codigo aceite",
        //   text: "Insira a nova palavra",
        //   icon: "success",
        //   confirmButtonColor: "#686d25",
        // });
        console.log(response);

        setverPassReset(true);
      }
      console.log(response);
      // setverificar("verificado");
    });
  };

  const [password, setpassword] = useState();
  const editPassword = (e) => {
    if (e.target.value === undefined) {
      return;
    }
    setpassword(e.target.value);
  };
  const [alterado, setalterado] = useState(false);
  const editarPassword = async (e) => {
    e.preventDefault();
    const data = {
      senha: password
    }
    await putPasswordReset(visitante, data)
    .then((response) => {
      Swal.fire({
        title: "Palavra-passe alterada com sucesso",
        text: "A sua palavra-passe foi alterada com sucesso, agora pode fazer login com a nova palavra-passe",
        icon: "success",
        confirmButtonColor: "#686d25",
      });
      setalterado(true);
      console.log(response);
      // setverificar("verificado");
    });
  };
  

  // const [status, setStatus] = useState({
  //   type: "",
  //   mensagem: "",
  // });

  // async function validate() {
  //   let schema = yup.object().shape({
  //     senha: yup
  //       .string("O campo senha obrigatório!")
  //       .required("O campo senha obrigatório!")
  //       .min(6, "A senha deve ter 6 caracteres!")
  //       .max(6,"A senha deve ter 10 caracteres!"),

  //     email: yup
  //       .string("O campo email obrigatório!")
  //       .required("O campo email obrigatório!")
  //       .email("e-mail inválido!")
  //       .matches(/^(?!.*@[^,]*,)/),
     
  //   });
  //   try {
  //     await schema.validate(Oemail);
  //     return true;
  //   } catch (err) {
  //     setStatus({
  //       type: "error",
  //       mensagem: err.errors,
  //     });
  //     return false;
  //   }
  // }


  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Redifinição de senha</h1>
        </div>
      </section>
      <section id="section" className="verde sobre" style={{ display: "flex" }}>
        {/* <p>Enviamos </p> */}

        {verificar ? (
          <div className="login_texto">
            <div className="p_s is-flex">
              <form onSubmit={(e) => verVisitante(e)}>
                <div className="cadastro">
                  <div className="e_cima is-flex justify-space-between">
                    <p style={{ textAlign: "center", margin: "10px" }}>
                      Insira o seu endereço email para enviarmos a sua nova
                      palavra passe
                    </p>
                  </div>
                  <div className="s_meio">
                  {/* {status.type === "error" ? (
                    <div className="alert_campos">
                      <p>{status.mensagem}</p>
                    </div>
                  ) : (
                    ""
                  )} */}
                    <p className="s_pp is-flex">
                      <input
                        type="email"
                        placeholder="Email"
                        minLength={2}
                        maxLength={30}
                        required
                        onChange={(e) => VerEmail(e)}
                      />
                    </p>
                  </div>
                  <div className="s_bot is-flex" style={{ marginTop: "20px" }}>
                    <div></div>
                    <div>
                      <Link to="/login">
                        <button
                          className="s_btn"
                          style={{ marginRight: "30px" }}
                        >
                          Cancelar
                        </button>
                      </Link>
                      <button className="s_btn">Enviar Email</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>
            {!verPassReset ? (
              <div className="login_texto">
                <div className="p_s is-flex">
                  <form onSubmit={(e) => enviarCodigo(e)}>
                    <div className="cadastro">
                      <div className="e_cima is-flex justify-space-between">
                        <p style={{ textAlign: "center", margin: "10px" }}>
                          Insira o código de 6 digitos que esta no seu email
                        </p>
                      </div>
                      <div className="s_meio">
                        <p className="s_pp is-flex">
                          <input
                          id="place_input"
                            type="Number"
                            placeholder="000000"
                            required
                            onChange={(e) => getCodigoForm(e)}
                            style={{fontSize: "30px", letterSpacing: "5px", margin: "auto", textAlign: "center" }}
                          />
                        </p>
                      </div>
                      <div
                        className="s_bot is-flex"
                        style={{ marginTop: "20px" }}
                      >
                        <div></div>
                        <div>
                          <Link to="/login">
                            <button
                              className="s_btn"
                              style={{ marginRight: "30px" }}
                            >
                              Cancelar
                            </button>
                          </Link>
                          <button className="s_btn">Enviar</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                {!alterado ? (
                  <div className="login_texto">
                    <div className="p_s is-flex">
                      <form onSubmit={(e) => editarPassword(e)}>
                        <div className="cadastro">
                          <div className="e_cima is-flex justify-space-between">
                            <p style={{ textAlign: "center", margin: "10px" }}>
                              Insira a sua nova palavra-passe
                            </p>
                          </div>
                          <div className="s_meio">
                            <p className="s_pp is-flex">
                              <input
                                type="password"
                                placeholder="Nova Palavra passe"
                                required
                                onChange={(e) => editPassword(e)}
                              />
                            </p>
                          </div>
                          <div
                            className="s_bot is-flex"
                            style={{ marginTop: "20px" }}
                          >
                            <div></div>
                            <div>
                              <Link to="/login">
                                <button
                                  className="s_btn"
                                  style={{ marginRight: "30px" }}
                                >
                                  Cancelar
                                </button>
                              </Link>
                              <button className="s_btn">Enviar</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="login_texto">
                    <div className="p_s is-flex">
                      <form onSubmit={(e) => editarPassword(e)}>
                        <div className="cadastro">
                          <div className="e_cima is-flex justify-space-between">
                            <p style={{ textAlign: "center", margin: "10px" }}>
                              Palavra-passe alterada com sucesso
                            </p>
                          </div>
                          <div className="s_meio" style={{textAlign: "center", display: "flex", justifyContent: "center"}}>
                            <p className="s_pp is-flex" style={{padding: "15px 30px", textAlign: "center"}}>
                              Agora pode fazer login com a sua nova palavra-passe
                            </p>
                          </div>
                          <div
                            className="s_bot is-flex"
                            style={{ marginTop: "20px" }}
                          >
                            <div></div>
                            <div>
                              <Link to="/login">
                                <button
                                  className="s_btn"
                                  style={{ marginRight: "30px" }}
                                >
                                  Fazer Login
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
}
export default ForgetPassword;
