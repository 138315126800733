import Menu from "../../components/menu/NavBar";
import noticias from "../../services/noticias";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";

const Termos = () => {
  const [VerNoticia, setVerNoticia] = useState([]);

  const VerAsNoticias = async () => {
    noticias
      .getNoticias()
      .then((response) => {
        setVerNoticia(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };
  
  useEffect(() => {
    VerAsNoticias();
  }, []);
  return (
    <>
      <Menu />
      <main id="main">
        
          <div className="fundo_termos">
          <div className="margem">
            <h1>
              O que é abrangido por estes termos
            </h1>
            <p>
              Sabemos que é tentador ignorar estes Termos de Utilização, mas é
              importante estabelecer o que pode esperar de nós à medida que
              utiliza serviços da CWAM, bem como o que podemos esperar de si.
            </p>
            </div>

            <div className="margem">
              <h1>
                A sua relação com a CWAM
              </h1>
              <p>
                Os presentes termos ajudam a definir a relação entre si e a
                CWAM.
              </p>
              <p>
                Em termos gerais, concedemos-lhe autorização para utilizar os
                nossos serviços, se aceitar respeitar os presentes termos, quais
                refletem o modo de funcionamento do negócio da CWAM e como a
                mesma ganha dinheiro.
              </p>
            </div>

            <div className="margem">
              <h1>
                O que pode esperar de nós
              </h1>
              <p>Fornecemos uma ampla gama de serviços úteis.</p>
              <p>
                Fornecemos uma ampla gama de serviços sujeitos aos presentes
                termos que incluem:
                <li>Compras online</li>
                <li>Leilão Virtual</li>
                <li>Serviço de Streaming nos Leilões</li>
                <li>Geolocalização das Fazendas</li>
                <li>Mapeamento dos Animais</li>
              </p>
            </div>

            <div className="margem">
              <h1>
                {" "}
                O que esperamos de si
              </h1>
              <p>
                Siga estes termos e os termos adicionais específicos de serviços
              </p>
              <h1>
                Respeite os outros
              </h1>
              <p>
                Pretendemos manter um ambiente de respeito para todos, o que
                significa que tem de seguir estas regras básicas de conduta:
                <li>
                  agir em conformidade com as leis aplicáveis, incluindo leis de
                  controlo de exportações, sanções e leis sobre o tráfico de
                  pessoas
                </li>
                <li>
                  respeitar os direitos de terceiros, incluindo direitos de
                  privacidade e propriedade intelectual
                </li>
                <li>
                  não cometer abuso nem danos contra terceiros ou contra si
                  próprio (ou ameaçar ou incentivar tal abuso ou danos), por
                  exemplo, ao induzir em erro, defraudar, roubar a identidade de
                  outra pessoa ilegalmente, difamar, praticar bullying, assediar
                  ou perseguir e intimidar terceiros
                </li>
                <li>
                  não abusar, interferir ou interromper os serviços — por
                  exemplo, ao acedê-los ou utilizá-los de formas fraudulentas ou
                  enganadoras, introduzir software malicioso, efetuar spamming,
                  pirataria ou contornar os nossos sistemas ou medidas de
                  proteção. Quando indexamos a Web para lhe fornecer resultados
                  da pesquisa, respeitamos as restrições de utilização padrão
                  que os proprietários do Website especificam no código dos
                  respetivos Websites, pelo que exigimos o mesmo quando outras
                  pessoas utilizam os nossos serviços
                </li>
              </p>
            </div>

            <div className="margem">
              <h1>
                {" "}
                A sua relação com a CWAM
              </h1>
              <p>
                Os presentes termos ajudam a definir a relação entre si e a
                CWAM.
              </p>
              <p>
                Em termos gerais, concedemos-lhe autorização para utilizar os
                nossos serviços, se aceitar respeitar os presentes termos, quais
                refletem o modo de funcionamento do negócio da CWAM e como a
                mesma ganha dinheiro.
              </p>
            </div>
          </div>
     
      </main>
    </>
  );
};

export default Termos;
