import React, { useContext, useEffect, useState } from 'react'
import noticias from "../../services/noticias";
import Resposta from "../../pages/resposta/Resposta";
import Swal from "sweetalert2";
import { useParams, Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AuthContext } from "../../contexts/Auth";

const Comentario = ({ noticiaCodigo }) => {
  const { isAuthenticated, user } = useContext(AuthContext);

  const logado = user?.codigo;


  const [VerComentario, setVerComentario] = useState([]);
  const [idComentario, setIdComentario] = useState([]);
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = (codigo) => {
    setOpen(true);
    setIdComentario(codigo);
};


const handleClose = () => {
    setOpen(false);
};


  const [comentario, setComentario] = useState({
    conteudo: "",
    noticia: {
      codigo: noticiaCodigo,
    },
    visitante: {
      codigo: logado
    },
  });

  // const { conteudo} = comentario;
  const [conteudo, setConteudo] = useState("");

      //Limpar Campos
      const handleClear = () => {
        setConteudo({
          conteudo: "",
        });
    };

  const onInputChange = (e) => {
    setComentario({ ...comentario, [e.target.name]: e.target.value });
  };

  const saveUpdateComentario = async (e) => {
    e.preventDefault();

    const comentario = {
      conteudo,
      noticia: {
        codigo: noticiaCodigo,
      },
      visitante: {
        codigo: logado
      },
    }

    //
    await noticias
      .createComentario(comentario)
      .then((response) => {
        if (response.data === "saved") {
          Swal.fire({
            title: "Comentário Enviado com sucesso",
            icon: "success",
            confirmButtonColor: "#686d25",
          });
        }
        handleClear();
        // console.log(VerComentario.data)
      })
      .catch(() => {
        Swal.fire({
          title: "Erro de conexão",
          icon: "error",
          confirmButtonColor: "#686d25",
        });
      });
  };



  const VerOsComentarios = async () => {
    await noticias.getComentarios(noticiaCodigo)
      .then((response) => {
        setVerComentario(response.data);
        const result = response.data[0].codigo;
        setIdComentario(result);
      })
      .catch(() => {
        // Swal.fire({
        //   title: "Erro em estabelecer a Conexão"
        // });
      });
  };

  useEffect(() => {
    VerOsComentarios();
  });

  const pages = Math.ceil(VerComentario.length);
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  //
  const modules = { toolbar: { container } };
  //
  return (
    <>
      {/* <!-- End blog entry --> */}

      <div
        className="blog-comments view_noticia_branca"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <h4 className="comments-count">{pages} Comentarios</h4>
        {VerComentario.map((comentario) => (
          <div
            id="comment-1"
            className="comment"
            style={{
              backgroundColor: "#b46739",
              borderRadius: "10px",
              margin: "10px",
              padding: "10px",
            }}
          >
            <div className="d-flex">
              <div className="comment-img">
                <img src="assets/img/blog/comments-1.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <p>
                    <strong>Por :</strong> {comentario.visitante.nome}
                  </p>
                </h5>
                <time datetime="2020-01-01">
                  {new Date(comentario.date).getDate() === new Date().getDate() &&
                    new Date(comentario.date).getMonth() === new Date().getMonth() &&
                    new Date(comentario.date).getFullYear() ===
                    new Date().getFullYear() ? (
                    <>
                      Hoje às {new Date(comentario.date).getHours()}:
                      {new Date(comentario.date).getMinutes()} (
                      {new Date(new Date() - new Date(comentario.date)).getHours() - 1 >
                        0 ? (
                        <>
                          {new Date(new Date() - new Date(comentario.date)).getHours() -
                            1}
                          h
                        </>
                      ) : (
                        ""
                      )}{" "}
                      {new Date(new Date() - new Date(comentario.date)).getMinutes()} min
                      atrás )
                    </>
                  ) : (
                    <>
                      {new Date(comentario.date).getDate()} de {" "}
                      {meses[new Date(comentario.date).getMonth()]} de{" "}
                      {new Date(comentario.date).getFullYear()} às{" "}
                      {new Date(comentario.date).getHours()}:
                      {new Date(comentario.date).getMinutes()}
                    </>
                  )}
                </time>
                <p className="view_noticia_letra_preta" style={{ lineHeight: '1.5', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: comentario.conteudo }}>
                  
                </p>
                {/*  */}
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  {/* <Link to="">  <p>Apagar</p></Link> */}
                  {/* <Link to="">  <p>Editar</p></Link> */}
                  <Link to=""  onClick={() => handleClickOpen(comentario.codigo)}>  <p>Responder</p></Link>
                   </div>
              {/*  */}
              </div>
             
            </div>
             
          </div>
        ))}

        <div className="reply-form">
          <form
            onSubmit={(e) => saveUpdateComentario(e)}
            style={{ textAlign: "center" }}
          >
            <div className="row"></div>
            <div className="row">
              <div className="col form-group">
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  className='custom-quill'
                  name="conteudo"
                  value={conteudo}
                  onChange={setConteudo}
                  style={{ maxHeight: '300px' }} // Defina a altura máxima conforme necessário
                />

                <br />
                {!isAuthenticated ?
                  "Entra com sua conta para poder comentar"
                  :
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#585e0e",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Comentar
                  </button>
                }

              </div>
            </div>
          </form>
        </div>
      
      
      </div>
      <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogContent>
                    <Resposta idComentario={idComentario} />
                </DialogContent>
            </Dialog>
      {/* <!-- End blog comments --> */}

    </>
  );
};


//
let container = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ["blockquote", "code-block"],

  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

//
export default Comentario;
