import React, { useEffect, useState } from "react";

const Loading = () => {
    const [loading, setLoading] = useState(true);
  
  
    useEffect(() => {
        load()
        setLoading(false)
    }, []);
  

    const load  = () =>  {  
      
      if(loading) {
      return <div style={{ color: "red", width: "300px", height: "300px", position: "absolute"}}></div>
     }
    }
  
    return (
      <>
       
      </>
    );
  };
  
  export default Loading;