import Swal from "sweetalert2";
import { useState } from "react";
import FooterService from "../services/footer";
import { Link } from "react-router-dom";
import * as yup from "yup";

const facebook = require("./imgs/icones-10.png");
const linkeDin = require("./imgs/icones-12.png");
const twitter = require("./imgs/icones-13.png");
const youtube = require("./imgs/icones-14.png");
const instagram = require("./imgs/icones-15.png");
const whatsapp = require("./imgs/icones-16.png");

//const logo = require("./imgs/logo.png");
const fale = require("./imgs/falewhatsapp.png");

const Footer = () => {
  // const logado = user?.id;

  const [footer, setFooter] = useState({
    nome: "",
    email: "",
    assunto: "",
    messagem: "",
  });

  const { nome, email, assunto, messagem } = footer;

  const onInputChange = (e) => {
    setFooter({ ...footer, [e.target.name]: e.target.value });
  };

  const limpar = () => {
    setFooter({
      nome: "",
      email: "",
      assunto: "",
      messagem: "",
    });
  };
  const saveUpdatefooter = async (e) => {
    e.preventDefault();
    if (!(await validate())) return;
    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("email", email);
    formData.append("assunto", assunto);
    formData.append("messagem", messagem);

    //
    await FooterService.enviarMensagem(formData)
      .then((response) => {
        if (response.data === "saved") {
          Swal.fire({
            title: "Mensagem Enviada com sucesso",
            text: "Recebemos a sua mensagem, mais tarde entraremos em contacto consigo",
            icon: "success",
            confirmButtonColor: "#686d25",
          });
        }
        limpar();
      })
      .catch(() => {
        Swal.fire({
          title: "Erro de conexão",
          text: "Verifique a sua internet e tente novamente",
          icon: "error",
          confirmButtonColor: "#686d25",
        });
      });
  };

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //VAlidacao
  async function validate() {
    let schema = yup.object().shape({
      nome: yup
        .string("O campo nome é obrigatório!")
        .required("O campo nome é obrigatório!"),

      email: yup
        .string("O campo email é obrigatório!")
        .required("O campo email é obrigatório!")
        .email("e-mail inválido!"),

      assunto: yup
        .string("O campo assunto é obrigatório!")
        .required("O campo assunto é obrigatório!"),

      messagem: yup
        .string("O campo messagem é obrigatório!")
        .required("O campo messagem é obrigatório!"),
    });
    try {
      await schema.validate(footer);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  return (
    <>
      <section id="contato">
        <div id="atendimento-whatsapp">
          <a href="https://wa.me/message/DSYJKNYIPFRAJ1" target="_blank">
            <img src={fale} alt="" />
          </a>
        </div>
        <h1 id="texto-1">Fale Conosco</h1>
        {status.type === "error" ? (
            <p
              className="esconder"
              style={{ color: "##AD0406"}}
            >
              {status.mensagem}
            </p>
          ) : (
            ""
          )}
        <form
          onSubmit={(e) => saveUpdatefooter(e)}
          className="formulario-contato"
        >
  
          <div className="fale is-flex is-justify-content-space-between is-align-items-center">
            <div className="">
              <input
                name="nome"
                value={nome}
                onChange={(e) => onInputChange(e)}
                type="text"
                placeholder="Nome Completo:"
                // required
                // minLength={2}
                // maxLength={30}
              />

              {/* Email */}

              <input
                // required
                name="email"
                value={email}
                onChange={(e) => onInputChange(e)}
                type="email"
                placeholder="Email:"
              />

              {/* Assunto */}

              <input
                // required
                type="text"
                name="assunto"
                value={assunto}
                onChange={(e) => onInputChange(e)}
                placeholder="Assunto:"
                // minLength={2}
                // maxLength={40}
              />
            </div>

            {/* Mensagem */}

            <div className="">
              <textarea
                // required
                // minLength={10}
                // maxLength={300}
                type="text"
                name="messagem"
                value={messagem}
                onChange={(e) => onInputChange(e)}
                style={{ padding: "15px 0 0 15px", marginTop: "40px" }}
                placeholder="Mensagem:"
              ></textarea>
              <div>
                <button
                  className="botao_enviar verde_escuro"
                  style={{ cursor: "pointer" }}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </form>

        <div
          className="is-flex is-justify-content-space-between is-align-items-center"
          style={{ width: "90%", margin: "auto", marginBottom:"40px" }}
        >
          <div className="">
            <h2 id="texto-2">Contactos</h2>
            <p className="paragrafo-1">+244 921 441 512</p>
            <p className="paragrafo-1">+244 222 771 281</p>
            <p className="paragrafo-1">cooperativacwamahuila@gmail.com</p>
            <p className="paragrafo-1">Lubango-Huíla-Angola</p>
            {/* <p className="paragrafo-1">Bairro: Nossa Srª do Monte</p> */}
          </div>
          <div className="is-flex is-justify-content-space-between is-align-items-center">
            <div>
              <Link to="/venda">
                <p className="paragrafo-2">Vendas</p>
              </Link>
              <Link to="/servicos">
                {" "}
                <p className="paragrafo-2">Serviços</p>
              </Link>
              <Link to="/fazendas">
                <p className="paragrafo-2">Parceiros</p>
              </Link>
              <Link to="/">
                <p className="paragrafo-2">Cooperativa</p>
              </Link>
            </div>
          </div>
        </div>
      </section>

       <footer id="rodape">
        <hr style={{ background: "#9ba236" }} />
        <div className="redes_sociais">
          <a href="https://web.facebook.com/profile.php?id=100086346207600" target="_blank">
            <img src={facebook} alt="facebook.com" className="redes" />
          </a>
          <a href="#" target="_blank">
            <img src={instagram} alt="instagram.com" className="redes" />
          </a>
          {/* <a href="#">
            <img src={whatsapp} alt="whatsapp.com" className="redes" />
          </a> */}
          <a href="https://www.youtube.com/@CWAMTV">
            <img src={youtube} alt="youtube.com" className="redes" />
          </a>
          {/* <a href="https://www.twitter.com/">
            <img src={twitter} alt="twitter.com" className="redes" />
          </a> */}
          {/* <a href="https://www.linkendin.com/">
            <img src={linkeDin} alt="linkeDin.com" className="redes" />
          </a> */}
        </div>
        <div className="texto-rodape">
          {/* <div className="is-flex is-justify-content-space-between is-align-items-center ladoss">
            <div className="">
              <Link to="/Politicas">
                {" "}
                <p>Políticas e Privacidade</p>
              </Link>
            </div>

            <div className="">
              <Link to="/termosdeultilizacao">
                <p className="paragrafo-2">Termos de Uso</p>
              </Link>
            </div>
          </div> */}

          <p style={{ marginTop: "20px" }}>
            Copyright &copy; CWAM - Todos os Direitos Reservados
          </p>
          {/* <p>Desenvolvido pela Tecno Base</p> */}
        </div>
      </footer> 
    </>
  );
};

export default Footer;
