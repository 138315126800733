//import "./styles.css"
import Menu from "../../components/menu/NavBar";
import InfoUser from "../../components/perfil/InfoUser";
import MenuPerfil from "../../components/perfil/MenuPerfil";
import DadosUser from "../../components/perfil/DadosUser";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UserPerfil from "../../services/UserPerfil";
const PerfilUserDados = () => {
  const { email } = useParams();
  const [VerProfile, setVerProfile] = useState({});
  const onInputChange = (e) => {
    setVerProfile({ ...VerProfile, [e.target.name]: e.target.value });
  };
  const { nome } = setVerProfile;

  const VerAsInformacaoUser = async () => {
    UserPerfil.getInformacaoUser(email)
      .then((response) => {
        setVerProfile(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    VerAsInformacaoUser();
  });
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar"></section>

      <section className="card_user">
        <div className="card_user2">
          <InfoUser />
          <div className="card_filho2">
            <div className="O_menu_do_usuario">
              <MenuPerfil />
            </div>
            <h1 className="h1_text_user">Seus Dados</h1>
            <div className="corpo_das_informacoes">
              <DadosUser VerProfile={VerProfile} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PerfilUserDados;
