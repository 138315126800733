import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import "./styles.css";
import { Link } from "react-router-dom";
import LeilaoService from "../../services/leilao";
import Menu from "../../components/menu/NavBar";

const LeilaoOnline = () => {
  const [Leilao, setLeilao] = useState([]);
  const [totalTime, setTotalTime] = useState([60 * 60]);
  const [loading, setLoading] = useState(true);

  const minutos = Math.floor(totalTime / 60);
  const segundos = totalTime % 60;

  useEffect(() => {
    if (totalTime === 0) {
      alert("O tempo acabou");
    } else {
      setTimeout(() => {
        // VerLeilao();
        setTotalTime(totalTime - 1);
      }, 1000);
    }
    VerLeilao();
  }, [totalTime]);

  // Pegar os animais
  const VerLeilao = async () => {
    setLoading(true)
    await LeilaoService.getLeilao().then((response) => {
      setLeilao(response.data);
    });
  };

  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section
        className="fundo_entrar"
        style={{ backgroundImage: "../imgs/Cultura Coop_Huíla II.png" }}
      >
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Leilão Fazendas</h1>
        </div>
      </section>

      <section className="animais">
          {loading && !Leilao && 
        <p style={{ color: "red"}}></p>
        }
        <div className="m-2 ani_p">
          {Leilao.map((ver) => (
            <div className="am_p" key={ver.codigo}>
             <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${ver.fazenda.logo})`,
                  // backgroundImage: `url(${ver.fazenda.logo})`,
                  borderRadius: "20px",
                }}
              ></div>
              {/* <img className="img-logo-empresa" src={`https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${fazenda.logo}`} alt="" />  */}
              <div className="mi">
              <span style={{ width : "30px", height: "10px", backgroundColor: "green", borderRadius: "250px", marginLeft: "45%" }}> </span>
              <strong style={{ color : "green" }}>{ver.status}</strong> 
      
              
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {ver.fazenda.nome}
                </h2>
                <table>
                  <tr>
                    <td>Data:</td>
                    <td>{ver.dataLeilao}</td>
                  </tr>
                </table>
         
                <Link
                  to={`/LeilaoItem/${ver.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Ver Mais
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default LeilaoOnline;
