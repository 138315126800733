import api from "./api";

const getAnimalItem = (codigo) => {
    return api.get(`ItemExclusivoDetail/${codigo}`);
};

const getLicitadores = (cod_item) => {
    return api.get(`lanceExclusivo/todoslances/${cod_item}`);
};

const getUltimoLance = (cod_item) => {
    return api.get(`lanceExclusivo/max/${cod_item}`);
};

const postLance = (data) => {
    return api.post(`lanceExclusivo`, data);
};

const getLoteRapido = (codigo) => {
    return api.get(`loteRapidoByLote/${codigo}`);
};

const getLoteExistente = (codigo) => {
    return api.get(`loteAnimal/${codigo}`);
};

export default { getAnimalItem, getLicitadores, getUltimoLance, postLance, getLoteRapido, getLoteExistente }