import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import VendaService from "../../services/vendas";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "animate.css";
const Cart = () => {
  const { user } = useContext(AuthContext);
  const logado = user?.codigo;
  // const logado = 39;

  const icon = require("../imgs/icons/cart_carrinho.png");
  const [btnState, setBtnState] = useState(false);

  const ver_modal_cart = () => {
    setBtnState((btnState) => !btnState);
    VerOsAnimaisProdutosCarrinho();
  }
  function fechar_modal_cart() {
    setBtnState((btnState) => false);
  }
  let toggleClassCheck = btnState ? "active_cart" : "";
  let toggleClassFilho = btnState
    ? "produtos_da_compra_cart_filho_animation"
    : "";

  // ----------------------------------------------
  const [VerAnimais, setVerAnimais] = useState([]);
  const [VerProdutosDoCarrinho, setVerProdutosDoCarrinho] = useState([]);

  useEffect(() => {
    VerOsAnimaisProdutosCarrinho();
  }, []);

  const VerOsAnimaisProdutosCarrinho = async () => {
    try {
      const response = await VendaService.getCarrinhoGlobal(logado);

      if (response.data.length > 0) {
        const codigoGlobal = response.data[0].codigo;
        const itemCardResponse = await VendaService.getItemCard2(codigoGlobal);

        setVerAnimais(itemCardResponse.data);
        // VerOtotalLote(codigoGlobal);
        VerOsProdutosCarrinho(codigoGlobal);
        VerOtotalProduto(codigoGlobal);
      } else {
        // Lidar com o caso em que não há itens no carrinho
      }
    } catch (error) {
      // Lidar com erros, por exemplo, exibir uma mensagem de erro ao usuário
      console.error("Erro ao buscar itens do carrinho:", error);
    }
  };


  // Ver os produtos do carrinho
  const VerOsProdutosCarrinho = async (id) => {
    await VendaService.getProdutosCarrinho(id).then((response) => {
      setVerProdutosDoCarrinho(response.data);
    
    })
  };
  // const [totalCarrinho, setTotalCarrinho] = useState(0)
  // const VerOsProdutosCarrinho = async (id) => {
  //   await VendaService.getProdutosCarrinho(id).then((response) => {
  //     setVerProdutosDoCarrinho(response.data);
  //     const vetores = response.data
  //     let tot = 0
  //     vetores.map((vetor)=>{
  //       tot += vetor?.artigoProduto?.preco * vetor?.quantidade
  //     })
  //     setTotalCarrinho(tot)
      
  //   })
  // };

  // Total do carrinho
  const [valor, setValor] = useState(0);
  const VerOtotalLote = async (id) => {
    await VendaService.GetvalorCart(id).then((response) => {
      setValor(response.data);
      console.log(response.data)
    }).catch(() => {
      console.log(0)
    })
  };


    // Ver o total dos produtos
    const [valorProduto, setValorProduto] = useState(0);
    const VerOtotalProduto = async (id) => {
      await VendaService.GetTotalValorProduto(id).then((response) => {
        setValorProduto(response.data);
        console.log(response.data, "PRODUTOS CARRINHO")
      }).catch(()=>{
        console.log(0)
      })
    };




  // remover um lote
  const removerItem = async (id) => {
    await Swal.fire({
      width: 300,
      text: "Deseja mesmo remover?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba235",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim Remover",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        VendaService.removeItemCart(id)
          .then((response) => {
            VerOsAnimaisProdutosCarrinho();
            Swal.fire({
              width: 300,
              icon: "success",
              text: "Removido com sucesso",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            Swal.fire({
              width: 300,
              icon: "error",
              text: "Erro ao remover ao carrinho " + error,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  // Remover um produto
  const removerItemProduto = async (id) => {
    await Swal.fire({
      width: 300,
      text: "Deseja mesmo remover?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim Remover",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        VendaService.removeItemCartProduto(id)
          .then((response) => {
            VerOsProdutosCarrinho();
            Swal.fire({
              width: 300,
              icon: "success",
              text: "Removido com sucesso",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(() => {
            Swal.fire({
              width: 300,
              icon: "error",
              text: "Erro ao remover ao carrinho ",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  // Aumentar a quatidade do carrinho de produtos
  const MudarQtdAumentarProduto = async (id) => {
    await VendaService.updateQtdCartAumentProduto(id)
      .then((response) => {
        VerOsAnimaisProdutosCarrinho();
      })
      .catch(() => {
        Swal.fire({
          width: 300,
          icon: "error",
          text: "Erro de Conexão ",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  // Aumentar a quatidade do carrinho de produtos
  const MudarQtdDiminuirProduto = async (id, qtd) => {
    if (qtd === 1) {
      return;
    }
    await VendaService.updateQtdDiminuirProduto(id)
      .then((response) => {
        VerOsAnimaisProdutosCarrinho();
      })
      .catch(() => {
        Swal.fire({
          width: 300,
          icon: "error",
          text: "Erro ao Mudar a quantidade ",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  // ---------------------------------------------------

  // Páginação do Swipper
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '"  style="background-color: #B46739; text-align: center; width: 30px; height: 30px">' +
        '<div style="transform: translateY(6px)">' +
        (index + 1) +
        "</div>" +
        "</span>"
      );
    },
  };

  // Contar
  const tamanhoTela = window.innerWidth;
  var verSwi = 3;
  var verSwidist = 30;
  if (tamanhoTela <= 900) {
    verSwi = 2;
    verSwidist = 20;
  }
  if (tamanhoTela <= 700) {
    verSwi = 1;
  }

  return (
    <>
      <div
        className={`ver_compras_cart`}
        onClick={() => ver_modal_cart()}
        id="ver_compras_cart"
      >
        <img src={icon} alt="não encontrado" />
      </div>
      <div className={`produtos_da_compra_cart ${toggleClassCheck}`}>
        <div
          className={`produtos_da_compra_cart_filho animate__animated animate__fadeInDownBig ${toggleClassFilho}`}
        >
          {VerAnimais.length !== 0 || VerProdutosDoCarrinho.length !== 0 ? (
            <div>
              <div>
                <div className="flex_cima_carrinho">
                  <div>
                    <p
                      style={{
                        fontSize: "20px",
                        backgroundColor: "#B46739",
                        padding: "5px 20px",
                        borderRadius: "20px",
                      }}
                    >
                      {VerAnimais.length + VerProdutosDoCarrinho.length}
                    </p>
                  </div>
                  <div>
                    <h1>Suas compras</h1>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="contswiper">
                    <Swiper
                      slidesPerView={verSwi}
                      spaceBetween={verSwidist}
                      freeMode={true}
                      pagination={pagination}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {VerAnimais.map((animais, index) => (
                        <SwiperSlide>
                          <div className="sli_flex">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div></div>
                              <div
                                className="remover_do_carrinho"
                                title="remover do carrinho"
                                onClick={() => removerItem(animais.codigo)}
                              // style={estiloRemover}
                              >
                                x
                              </div>
                            </div>
                            <div
                              className="img_cart_img"
                              style={{
                                backgroundImage: `url(${animais.artigo.lote.imgUrl})`,
                              }}
                            ></div>
                            {/* <div
                            className="remover_do_carrinho"
                            title="remover do carrinho"
                            onClick={() => removerItem(animais.codigo)}
                            style={estiloRemover}
                          >
                            x
                          </div> */}

                            <div className="legenda_cart_legenda">
                              <div className="flex_nome_preco">
                                <div>
                                  <h4>
                                    {animais.artigo.lote.especie.especieAnimal}
                                  </h4>
                                </div>
                                <div>
                                  <span>
                                    {animais.artigo.preco.toLocaleString(
                                      "pt-br",
                                      { minimumFractionDigits: 2 }
                                    )}{" "}
                                    Kz
                                  </span>
                                </div>
                              </div>
                              <br />
                              <p className="qtd_do_cart"> </p>
                              <p>
                                Sub-total:{" "}
                                <span>
                                  {animais.total.toLocaleString("pt-br", {
                                    minimumFractionDigits: 2,
                                  })}{" "}
                                  Kz
                                </span>
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                      {VerProdutosDoCarrinho.map((produto, index) => (
                        <SwiperSlide>
                          <div className="sli_flex">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div></div>
                              <div
                                className="remover_do_carrinho"
                                title="remover do carrinho"
                                onClick={() =>
                                  removerItemProduto(produto.codigo)
                                }
                              // style={estiloRemover}
                              >
                                x
                              </div>
                            </div>
                            <div
                              className="img_cart_img"
                              style={{
                                backgroundImage: `url(${produto.artigoProduto.produto.imgUrl})`,
                              }}
                            ></div>

                            <div className="legenda_cart_legenda">
                              <div className="flex_nome_preco">
                                <div>
                                  <h4>{produto.artigoProduto.produto.nome}</h4>
                                </div>
                                <div>
                                  <span>
                                    {produto.artigoProduto.preco.toLocaleString(
                                      "pt-br",
                                      { minimumFractionDigits: 2 }
                                    )}{" "}
                                    Kz
                                  </span>
                                </div>
                              </div>
                              <p className="qtd_do_cart">
                                {" "}
                                <div>Qtd: </div>{" "}
                                <div className="btn_flex_cart">
                                  <div
                                    className="btn_btn_cart_qtd"
                                    onClick={() =>
                                      MudarQtdDiminuirProduto(
                                        produto.codigo,
                                        produto.quantidade
                                      )
                                    }
                                  >
                                    -
                                  </div>{" "}
                                  <span> {produto.quantidade}</span>
                                  <div
                                    className="btn_btn_cart_qtd"
                                    onClick={() =>
                                      MudarQtdAumentarProduto(produto.codigo)
                                    }
                                  >
                                    +
                                  </div>
                                </div>
                              </p>
                              <p
                              // onChange={vertotaoPreco(produto.artigo.preco)}
                              >
                                Sub-total:{" "}
                                <span>
                                  {produto.total.toLocaleString("pt-br", {
                                    minimumFractionDigits: 2,
                                  })}{" "}
                                  Kz
                                </span>
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className="baixo_cart_baixo">
                    <div className="baixo_cart_baixo_flex">
                      <div>
                        <h3>
                          Total:{" "}
                          <span>
                            {" "}
                            {(valorProduto + valor).toLocaleString("pt-br", {
                              minimumFractionDigits: 2,
                            })}
                            {/* {(totalCarrinho).toLocaleString("pt-br", {
                              minimumFractionDigits: 2,
                            })} */}
                            Kz{" "}
                          </span>
                        </h3>
                        <Link to="/finalizar_compra">
                          <button style={{ cursor: "pointer" }}>
                            Finalizar compra
                          </button>
                        </Link>
                      </div>
                      <div className="button_fechar_carrinho">
                        <button
                          onClick={fechar_modal_cart}
                          style={{ backgroundColor: "gray", cursor: "pointer" }}
                        >
                          Fechar carrinho
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h1>Adicione Items ao Seu carrinho </h1>
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <img src="/cartvazio.svg" alt="" width="400px" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
