import api from "./api";
const createVisitante = (data) => {
    return api.post("criar_visitante", data);
};

const infoVisitante = (codigo) => {
    return api.get("visitante/"+codigo);
};
const EditarVisitante = (codigo, data) => {
    return api.put(`visitante/${codigo}`, data);
};

export default { createVisitante, infoVisitante, EditarVisitante }