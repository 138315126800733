import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import { getLoteGeralCompras, getRacas}  from "../../services/ComprasService";

const ComprasUserInfoItems = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const logado = user?.codigo;
  const { lote } = useParams();
  // if(!logado){
  //   navigate("/login")
  // }

  useEffect(() => {
    getLoteGeral()
    getAsRacas()
  });

  const [verAnimais, setVerAnimais] = useState([]);
  const getLoteGeral = async () => {
    await getLoteGeralCompras(lote)
      .then((response) => {
        setVerAnimais(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [verRacas, setVerRacas] = useState([]);
  const getAsRacas = async () => {
    await getRacas(lote)
      .then((response) => {
        setVerRacas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return ( 
    <>
      <div className="info_doLote">       
        <div className="imgDOGeral">
        {/* <img src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemCooperador/${verAnimais.lote?.imgUrl}`} alt="" style={{objectFit: "cover", width: "100%", height: "100%", borderRadius: "10px"}} /> */}
        <img src={`${verAnimais.lote?.imgUrl}`} alt="" style={{objectFit: "cover", width: "100%", height: "100%", borderRadius: "10px"}} />
        </div>
        <div className="Legaenda_doLoteGeralInfo" >
          <h1>Detalhes</h1>
          <table style={{backgroundColor: "transparent"}} className="divVerLoteGeralCompra">
            <tr style={{backgroundColor: "transparent"}}>
              <td>Especie:</td> 
              <td>{verAnimais.numerosexoF}</td>
            </tr>
            <tr>
              <td>Quantidade: </td>
              <td>{verAnimais.qtd}</td>
            </tr>
            <tr>
              <td>Nº de machos:</td>
              <td>{verAnimais.numerosexoM}</td>
            </tr>
            <tr>
              <td>Nº de femeas:</td>
              <td>{verAnimais.numerosexoF}</td>
            </tr>
            <tr>
              <td>Peso Minimo:</td>
              <td>{verAnimais.pesomin}Kg</td>
            </tr>
            <tr>
              <td>Peso Maximo:</td>
              <td>{verAnimais.pesomaxi}Kg</td>
            </tr>
            <tr>
              <td>Idade Minima:</td>
              <td>{verAnimais.idademin} meses</td>
            </tr>
            <tr>
              <td>Idade Maxima:</td>
              <td>{verAnimais.idademax} meses</td>
            </tr>
            <tr>
              <td>Racas</td>
              <td>
              {verRacas.map((raca)=>(
                <span> {raca.raca.racaAnimal} </span>
              ))}
              </td>
            </tr>
            <tr>
              <td>Fazenda</td>
              <td>{verAnimais.lote?.fazenda?.nome}</td>
            </tr>            
          </table>
        </div>
      </div>
    </>
  );
};

export default ComprasUserInfoItems;
