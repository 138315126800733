import { useContext, useEffect, useState } from "react";
import VendaService from "../../services/vendas";
import Menu from "../../components/menu/NavBar";
import { AuthContext } from "../../contexts/Auth";

import { Link } from "react-router-dom";

function Finalizar() {
  const { user } = useContext(AuthContext);
  const logado = user?.codigo;
  // ----------------------------------------------
  const [VerAnimais, setVerAnimais] = useState([]);
  const [VerProdutosDoCarrinho, setVerProdutosDoCarrinho] = useState([]);

  useEffect(() => {
    if (logado)
      m();
  }, [logado]);

  const m = async () => {
    try {
      const response = await VendaService.getCarrinhoGlobal(logado)
      if (response.data.length > 0) {
        const codigoGlobal = response.data[0].codigo;
        await VendaService.getItemCard2(codigoGlobal).then((response) => {
          setVerAnimais(response.data);
          // VerOtotalLote(codigoGlobal);
        });

        VerOtotalProduto(codigoGlobal);
        VerOsProdutosCarrinho(codigoGlobal);
      }

    } catch (err) {
      console.log("Ocorreu em erro ao buscar os dados", err)
    }

  };
  const [valor, setValor] = useState(0);

  const VerOtotalLote = async (id) => {
    await VendaService.GetvalorCart(id).then((response) => {
      setValor(response.data);
    });
  };

  const [valorProduto, setValorProduto] = useState(0);
  const VerOtotalProduto = async (id) => {
    await VendaService.GetTotalValorProduto(id).then((response) => {
      setValorProduto(response.data);
    });
  };
  const [totalCarrinho, setTotalCarrinho] = useState(0)

  const VerOsProdutosCarrinho = async (id) => {
    await VendaService.getProdutosCarrinho(id).then((response) => {
      setVerProdutosDoCarrinho(response.data);
      const vetores = response.data
      let tot = 0
      vetores.map((vetor)=>{
        tot += vetor?.artigoProduto?.preco * vetor?.quantidade
      })
      setTotalCarrinho(tot)
    });
  };

  return (
    <>
      <Menu />

      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Finalizar Compras</h1>
        </div>
      </section>

      {VerAnimais.length !== 0 || VerProdutosDoCarrinho !== 0 ? (
        <div>
          <div>
            <h1
              style={{
                fontSize: "30px",
                color: "#fff",
                textAlign: "center",
                margin: "10px",
              }}
            >
              Suas compras
            </h1>
          </div>
          <div className="doisFinal">
            <div className="tabela_final">
              <div className="tabela_final_t">
                <table
                  style={{ backgroundColor: "#bdc466", borderRadius: "20px" }}
                >
                  <tr>
                    <th style={{ borderTopLeftRadius: "10px" }}>Imagem</th>
                    <th>nome</th>
                    <th>Qtd</th>
                    <th>Valor Unitario</th>
                    <th style={{ borderTopRightRadius: "10px" }}>
                      Valor Total
                    </th>
                  </tr>
                  {VerAnimais.map((animais, index) => (
                    <tr>
                      <td>
                        <div
                          className="img_cart_img_final"
                          style={{
                            // backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemLote/${animais.artigo.lote.imgUrl})`,
                            backgroundImage: `url(${animais.artigo.lote.imgUrl})`,
                          }}
                        ></div>
                      </td>
                      <td>{animais.artigo.lote.especie.especieAnimal}</td>
                      <td>{animais.quantidade}</td>
                      <td>
                        {animais.artigo.preco.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        kz
                      </td>
                      <td>
                        {animais.total.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        kz
                      </td>
                    </tr>
                  ))}

                  {VerProdutosDoCarrinho.map((produto, index) => (
                    <tr>
                      <td>
                        <div
                          className="img_cart_img_final"
                          style={{
                            // backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${produto.artigoProduto.produto.imgUrl})`,
                            backgroundImage: `url(${produto.artigoProduto.produto.imgUrl})`,
                          }}
                        ></div>
                      </td>
                      <td>{produto.artigoProduto.produto.nome}</td>
                      <td>{produto.quantidade}</td>
                      <td>
                        {produto.artigoProduto.preco.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        kz
                      </td>
                      <td>
                        {produto.total.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        kz
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3}></td>
                    <td
                      className="g_total"
                      style={{ textAlign: "right", borderRadius: "10px 0 0 0" }}
                    >
                      Total do carrinho:{" "}
                    </td>
                    <td
                      className="g_total"
                      style={{ borderRadius: "0 0 10px 0" }}
                    >
                      {(totalCarrinho).toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })}{" "}
                      {/* {(valor + valorProduto).toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })}{" "} */}
                      Kz
                    </td>
                  </tr>
                </table>
              </div>
              <div className="info-finalizar-compra">
                <div
                  className="importante importante_compra"
                  style={{ marginTop: "20px", width: "70%" }}
                >
                  <div className="i_cima">
                    <div className="text-imp" style={{ color: "#fff" }}>
                      Importante
                    </div>
                  </div>
                  <div className="i_meio">
                    <p>
                      Deseja solicitar um serviço de transporte para os animais
                      no seu carrinho?
                    </p>
                  </div>
                  <div className="s_bot is-flex">
                    <div></div>
                    <div>
                      <Link to="/destino">
                        <button
                          className="s_btn"
                          style={{ backgroundColor: "#B46739", color: "#fff" }}
                        >
                          Solicitar Transporte
                        </button>
                      </Link>
                      <Link to="/pagamentos">
                        <button className="s_btn">
                          Coninuar sem trnasporte
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1>Adicione Items ao Seu carrinho </h1>
        </div>
      )}
    </>
  );
}
export default Finalizar;
