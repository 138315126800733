import { Link } from "react-router-dom";

const CardCooperador = (
  props,
  { totalCount, pageSize, siblingCount = 1, currentPage }
) => {
  return (
    <>
      <div className="am_p">
        <div
          // className="img_do_cooperador"
          className="img_do_cooperado"
          id="mg"
          style={{ backgroundImage: `url(${props.imgUrl})` }}
        ></div>
        <div className="mi" id="card-info-cooperador">
          <h2
  
            style={{ borderBottom: "1px solid #B46739", paddingBottom: "10px" }}
          >
            {props.nome} {props.sobrenome}
          </h2>
          <table className="info-all-cooperators">
            {/* <tr>
                  <td>Endereco:</td>c
                  <td>{props.endereco}</td>
                </tr> */}
            <tr>
              {/* <td colSpan={2}>{props.email}</td> */}
            </tr>
          </table>
          {props.is_verify ? (
            <p className="mb-5"> Verificado</p>
          ) : (
            <p className="mb-5"></p>
          )}
          <Link
            to={`/sobre_cooperador/${props.codigo}`}
            className="button botao_para_saber_mais is-link"
            style={{ background: "#9ba236", margin: "0 auto" }}
          >
            Ver Perfil
          </Link>
        </div>
      </div>
    </>
  );
};
export default CardCooperador;
