import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cart from "../../../components/vendas/Cart";
import CardVendaService from "../../../services/cardVenda";
import Swal from "sweetalert2";
import VendaService from "../../../services/vendas";
import LanceService from "../../../services/lance";
import Menu from "../../../components/menu/NavBar";
import { AuthContext } from "../../../contexts/Auth";

const SobreArtigo = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const logado = user?.codigo;

  //Botao Salvar
  const salvarCarrinho = async (id, preco) => {
    const data = {
      artigo: {
        codigo: id,
      },
      visitante: {
        codigo: logado,
      },
      total: preco,
    };
    // const { artigo, visitante } = data;
    await CardVendaService.createCarrinho(data)
      .then((response) => {
        if (response.data === "carrinho_exist") {
          Swal.fire({
            width: 300,
            icon: "warning",
            text: "Este Lote já foi adicionado, verifique o seu carrinho",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (response.data === "saved") {
          Swal.fire({
            width: 300,
            icon: "success",
            text: "Adicionado ao carrinho ",
            showConfirmButton: false,
            timer: 1500,
          });
          //Cart.VerOsAnimaisCarrinho()
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          width: 300,
          icon: "warning",
          text: "É necessário autenticar para fazer compras " + id,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/login");
      });
  };

  //
  const [VerAnimalLote, setVerAnimalLote] = useState([]);
  const [VerLoteRapido, setVerLoteRapido] = useState([]);
  const [VerExistente, setVerExistente] = useState([]);
  const [VerFazenda, setVerFazenda] = useState([]);
  const [VerPreco, setVerPreco] = useState([]);
  const [VerArtigoCodigo, setVerArtigoCodigo] = useState([]);

  const { codigo } = useParams();

  //
  useEffect(() => {
    loadOurService();
  }, []);
  //

  const VerAnimaisDoLoteRapido = async (codigo) => {
    await LanceService.getLoteRapido(codigo).then((response) => {
      setVerLoteRapido(response.data[0]);
      console.log(response.data[0]);
    });
  };

  const loadOurService = () => {
    if (codigo) {
      VendaService.getAnivalVendaCodigo(codigo).then((response) => {
        setVerAnimalLote(response.data.lote);
        setVerPreco(response.data.preco);
        if (response.data.lote.geral) {
          VerAnimaisDoLoteRapido(response.data.lote.codigo);
        } else if (response.data.lote.existente) {
          VerExistente(response.data.lote.codigo);
        }
      });
    }
  };
  //
  return (
    <>
      <Menu />
      <Cart />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Detalhes de Venda</h1>
        </div>
      </section>
      <section className="corpo f_corpo">
        <div className="fotografiaAnimal">
          <img
            className="img-animal"
            // src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemLote/${VerAnimalLote.imgUrl}`}
            src={`${VerAnimalLote.imgUrl}`}
            alt=""
          />
        </div>
        <div className="conteudo" style={{ border: "4px solid #686d25" }}>
          <divn className="cima_preco_valor">
            <div></div>
            <div className="valor-preco-venda">
              <p>
                Preço do Lote: <strong>{VerPreco} Kz</strong>
              </p>
            </div>
            <div></div>
          </divn>
          <div className="legenda_do_lance">
            <div>
              <h2 id="detalhes-animal" style={{ textAlign: "center" }}>
                {VerAnimalLote.especieAnimal}
              </h2>
              <div
                className="legenda_do_lance"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "auto",
                    justifyContent: "space-around",
                  }}
                >
                  <div>
                    <p>
                      Idade Minima:{" "}
                      <strong>{VerLoteRapido.idademin} meses</strong>
                    </p>
                    <p>
                      Idade Maximo:{" "}
                      <strong>{VerLoteRapido.idademax} meses</strong>
                    </p>
                    <p>
                      Média de Idade:{" "}
                      <strong>
                        {(VerLoteRapido.idademax + VerLoteRapido.idademin) / 2}{" "}
                        meses
                      </strong>
                    </p>
                    <p>
                      {VerLoteRapido.numerosexoF === 1 ? (
                        <strong>{VerLoteRapido.numerosexoF} Femea</strong>
                      ) : (
                        <strong>{VerLoteRapido.numerosexoF} Femeas</strong>
                      )}
                    </p>
                    <p></p>
                  </div>
                  <div>
                    <p>
                      Peso Minimo: <strong>{VerLoteRapido.pesomin}Kg</strong>
                    </p>
                    <p>
                      Peso Maximo: <strong>{VerLoteRapido.pesomaxi}Kg</strong>
                    </p>
                    <p>
                      Media de Pesos:{" "}
                      <strong>
                        {(VerLoteRapido.pesomaxi + VerLoteRapido.pesomin) / 2}Kg
                      </strong>
                    </p>
                    <p>
                      {VerLoteRapido.numerosexoM === 1 ? (
                        <strong>{VerLoteRapido.numerosexoM} Macho</strong>
                      ) : (
                        <strong>{VerLoteRapido.numerosexoM} Machos</strong>
                      )}
                    </p>
                  </div>
                </div>
                <div className="doisBotVerLote">
                  <div className="cart-preco-venda">
                    {/* <button>Adicionar ao carrinho</button> */}
                    <a
                      onClick={() => salvarCarrinho(VerArtigoCodigo, VerPreco)}
                      className="button is-link venda_botao"
                      // style={{ background: "#B46739", width: "60%" }}
                      style={{ background: "#a7ac69", border: "3px solid #686d25" }}
                    >
                      Adicionar ao carrinho
                    </a>
                    
                    {/* <a
                      className="btn_cart_btn is-link"
                      onClick={() => salvarCarrinho(props.codigo, props.preco)}
                      style={{ margin: "20px auto 20px auto" }}
                    >
                      <img src={icon} alt="" width="50px" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="descricao">
        <div className="info_animal">
          <h1>{VerAnimalLote.nome}</h1>
          <p>{VerAnimalLote.descricao}</p>
        </div>
      </section>
    </>
  );
};

export default SobreArtigo;
