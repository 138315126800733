import api from "./api"; 

export const createSession = async (data) => {
  try {
    return await api.post("authlogin", data);
  } catch (error) {
    console.log(error.message);
  }
};

export const createSessionF = async (data) => {
  try {
    return await api.post("authCooperador", data);
  } catch (error) {
    console.log(error.message);
  }
};



export const getUsers = async () => {
  try {
    return await api.get("/Visitante");
  } catch (error) {
    console.log(error.message);
  }
};
export const encontrarUser = async (params) => {
  try {
    return await api.get(`visitanteEmail/${params}`);
  } catch (error) {
    console.log(error.message);
  }
};