import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import VendaService from "../../services/vendas";
import VisitanteService from "../../services/visitante";
import PagamentoService from "../../services/pagamentos";
import EntregaService from "../../services/entregas";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../../components/menu/NavBar";
import { AuthContext } from "../../contexts/Auth";

function Cart() {
  const { user } = useContext(AuthContext);
  // const logado = 39;
  const logado = user?.codigo;

  useEffect(() => {
    VerOCarrinhoGlobal();
    VerEntregaDoUser();
    VerOtotalProduto();
    VerOtotalLote();
  }, [logado]);

  // Sobre o botão
  const navigate = useNavigate();
  const [VerUserEntrega, setVerUserEntrega] = useState(0);
  const [VerInfoUserEntrega, setVerInfoUserEntrega] = useState(0);
  const VerEntregaDoUser = async () => {
    await EntregaService.VerUserExisteTransporte(logado).then((response) => {
      setVerInfoUserEntrega(response.data[0])
      setVerUserEntrega(response.data[0].totalTransporte);
    });
  };
  const [VerCarrinhoGlobal, setVerCarrinhoGlobal] = useState();
  const VerOCarrinhoGlobal = async () => {
    await VendaService.getCarrinhoGlobal(logado).then((response) => {
      if (response.data.length === 0) {
        navigate("/venda");
      } else {
        setVerCarrinhoGlobal(response.data[0].codigo);
        console.log(response.data[0].codigo);
      }
      console.log(response.data);
    });
  };

  // Ver o Total do lote
  const [valor, setValor] = useState(0);
  const VerOtotalLote = async () => {
    await VendaService.GetvalorCart(logado).then((response) => {
      setValor(response.data);
    });
  };

  // Ver o Total do produto
  const [valorProduto, setValorProduto] = useState(0);
  const VerOtotalProduto = async () => {
    await VendaService.GetTotalValorProduto(logado).then((response) => {
      setValorProduto(response.data);
    });
  };

  //
  const [formationNormal] = useState({
    pdf: "",
    carrinhoGlobal: {
      codigo: VerCarrinhoGlobal,
    },
  });
  const [pdf, setpdf] = useState("");

  const FecharCarrinhoGlobal = async () => {
    const data = {
      status: 1,
    };
    await PagamentoService.setCarrinhoState(VerCarrinhoGlobal, data).then(
      () => {
        if (VerUserEntrega) {
          const estado = {
            state: 1,
          };
          PagamentoService.setEntregaState(VerInfoUserEntrega.codigo, estado).then(() => {
            Enviado();
          })
            .catch(() => {
              console.log('ocorreu um erro ao finalizar entrega')
            })
        } else {
          Enviado();
        }
      }
    );
  };

  const Enviado = () => {
    Swal.fire({
      title: "Comprovativo enviado com sucesso",
      text: "Recebemos o seu comprovativo",
      icon: "success",
      confirmButtonColor: "#686d25",
    });
    navigate("/confirmacao");
  };

  //Botao Salvar
  const saveUpdateFormationNormal = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("carrinhoGlobal", VerCarrinhoGlobal);

    //
    if (formData) {
      await PagamentoService.sendComprovativo(formData)
        .then((response) => {
          if (response.data === "saved") {
            const closeCart = {
              status: 1,
            };
            const closeEntrega = {
              state: 1,
            };
            FecharCarrinhoGlobal();
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro de conexão",
            text: "Verifique a sua internet e tente novamente" + error,
            icon: "error",
            confirmButtonColor: "#686d25",
          });
        });
    }
  };

  // ----------------------------------------------

  // Ver o comprovativo
  const VerComprovativo = (e) => {
    if (e.target.files.length > 0) {
      setpdf(e.target.files[0]);
      //var caminho = URL.createObjectURL(e.target.files[0]);
      var nome = e.target.files[0];
      var preview = document.getElementById("ver_pdf_label");
      preview.innerText = nome.name;
    }
  };

  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Pagamentos</h1>
        </div>
      </section>

      {/* {
        VerAnimais.length === 0 ?
          <div>
            <h1>Adicione Items ao Seu carrinho </h1>
          </div>
          : */}
      <div>
        <div className="doisFinal">
          <div className="pagamento_final_compra">
            <div>
              <div className="paga_final_1">
                <h1 style={{ color: "#fff" }}>Informações do Cliente</h1>
                <table>
                  <tr>
                    <td>Nome: </td>
                    <td>{user?.nome}</td>
                  </tr>
                  <tr>
                    <td>Email: </td>
                    <td>{user?.email}</td>
                  </tr>
                  {/* <tr>
                    <td>Telefone: </td>
                    <td>{user?.telefone}</td>
                  </tr> */}
                </table>
              </div>
            </div>

            <div className="paga_final">
              <h1 style={{ color: "#fff" }}>Escolha a forma de pagamento</h1>
              <div className="banco_paga">
                <Link to="">
                  <button className="btn_banco btn_angolano"></button>
                </Link>

                <button className="btn_banco btn_paypal"></button>
                <button className="btn_banco btn_visa"></button>
                <button className="btn_banco btn_mastercade"></button>
              </div>
              <div>
                <h2 className="bancoh2">Banco Angolano</h2>
                <h3 className="bancoh3">Informações da Conta à transferir</h3>
                <table>
                  <tr>
                    <td>Nome da Conta: </td>
                    <td>
                      Cooperativa CWAM
                    </td>
                  </tr>
                  <tr>
                    <td>IBAN: </td>
                    <td>0040.0000.4598.135.456.5</td>
                  </tr>
                  <tr>
                    <td>Banco: </td>
                    <td>BAI</td>
                  </tr>
                  <tr>
                    <td>Valor do carrinho: </td>
                    <td>
                      {(valorProduto + valor).toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })}
                      Kz
                    </td>
                  </tr>
                  {VerUserEntrega === "" ? (
                    ""
                  ) : (
                    <tr>
                      <td>Valor do transporte: </td>
                      <td>
                        {VerUserEntrega.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}
                        Kz
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>Valor à Transferir: </td>
                    {VerUserEntrega === "" ? (
                      <td>
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {(valor + valorProduto).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                          Kz
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {(
                            VerUserEntrega +
                            valorProduto +
                            valor
                          ).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                          Kz
                        </span>
                      </td>
                    )}
                  </tr>
                </table>
                <div className="fim_letra_pagamentos">
                  <div>
                    <h2>
                      Depois de feita a transferência, por favor carregue o seu
                      comprovativo aqui...
                    </h2>
                    <form
                      encType="multipart/form-data"
                      onSubmit={(e) => saveUpdateFormationNormal(e)}
                    >
                      <p
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <label for="arquivo" id="ver_pdf_label">
                          <div>Carregar o comprovativo</div>
                        </label>
                      </p>
                      {/* <div className="flex_nome_paga"><div id="id_do_file"></div><div className="img_pdf_enviar"></div></div> */}
                      <p>
                        <input
                          type="file"
                          id="arquivo"
                          onChange={(e) => VerComprovativo(e)}
                          name="pdf"
                        />
                      </p>
                      <p>
                        <button
                          type="submit"
                          className="enviar_button_pagamentos"
                          style={{
                            backgroundColor: "#686d25",
                            marginTop: "20px",
                          }}
                        >
                          Enviar Comprovativo
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  );
}
export default Cart;
