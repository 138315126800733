import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../components/menu/NavBar";

export function ForgetPasswordPin () {
  const navigate = useNavigate();

  const enviar_email = (e) => {
    Swal.fire({
      title: "Email enviado com sucesso",
      text: "Enviamos a nova senha para o seu email",
      icon: "success",
      confirmButtonColor: "#686d25",
    });

    navigate("/forgetPin");
  }

  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">
            Insira o código de 6 digitos
          </h1>
        </div>
      </section>
      <section id="section" className="verde sobre">
        <div className="login_texto">
          <div className="p_s is-flex">
            <form onSubmit={enviar_email}>
              <div className="cadastro">
                <div className="e_cima is-flex justify-space-between">
                  <p style={{ textAlign: "center", margin: "10px" }}>
                    Insira o seu email para enviarmos a sua nova palavra passe
                  </p>
                </div>
                <div className="s_meio">
                  <p className="s_pp is-flex">
                    <input
                      type="email"
                      placeholder="Email"
                      minLength={2}
                      maxLength={30}
                      required
                    />
                  </p>
                </div>
                <div className="s_bot is-flex" style={{ marginTop: "20px" }}>
                  <div></div>
                  <div>
                    <Link to="/login">
                      <button className="s_btn" style={{ marginRight: "30px" }}>
                        Cancelar
                      </button>
                    </Link>
                    <button className="s_btn" type="submit">
                      Enviar Email
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgetPasswordPin;
