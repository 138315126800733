import React from "react";
import Menu from "../../components/menu/NavBar";

const VerificarEmail = () => {
  //
  return (
    <div>
    <>
      <Menu />
      <section id="section" className="verde sobre borda_img_log ">
        <div className="login_texto">
          <div className="p_s is-flex">
            {/* <div className="login">

     
            </div> */}
            <div className="importante">
              <div className="i_cima">
                <div className="text-imp">Olá, seja bem vindo a CWAM</div>
              </div>
              <div className="i_meio">
                <p>
                Acabamos de enviá-lo no seu e-mail, um link de activação, para
                certificar os seus dados.
                </p>
                <p>
                Basta clicar na opção “Verificar email”. De seguida pode voltar
                efectuar o seu login e desfrutar o melhor que podemos
                oferecê-lo.
                </p>
              </div> 
      
            </div>
          </div>
        </div>
      </section>
    </>
    </div>
  );
};

export default VerificarEmail;