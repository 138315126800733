import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import FazendaService from "../../services/fazendas";
import Menu from "../../components/menu/NavBar";
import './styles.css'

const Fazendas = () => {
  const [VerFazendass, setVerFazendass] = useState([]);

  const [itensPorPage, setitensPorPage ] = useState([8])
  const [correntPage, setcorrentPage ] = useState([0])

  const pages = Math.ceil(VerFazendass.length/ itensPorPage)
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerFazendass.slice(startItens, endItens)

  // Pegar os animais
  const VerAsFazendas = async () => {
    FazendaService.getFazendas()
      .then((response) => {
        setVerFazendass(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    VerAsFazendas();
  }, []);

  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Fazendas</h1>
        </div>
      </section>
      <section className="animais">
        <div className="m-2 ani_p">
          {correntItens.map((fazenda) => (
            <div className="am_p" key={fazenda.codigo}>
              <div
                className="img_do_cooperador"
                style={{
                  backgroundImage: `url(${fazenda.logo})`,
                }}
              ></div>
              <div className="mi">
                <h2
                  // className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {fazenda.nome}
                </h2>
                <table>
                  <tr>
                    <td>Província:</td>
                    <td>{fazenda.municipio.provincia.nome}</td>
                  </tr>
                  {/* <tr>
                    <td>Cooperador:</td>
                    <td>{fazenda.cooperador.nome}</td>
                  </tr> */}
                </table>
                <Link
                  to={`/sobre_fazenda/${fazenda.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Entrar na Fazenda
                </Link>
              </div>
            </div>
          ))}
        </div>

        </section>
        <section className="sect">
        {Array.from(Array(pages), (VerFazendass, index)=> {
            return(
              <button className="pagB" value={index} onClick={(e) => setcorrentPage(Number(e.target.value))}>{index + 1}</button>
            )
        }
        )}

      </section>
    </>
  );
};

export default Fazendas;
