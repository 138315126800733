import api from "./api";

export const getMinhasComprass = async () => {
  try {
    return await api.get("carrinhoGlobal");
  } catch (error) {
    console.log(error.message);
  }
};
export const createMinhasCompras = async (data) => {
  try {
    return await api.post("criar_MinhasCompras", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getMinhasComprasCodigo = async (codigo) => {
  try {
    return await api.get(`carrinhoGlobal/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
/*Lista das Compras*/
export const getVerMinhasComprasCodigo = async (codigo) => {
    try {
      return await api.get(`minhaCompraCarrinhoGlobal/${codigo}`);
    } catch (error) {
      console.log(error.message);
    }
  };
/*Lista das Compras*/
export const getTotalDeCompras = async (codigo) => {
    try {
      return await api.get(`carrinhoFechadoVisitante/${codigo}`);
    } catch (error) {
      console.log(error.message);
    }
  };
/**/
export const updateMinhasCompras = async (data) => {
  try {
    return await api.put("MinhasCompras", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeMinhasCompras = async (codigo) => {
  try {
    return await api.delete(`MinhasCompras/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemMinhasCompras/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const ExibirComprovativo = async (codigo) => {
  try {
    return await api.get(`comprovativoCarrinhoGlobal/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getItemsComprasPerfil = async (global, visitante) => {
  try {
    return await api.get(`minhasCarrinhoVerificar/${global}/${visitante}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getItemsComprasPerfilProdutos = async (global, visitante) => {
  try {
    return await api.get(`minhasCarrinhoProdutoVerificar/${global}/${visitante}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteGeralCompras = async (codigo) => {
  try {
    return await api.get(`loteRapidoByLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getRacas = async (codigo) => {
  try {
    return await api.get(`RacasByLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
