import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardVendaService from "../../services/cardVenda";
import VendaService from "../../services/vendas";
import Swal from "sweetalert2";
import { AuthContext } from "../../contexts/Auth";

const icon = require("../imgs/icons/cart_carrinho.png");
const olho = require("../imgs/icons/olhos.png");

const CardVenda = (props) => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const logado = user?.codigo;

  //Botao Salvar
  const salvarCarrinho = async (id, preco) => {
    const usuario = {
      visitante: {
        codigo: logado,
      },
    };
    await VendaService.createCarrinhoGlobal(usuario).then((response) => {
      const codGlobal = response.data.codigo;
      VendaService.VerificarExisteAnimal(id, codGlobal).then((response) => {
        if (response.data.length === 0) {
          console.log("Pode cadastrar");
          const data = {
            artigo: {
              codigo: id,
            },
            total: preco,
            carrinhoGlobal: {
              codigo: codGlobal,
            },
          };
          VendaService.createCarrinhoAnimal(data)
         .then((response) => {
           if (response.data === "carrinho_exist") {
             Swal.fire({
               width: 300,
               icon: "warning",
               text: "Este Lote já foi adicionado, verifique o seu carrinho",
               showConfirmButton: false,
               timer: 1500,
             });
           }
           if (response.data === "saved") {
             Swal.fire({
               width: 300,
               icon: "success",
               text: "Adicionado ao carrinho ",
               showConfirmButton: false,
               timer: 1500,
             });
            //  Cart.VerOsAnimaisCarrinho()
           }
         })
         .catch((error) => {
           Swal.fire({
             position: "top-end",
             width: 300,
             icon: "warning",
             text: "É necessário autenticar para fazer compras" + id,
             showConfirmButton: false,
             timer: 1500,
           });
            // navigate("/login");
         });

        } else {
          Swal.fire({
            width: 300,
            icon: "warning",
            text: "Este Lote já foi adicionado, verifique o seu carrinho",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    });

    // const { artigo, visitante } = data;
  };

  return (
    <>
      <div className="am_p">
        {/* */}
        <div
          className="img_do_animal"
          style={{
            backgroundImage: `url(${props.imgUrl})`,
            borderRadius: "20px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div></div>
          <button className="preco_don_botao">
            {props.preco.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            })}
            Kz
          </button>
        </div>

        <div className="mi venda_mi" style={{ zIndex: "1" }}>
          <h2
            className="title has-text-centered is-4 m-2"
            style={{ borderBottom: "1px solid #B46739", paddingBottom: "10px" }}
          >
            {props.quantidade <= 1 ? (
              <strong>
                {props.quantidade} animal da especie {props.especie}
              </strong>
            ) : (
              <strong>
                {props.quantidade} animais da especie {props.especie}
              </strong>
            )}
          </h2>
          {/* */}
          <p className="dois_btn_cart">
            <Link
              to={`/sobre_compra/${props.codigo}`}
              className="btn_cart_btn is-link"
              style={{ margin: "20px auto 20px auto" }}
            >
              <img src={olho} alt="" width="50px" />
            </Link>
            <a
              className="btn_cart_btn is-link"
              onClick={() => salvarCarrinho(props.codigo, props.preco)}
              style={{ margin: "20px auto 20px auto" }}
            >
              <img src={icon} alt="" width="50px" />
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
export default CardVenda;
