import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useState } from "react";
import CadastroCooperadorService from "../services/cadastroCooperador";
import Menu from "./menu/NavBar";
import * as yup from "yup";

export function Cadastro() {
  //
  const [cadastro, setCadastro] = useState({
    nome: "",
    sobrenome: "",
    email: "",
    telefone: "",
    endereco: "",
    senha: "",
  });

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  const { nome, sobrenome, email, telefone, senha, endereco } = cadastro;

  // Buscar por ID
  //
  const onInputChange = (e) => {
    setCadastro({ ...cadastro, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdatecadastro = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("sobrenome", sobrenome);
    formData.append("email", email);
    formData.append("telefone", telefone);
    formData.append("senha", senha);
    formData.append("endereco", endereco);

    //
    await CadastroCooperadorService.create(formData)
      .then((response) => {
        if (response.data === "saved") {
          Swal.fire({
            title: "Cadastrado com sucesso",
            text: "Enviamos email para o seu endereço, para que tenhas acesso ao sistema",
            icon: "success",
            confirmButtonColor: "#686d25",
          });
        } else if (response.data === "email_exists") {
          Swal.fire({
            title: "Email invalido",
            text: "Este email já foi cadastrado, Tente outro",
            icon: "warning",
            confirmButtonColor: "#686d25",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Erro de conexão",
          text: "Verifique a sua internet e tente novamente",
          icon: "error",
          confirmButtonColor: "#686d25",
        });
      });
  };

    //VAlidacao
    async function validate() {
      let schema = yup.object().shape({
        email: yup
          .string("O campo email obrigatório!")
          .required("O campo email obrigatório!")
          .email("e-mail inválido!"),
        senha: yup
          .string("O campo senha obrigatório!")
          .required("O campo senha obrigatório!")
          .min(6, "A senha deve ter no mínimo 6 caracteres!"),
      });
      try {
        await schema.validate(cadastro);
        return true;
      } catch (err) {
        setStatus({
          type: "error",
          mensagem: err.errors,
        });
        return false;
      }
    }
  
    //
  return (
    <>
      <Menu />
      <section
        id="section"
        className="verde sobre"
        style={{ flexDirection: "column" }}
      >
        <h1
          className="title has-text-centeder"
          style={{ color: "#fff", marginTop: "30px" }}
        >
          Cadastre-se como cooperador da CWAM
        </h1>
        <div className="login_texto">
          <div className="p_s is-flex">
            <form
              encType="multipart/form-data"
              onSubmit={(e) => saveUpdatecadastro(e)}
            >
              <div className="cadastro">
                <div className="c_cima is-flex justify-space-between">
                  <div></div>
                </div>
                <div className="s_meio">
                  <p className="s_pp is-flex">
                    <input
                      type="text"
                      placeholder="Nome"
                      name="nome"
                      value={nome}
                      onChange={(e) => onInputChange(e)}
                      minLength={3}
                      maxLength={30}
                      required
                    />
                  </p>

                  <p className="s_pp is-flex">
                    <input
                      type="text"
                      placeholder="Sobrenome"
                      name="sobrenome"
                      value={sobrenome}
                      onChange={(e) => onInputChange(e)}
                      minLength={3}
                      maxLength={30}
                      required
                    />
                  </p>

                  <p className="s_pp is-flex">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => onInputChange(e)}
                      minLength={4}
                      maxLength={60}
                      required
                    />
                  </p>
                  <p className="s_pp is-flex">
                    <input
                      type="text"
                      placeholder="Telefone"
                      name="telefone"
                      value={telefone}
                      onChange={(e) => onInputChange(e)}
                      minLength={9}
                      maxLength={12}
                      required
                    />
                  </p>
                  <p className="s_pp is-flex">
                    <input
                      type="text"
                      placeholder="Endereco"
                      name="endereco"
                      value={endereco}
                      onChange={(e) => onInputChange(e)}
                      minLength={5}
                      maxLength={80}
                      required
                    />
                  </p>
                  <p className="s_pp is-flex">
                    <input
                      type="password"
                      placeholder="Senha"
                      name="senha"
                      value={senha}
                      onChange={(e) => onInputChange(e)}
                      minLength={6}
                      maxLength={30}
                      required
                    />
                  </p>
                  <p className="s_pp is-flex">
                    <input
                      type="password"
                      placeholder="Confirmar Senha"
                      minLength={6}
                      maxLength={30}
                      required
                    />
                  </p>
                </div>
                <div className="s_bot is-flex">
                  <div></div>
                  <div>
                    <Link to="/login">
                      <button className="s_btn" style={{ marginRight: "30px" }}>
                        Cancelar
                      </button>
                    </Link>
                    <button className="s_btn" type="submit">
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
