import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import LeilaoService from "../../services/leilao";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";

// import { fdazenda } from "../../services/fazendas";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "animate.css";

const Inscrever = (props) => {
  //
  const { user } = useContext(AuthContext);
  const { codigo } = useParams();
  //
  const logado = user?.codigo;
  //States
  const [VerLeilao, setVerLeilao] = useState([]);
  const [VerFazendas, setVerFazendas] = useState([]);
  //
  const [VerificarParticipante, setVerificarParticipante] = useState(false);

  const [VerificarParticipanteAprovado, setVerificarParticipanteAprovado] =
    useState(false);
  const [VerificarParticipanteReprovado, setVerificarParticipanteReprovado] =
    useState(false);
  const [VerificarParticipantePendente, setVerificarParticipantePendente] =
    useState(false);
  const [message, setmessage] = useState("");
  const [message2, setmessage2] = useState("");

  const [btnState, setBtnState] = useState(false);
  const [alterar, setAlterar] = useState(true);
  //
  function ver_modal_cart() {
    setBtnState((btnState) => !btnState);
    VerificarPendenteParticipante();
  }
  function fechar_modal_cart() {
    setBtnState((btnState) => false);
    VerificarPendenteParticipante();
  }

  let toggleClassCheck = btnState ? "active_cart_1" : "";
  let toggleClassFilho = btnState
    ? "produtos_da_compra_cart_filho_animation_1"
    : "";
  //Listar Fazenda
  const pegarfazenda = async () => {
    await LeilaoService.getLeilaoCodigo(codigo).then((response) => {
      console.log(response.data);
      console.log(codigo);
      setVerFazendas(response.data.fazenda);
    });
  };
  const getLeilaoCodigomandar = async () => {
    await LeilaoService.getLeilaoCodigo(codigo).then((response) => {
      console.log(response.data);
      console.log(codigo);
      setVerLeilao(response.data);
      VerificarPendenteParticipante();
    });
  };
  const termo = async () => {
    Swal.fire({
      title: "Termos e Politicas Para Leilões",
      text:
        "Ao Participar de um leilão, para questões" +
        " de segurança o seu nome permanecerá anónimo, apénas utilizaremos o seu id de Usuario. Ainda assim, para questões de confiabilidade, será necessário esperar a aprovação de participante pela equipe técnica da CWAM",
      confirmButtonColor: "#686d25",
      confirmButtonLabel: "Texto",
    });
  };
  //
  useEffect(() => {
    pegarfazenda();
    getLeilaoCodigomandar();
    VerificarPendenteParticipante();
  }, []);

  //METODO SALVAR
  //
  const [comprovativoParticipante] = useState({
    comprovativo: "",
    leilao: {
      codigo: codigo,
    },
    visitante: {
      codigo: logado,
    },
  });
  const [comprovativo, setComprovativo] = useState("");

  //Botao Salvar
  const saveUpdateComprovativoParticipante = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("comprovativo", comprovativo);
    formData.append("leilao", codigo);
    formData.append("visitante", logado);

    //
    if (formData) {
      await LeilaoService.createPegarPedente(formData)
        .then((response) => {
          if (response.data === "saved") {
            Swal.fire({
              title: "Comprovativo enviado com sucesso",
              text: "Recebemos o seu comprovativo",
              icon: "success",
              confirmButtonColor: "#686d25",
            });
          }
          if (response.data === "visitante_existente") {
            Swal.fire({
              title: "Actualize sua Página",
              text: "Recebemos o seu comprovativo, actualize sua página",
              icon: "warning",
              confirmButtonColor: "#686d25",
            });
          }
          setAlterar(false);
          VerificarPendenteParticipante();
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro de conexão",
            text: "Verifique a sua internet e tente novamente" + error,
            icon: "error",
            confirmButtonColor: "#686d25",
          });
          VerificarPendenteParticipante();
        });
    }
  };

  // ----------------------------------------------

  // Ver o comprovativo
  const VerComprovativo = (e) => {
    if (e.target.files.length > 0) {
      setComprovativo(e.target.files[0]);
      var nome = e.target.files[0];
      var preview = document.getElementById("ver_pdf_label");
      preview.innerText = nome.name;
    }
  };

  //VERIFICAR USUARIO PEDENTE
  const VerificarPendenteParticipante = async () => {
    await LeilaoService.getVerificarParticipanteVisitanteExistente(
      logado,
      codigo
    )
      .then((response) => {
        console.log(logado, codigo);

        if (response.data === "visitante_existente") {
          setmessage(
            "Aguarde a sua aprovação no seu emal... " + `${user?.email}`
          );
          console.log("Existente");
          setVerificarParticipantePendente(true);
        }
        if (response.data === "APROVADO") {
          setmessage2("none");
          setmessage("Inscrição Aprovada");
          console.log("Aprovado");
          props.estadoIns("Aprovado");
          setVerificarParticipanteAprovado(true);
        }
        if (response.data === "REPROVADO") {
          setmessage("Inscrição Reprovada");
          setmessage2("none");

          console.log("Reprovado");
          props.estadoIns("Reprovado");

          setVerificarParticipanteReprovado(true);
        }
        if (response.data === "NoExist") {
          console.log("Nao Existe");

          setVerificarParticipante(true);
          props.estadoIns("noExist");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //

  return (
    <>
      <div
        onClick={ver_modal_cart}
        id="ver_compras_cart_1"
        className="leilaoItemBotao titleItem"
      >
        {VerificarParticipante === true ? (
          <span
            style={{
              backgroundColor: "#B46739",
              borderRadius: "10px",
              padding: "8px",
              outline: "none",
              border: "none",
            }}
          >
            Inscrever-se{" "}
          </span>
        ) : (
          <div className={`btn-enviar-comprovativo ${message2}`}>
            <span
              style={{ backgroundColor: "#686d25" }}
              className="enviar_pagamentos"
            >
              Estado da inscricão
            </span>
          </div>
        )}
        {VerificarParticipanteAprovado === false ? (
          ""
        ) : (
          <div
            style={{ backgroundColor: "#686d25" }}
            className="btn-enviar-comprovativo"
          >
            <button
              type="submit"
              style={{ backgroundColor: "#686d25" }}
              className="enviar_pagamentos"
            >
              {message}
            </button>
          </div>
        )}

        {VerificarParticipanteReprovado === false ? (
          ""
        ) : (
          <div className="btn-enviar-comprovativo">
            <button
              type="submit"
              style={{ backgroundColor: "#ff4040" }}
              className="enviar_pagamentos"
            >
              Reprovado
            </button>
          </div>
        )}
      </div>

      <div className={`produtos_da_compra_cart_1 ${toggleClassCheck}`}>
        <div
          className={`produtos_da_compra_cart_filho_1 animate__animated animate__fadeInDownBig ${toggleClassFilho}`}
        >
          {/*  */}
          <div id="bg_img_Leilao">
            <h1 className="txt-leilao-inscricao">Leilão Online</h1>
            <p> {VerFazendas.nome} </p>
            <span></span>
          </div>
          {/* SAIR */}
          <a onClick={fechar_modal_cart} className="btn-sair">
            Sair
          </a>
          {/*  */}

          <div className="dizeres-posicao">
            <p>
              Participe do leilão da {VerFazendas.nome} a decorrer no dia{" "}
              {VerLeilao.dataLeilao}, submetendo a sua inscricão, enviando o
              comprovativo do pagamento para a caução de modo a ter acesso ao
              leilão online.
            </p>
            <p style={{ color: "#fff", fontSize: "1.2rem" }}>
              Valor da Caução:{" "}
              <strong style={{ color: "#B46739" }}> {VerLeilao.caucao} Kz</strong>
            </p>
            <p>
              Para poder fazer a sua inscricão e ter acesso ao leilão online,
              por favor faça o pagamento da caução e carregue o comprovativo de
              pagamento. Em seguida clique finalizar.
            </p>
          </div>
          {/*  */}
          <div className="btnInscreverFooter">
            {VerificarParticipante === true ? (
              <div>
                {alterar ? (
                  <form
                    className="tentativa"
                    encType="multipart/form-data"
                    onSubmit={(e) => saveUpdateComprovativoParticipante(e)}
                  >
                    <div className="btn-carregar">
                      <label for="arquivo" id="ver_pdf_label">
                        Carregar comprovativo
                      </label>
                    </div>

                    <p>
                      {" "}
                      <input
                        type="file"
                        id="arquivo"
                        accept="application/pdf"
                        onChange={(e) => VerComprovativo(e)}
                        name="comprovativo"
                      />
                    </p>

                    <div className="btn-enviar-comprovativo">
                      <button type="submit" className="enviar_pagamentos">
                        Finalizar
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="btn-enviar-comprovativo">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#686d25" }}
                      className="enviar_pagamentos"
                    >
                      A sua inscrição foi submetida aguarde a sua Aprovação no
                      seu emal...
                      <span> {user?.email}</span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <span className={`btn-enviar-comprovativo ${message2}`}>
                <span
                  style={{ backgroundColor: "#686d25" }}
                  className="enviar_pagamentos"
                >
                  {message}
                </span>
              </span>
            )}
            {VerificarParticipanteAprovado === false ? (
              ""
            ) : (
              <div
                style={{ backgroundColor: "#686d25" }}
                className="btn-enviar-comprovativo"
              >
                <button
                  type="submit"
                  style={{ backgroundColor: "#686d25" }}
                  className="enviar_pagamentos"
                >
                  {message}
                </button>
              </div>
            )}

            {VerificarParticipanteReprovado === false ? (
              ""
            ) : (
              <div className="btn-enviar-comprovativo">
                <button
                  type="submit"
                  style={{ backgroundColor: "#ff4040" }}
                  className="enviar_pagamentos"
                >
                  {message}
                </button>
              </div>
            )}
          </div>
          {/*  */}
          <p id="clicar">
            {" "}
            <a href="#" style={{ color: "white" }} onClick={() => termo()}>
              <span style={{ color: "#B46739", fontWeight: "bold" }}>
                Clique aqui
              </span>
            </a>{" "}
            para ver os termos de leilões online
          </p>
        </div>
      </div>
    </>
  );
};

export default Inscrever;
