import noticias from "../../services/noticias";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PesquisaCardNoticias = () => {
  const [VerRecenteposte, setVerRecenteposte] = useState([]);
  useEffect(() => {
    VerPostRecente();
  });
  const VerPostRecente = async () => {
    noticias
      .getNoticiasRecentes()
      .then((response) => {
        setVerRecenteposte(response.data);
      })

  };
  return (
    <>
      <div className="col-lg-3">
        <div
          className="direita" 
          style={{ backgroundColor: "", borderRadius: "10px" }}
        >
          {/* <h3 className="direita-title">Pesquisar</h3> */}
          {/* <div className="direita-item search-form">
            <form action="">
              <input type="text" style={{ outline: "none", font: "16px" }} />
              <button
                type="submit"
                style={{ background: "#424616", cursor: "pointer" }}
              >
                <i className="bi bi-search"></i>
              </button>
            </form>
          </div> */}
          {/* <!-- End sidebar search formn--> */}

          <h3 className="direita-title">Noticias recentes</h3>
          <div className="direita-item recent-posts">
            {VerRecenteposte.map((noticiasRecentes) => (
              <div class="post-item clearfix" key={noticiasRecentes.codigo}>
                <img
                  src={`${noticiasRecentes.imgUrl}`}
                  style={{ borderRadius: "8px" }}
                  alt=""
                />
                <h4>{noticiasRecentes.titulo}</h4>
                <time> {noticiasRecentes.date} </time>
              </div>
            ))}
          </div>
          {/* <!-- End sidebar recent posts--> */}
        </div>
        {/* <!-- End sidebar --> */}
      </div>
    </>
  );
};
export default PesquisaCardNoticias;
