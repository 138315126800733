import api from "./api";

const getNoticias = () => {
    // return api.get("NoticiaMostrar");
    return api.get("Noticia");
};
const getNoticiasRecentes = () => {
    return api.get("NoticiaRecente");
};

const getNoticiasId = (codigo) => {
    return api.get(`noticia/${codigo}`);
};

//comment

const createComentario = (data) => {
    return api.post("criar_comentario",data);
};

const getComentarios = (codigo) => {
    return api.get(`comentarioNoticia/${codigo}`);
};

const getComentariosCodigo = (codigo) => {
    return api.get(`comentario/${codigo}`);
};

//resposta

const createResposta = (data) => {
    return api.post("criar_resposta",data);
};

const getRespostas = (codigo) => {
    return api.get(`respostaComentario/${codigo}`);
};



// eslint-disable-next-line import/no-anonymous-default-export
export default { getNoticias, getNoticiasRecentes, getNoticiasId, createComentario, getComentarios, createResposta,  getRespostas, getComentariosCodigo}