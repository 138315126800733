import Menu from "../../components/menu/NavBar"; 

const ServicoNoticia = () => {
return(
  <>
    <Menu />
   <div>Noticias</div>
  </>
   
)
};

export default ServicoNoticia;
