import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EntregaService from "../../services/entregas";
import LocalizacaoService from "../../services/localizacao";
import { AuthContext } from "../../contexts/Auth";
import Menu from "../../components/menu/NavBar";
import VendaService from "../../services/vendas";


function Entregas() {
  const { user } = useContext(AuthContext);

  // const userlogado = 39;
  const userlogado = user?.codigo;

  const navigate = useNavigate();
  // ----------------------------------------------
  const [VerPaises, setVerPaises] = useState([]);
  const [ShowProvincias, setShowProvincias] = useState([]);
  const [ShowMunicipios, setShowMunicipios] = useState([]);
  const [DadosDoMunicipio, setDadosDoMunicipio] = useState('');
  const [DadosDoMunicipioId, setDadosDoMunicipioId] = useState([]);
  const [DadosProvinciaNome, setDadosProvinciaNome] = useState('');
  const [DadosPaisNome, setDadosPaisNome] = useState('');

  //const DisplayTransporte;

  useEffect(() => {
    VerGlobal();
    VerOsPaises();
  });
  const [codGlobal, setcodGlobal] = useState()
  const VerGlobal = async () => {
    await VendaService.getCarrinhoGlobal(userlogado)
    .then((response)=>{
      setcodGlobal(response.data[0].codigo)
      verExisteEntrega(response.data[0].codigo)
    })
  }

  const [VerExiste, setVerExiste] = useState("");
  const verExisteEntrega = async (id) => {
    // console.log("console id: "+id)
    await EntregaService.getVerTotalEntregaVisitante(id).then((response) => {
      if (response.data === "") {
        console.log("Está vazio");
      } else {
        console.log(response.data);
        const idPais = response.data.municipio.provincia.pais.codigo
        const NomePais = response.data.municipio.provincia.pais.nome
        const idProvincia = response.data.municipio.provincia.codigo
        const idMunicipio = response.data.municipio.codigo
        const NomeProvincia = response.data.municipio.provincia.nome
        setVerExiste(response.data);
        getProvincias(idPais);
        setDadosPaisNome(NomePais);
        setDadosProvinciaNome(NomeProvincia)
        getMunicipios(idProvincia);
        setDadosDoMunicipioId(idMunicipio)
        setDadosDoMunicipio(response.data.municipio.nome)
      }
    });
    return;
  };

  const addDestino = async (data) => {
    console.log(data)
    if (VerExiste.length === 0) {
      await EntregaService.createDestino(data)
        .then((response) => {
          if (response.data === "existe") {
            Swal.fire({
              title: "Já Inseriu a localização",
              icon: "warning",
            });
            return;
          }
          navigate("/transporte");
        })
        .catch((err) => {
          Swal({
            title: "Erro: " + err,
            icon: "error",
          });
        });
    } else {
      await EntregaService.putDestino(VerExiste.codigo, data)
        .then(() => {
          navigate("/transporte");
        })
        .catch((err) => {
          Swal({
            title: "Erro: " + err,
            icon: "error",
          });
        });
    }
  };

  const confirmarDestino = async (e) => {
    e.preventDefault();
    await Swal.fire({
      title: `${DadosPaisNome} - ${DadosProvinciaNome} - ${DadosDoMunicipio}`,
      text: "Este é o local para aonde os animais serão entregues",
      showCancelButton: true,
      confirmButtonColor: "#9ba235",
      cancelButtonColor: "#b46739",
      confirmButtonText: "Sim, Continuar",
      cancelButtonText: "Cancelar",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        if (VerExiste.length === 0) {
          const data = {
            municipio: {
              codigo: DadosDoMunicipioId,
            },
            visitante: {
              codigo: userlogado,
            },
            carrinhoGlobal: {
              codigo: codGlobal
            }
          };
          addDestino(data);
        } else {
          const data = {
            municipio: {
              codigo: DadosDoMunicipioId,
            },
          };
          addDestino(data);
        }
      }
    });
  };

  const onChangeDestino = (e) => {
    if (e.target.value === undefined) {
      return;
    }
    LocalizacaoService.getDadosMunicicpio(e.target.value)
      .then((response) => {
        setDadosDoMunicipio(response.data.nome);
        setDadosDoMunicipioId(response.data.codigo);
        setDadosProvinciaNome(response.data.provincia.nome);
        setDadosPaisNome(response.data.provincia.pais.nome);
      })
      .catch(() => {
        // Swal.fire(
        //   "Erro de Conexão",
        //   "Por favor verifique a sua internet",
        //   "error"
        // );
      });
  };
  const getProvincias = async (id) => {
    await LocalizacaoService.getPesquisaProvincias(id)
      .then((response) => {
        setShowProvincias(response.data);
      })
      .catch(() => {
        // Swal.fire(
        //   "Erro de Conexão",
        //   "Por favor verifique a sua internet",
        //   "error"
        // );
      });
  };

  const getMunicipios = async (id) => {
    await LocalizacaoService.getPesquisaMunicipios(id)
      .then((response) => {
        setShowMunicipios(response.data);
      })
      .catch(() => {
        // Swal.fire(
        //   "Erro de Conexão",
        //   "Por favor verifique a sua internet",
        //   "error"
        // );
      });
  };

  const VerOsPaises = async () => {
    await LocalizacaoService.getPais()
      .then((response) => {
        setVerPaises(response.data);
      })
      .catch(() => {
        // Swal.fire(
        //   "Erro de Conexão",
        //   "Por favor verifique a sua internet",
        //   "error"
        // );
      });
  };

  const VerAsProvincias = (e) => {
    if (e.target.value === undefined) {
      return;
    }
    getProvincias(e.target.value);
  };
  const VerOsMunicipios = (e) => {
    if (e.target.value === undefined) {
      return;
    }
    getMunicipios(e.target.value);
  };

  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Serviço de entrega</h1>
        </div>
      </section>

      <div>
        <div id="txt-local-entrega">
          <h1
            style={{
              fontSize: "25px",
              color: "#fff",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            Informe o local para aonde serão entregues os seus animais
          </h1>
        </div>
        <div className="doisFinal" id="info-entrega-destino">
          <div className="tabela_final">
            <div className={`entrega_final`}>
              <div className="ver_loc_entrega" style={{ marginBottom: "20px" }}>
                <h1 className="info_entrega">Informações para entrega</h1>

                <form onSubmit={(e) => confirmarDestino(e)}>
                  <div
                    className="localizacao_destino"
                    style={{ padding: "0 10px" }}
                  >
                    <select
                      required
                      name="pais"
                      onChange={(e) => VerAsProvincias(e)}
                    >
                      <option value="">Selcione o Pais</option>
                      {VerExiste.length === 0
                        ? VerPaises.map((pais) => (
                            <option
                              //onClick={() => VerAsProvincias(pais.codigo)}
                              value={pais.codigo}
                            >
                              {pais.nome}
                            </option>
                          ))
                        : VerPaises.map((pais) => (
                            <option
                              //onClick={() => VerAsProvincias(pais.codigo)}
                              value={pais.codigo}
                              selected={
                                pais.nome ===
                                VerExiste.municipio.provincia.pais.nome
                              }
                            >
                              {pais.nome}
                            </option>
                          ))}
                    </select>
                    <select
                      required
                      name="provincia"
                      onChange={(e) => VerOsMunicipios(e)}
                      style={{ margin: "0 5px" }}
                    >
                      <option value="">Selecione a provincia</option>

                      {VerExiste.length === 0 ? 
                      ShowProvincias.map((provincia) => (
                        <option
                          //onClick={() => VerAsProvincias(pais.codigo)}
                          value={provincia.codigo}
                        >
                          {provincia.nome}
                        </option>
                      )) : 
                      ShowProvincias.map((provincia) => (
                        <option
                          //onClick={() => VerAsProvincias(pais.codigo)}
                          value={provincia.codigo}
                          selected={
                            provincia.nome ===
                            VerExiste.municipio.provincia.nome
                          }
                        >
                          {provincia.nome}
                        </option>
                      ))
                      }
                      
                    </select>

                    <select
                      required
                      name="municipio"
                      onChange={(e) => onChangeDestino(e)}
                    >
                      <option value="">Selecione o municipio</option>
                      {VerExiste.length === 0 ? 
                      ShowMunicipios.map((municipios) => (
                        <option
                          key={municipios.codigo}
                          value={municipios.codigo}
                        >
                          {municipios.nome}
                        </option>
                      ))
                      :
                      ShowMunicipios.map((municipios) => (
                        <option
                          key={municipios.codigo}
                          value={municipios.codigo}
                          selected={
                            municipios.nome === VerExiste.municipio.nome
                          }
                        >
                          {municipios.nome}
                        </option>
                      ))}
                      
                    </select>
                  </div>
                  <div className="form_destino_botao">
                    <div></div>
                    {VerExiste.length === 0 ? (
                      <button type="submit" className="btn_laranja">
                        Confirmar Destino
                      </button>
                    ) : (
                      <button type="submit" className="btn_laranja">
                        Confirmar Destino
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Entregas;
