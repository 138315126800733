import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CooperadoresService from "../../services/cooperador";
import CardCooperador from "../../components/cooperador/cardCooperador";
// import "../../components/animais/styles.css";
import Menu from "../../components/menu/NavBar";
import "./styles.css";
// import io from "socket.io-client";

// const socket = io('https://webcwam-polished-dust-7716.fly.dev/api//cooperadores');
// const socket = io();

const Cooperador = () => {
  const [VerCooperadores, setVerCooperadores] = useState([]);

  const VerOsCooperadores = async () => {
    CooperadoresService.getCooperadores()
      .then((response) => {
        setVerCooperadores(response.data);
        console.log(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };
  // const sendPing = () => {
  //   socket.emit('ping');
  // }
  useEffect(() => {
    VerOsCooperadores();
    // sendPing()
    // socket.on("connection", VerOsCooperadores());
  }, []);

  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder txt-inicio-coop">Socket Io</h1>
        </div>
      </section>
      <section className="animais">
        <div className="m-2 ani_p">
          {VerCooperadores.map((cooperador) => (
            <CardCooperador
              codigo={cooperador.codigo}
              nome={cooperador.nome}
              sobrenome={cooperador.sobrenome}
              email={cooperador.email}
              item={cooperador}
              key={cooperador.codigo}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default Cooperador;
