import { useEffect, useState } from "react";
import { getTotalDeCompras } from "../../../services/ComprasService";
import { getLoteGeralCompras } from "../../../services/ComprasService";
import { Link } from "react-router-dom";

function CardComprasUserModalGeral(props) {
  // useEffect(() => {
  //   getTotal();
  // });

  // const [verTotal, setTotal] = useState();
  // const getTotal = async () => {
  //   // await getTotalDeCompras(props.id)
  //   //   .then((response) => {
  //   //     setVerAnimais(response.data);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  // };

  // const [verAnimais, setVerAnimais] = useState();
  // const getLoteGeral = async () => {
  //   await getLoteGeralCompras(props.id)
  //     .then((response) => {
  //       setVerAnimais(response.data);
  //       console.log(props.Animal);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // const cor = { color: "#fff" };

  return (
    <>
      <div className="PaideTodosModalcartModal">
        <div className="imgDoCartUserModal"></div>
        <div className="estilodaListaDaModal">
          <ul className="estilodaListaDaModalPai">
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li style={{ borderTopLeftRadius: "10px" }}>Especie</li>
                <li
                  style={{ borderTopRightRadius: "10px" }}
                  className="corescuramodalcart"
                >
                  Bovino
                </li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li>Raça </li>
                <li>Nelore</li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li>Qtd. de Machos:</li>
                <li className="corescuramodalcart">4</li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li>Qtd. de Femeas:</li>
                <li>{props.Animal.pesomin}</li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li>Peso Minimo:</li>
                <li className="corescuramodalcart">{props.Animal.pesomin}Kg</li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li style={{ borderBottomLeftRadius: "10px" }}>
                  Peso Maximo:{" "}
                </li>
                <li style={{ borderBottomRightRadius: "10px" }}>{props.Animal.pesomaxi}Kg</li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li>Idade Minima:</li>
                <li className="corescuramodalcart">{props.Animal.idademin} meses</li>
              </ul>
            </li>
            <li style={{ margin: "0" }}>
              <ul className="estilodaListaDaModalFilho" style={{ margin: "0" }}>
                <li style={{ borderBottomLeftRadius: "10px" }}>
                  Idade Maxima:{" "}
                </li>
                <li style={{ borderBottomRightRadius: "10px" }}>{props.Animal.idademax} meses</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default CardComprasUserModalGeral;
