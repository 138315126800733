import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import FazendaService from "../../../services/fazendas";
import Menu from "../../../components/menu/NavBar";
import "./styles.css";

const Sobrefazendas = () => {
  //~
  const { codigo } = useParams();
  const [VerAnimais, setVerAnimais] = useState([]);
//
const [fazendas, setFazendass] = useState({
  logo: "",
  nome: "",
  area: "",
  nif: "",
  pais: {
    nome: "",
  },
  cooperador: {
    nome: "",
  },
  municipio: {
    nome: "",
    provincia: {
      nome: "",
    },
  },
});
 //
 useEffect(() => {
  loadOurService();
  VerAnimalDafazendas();
}, []);
//
const loadOurService = () => {
  if (codigo) {
    FazendaService.getCodigoFazenda(codigo).then((response) => {
      setFazendass(response.data);
    });
  }
};

const VerAnimalDafazendas = async () => {
  FazendaService.getAnimalFazenda(codigo).then((response) => {
    setVerAnimais(response.data);
  });
};


  //
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section
        className="fundo_entrar_fazenda"
        style={{
          backgroundImage: `url(${fazendas.logo})`,
        }}
        // style={{
        //   backgroundImage: `url(${fazendas.logo})`,
        // }}
      >
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">
            Seja Bem vindo a {fazendas.nome}
          </h1>
        </div>
      </section>

      <h1 className="h1_sobre_fazenda">Sobre a {fazendas.nome}</h1>
      <section className="corpo f_corpo">
        <div className="fotografiaAnimal">
          <img
            className="img-animal"
            src={`${fazendas.logo}`}
            // src={`${fazendas.logo}`}
            alt=""
          />
        </div>
        <div className="conteudo conteudo_fazenda">
          <div className="legenda_do_lance">
            <p>
              localizaçao:{" "}
              <strong>
                {fazendas.municipio.provincia.nome} -{" "}
                {fazendas.municipio.nome}
              </strong>
            </p>
            <p>
              Area:{" "}
              <strong>
                {fazendas.area}m<sup>2</sup>
              </strong>
            </p>
            {/* <p>
              Nif:
               <strong>{fazendas.nif}</strong>
            </p> */}
            <p>
              Nome do Cooperador:
              <Link
                to={`/sobre_cooperador/${fazendas.cooperador.codigo}`}
              >
                <strong> {fazendas.cooperador.nome} {fazendas.cooperador.sobrenome}</strong>
              </Link>
            </p>
          </div>
          <p>
            {/* <a href="#geo_fazendas" className="ver_mapa_fazendas is-link">
              Ver no mapa
            </a> */}
            <Link to="/fazendas" className="button is-link" style={{ background: '#9ba236' }}>Voltar</Link>
          </p>
         
        </div>
      </section>

      <h1 className="h1_sobre_fazenda">Nossos Animais</h1>
      <section className="animais">
        <div className="m-2 ani_p">
          {VerAnimais.map((animais) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(${animais.imgUrl})`,
                  // backgroundImage: `url(${animais.imgUrl})`,
                }}
              ></div>
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {animais.raca.racaAnimal}
                </h2>
                <table>
                  <tr>
                    <td>Proveniente:</td>
                    <td>{animais.pais?.nome}</td>
                  </tr>
                  <tr>
                    <td>Raça:</td>
                    <td>{animais.raca?.racaAnimal}</td>
                  </tr>
                  <tr>
                    <td>Peso:</td>
                    <td>{animais.peso}kg</td>
                  </tr>
                </table>
                <Link
                  to={`/sobre_animais/${animais.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Saber Mais
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      <h1 className="h1_sobre_fazenda" id="geo_fazendas">
        Nossa Geolocalização
      </h1>

      <section
        className="section"
        id="mapa_fazendas"
        style={{ display: "flex", justifyContent: "center" }}
      >
           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2709.824280733739!2d13.493065682012642!3d-14.919777939994537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-PT!2sao!4v1716545996595!5m2!1spt-PT!2sao"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy">

        </iframe>
      </section>
    </>
  );
};

export default Sobrefazendas;
