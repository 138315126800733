import Menu from "../../components/menu/NavBar";
import noticias from "../../services/noticias";
import Swal from "sweetalert2";
import { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import PesquisarNoticiasCard from "../../components/noticias/PesquisarNoticiasCard";
import Comentario from "../../components/comentario/Comentario";
import { AuthContext } from "../../contexts/Auth";

const ViewNoticias = () => {

  const [VerNoticiaCodigo, setVerNoticiaCodigo] = useState({});
  const [noticiaCodigo, setNoticiaCodigo] = useState("");
  const [VerComentario, setVerComentario] = useState([]);
  const { codigo } = useParams();


  const VerAsNoticiasCodigo = async () => {
    await noticias
      .getNoticiasId(codigo)
      .then((response) => {
        setVerNoticiaCodigo(response.data);
        const result = response.data.codigo;
        setNoticiaCodigo(result)
      })
      .catch((err) => {
        // Swal.fire({
        //   title: "Erro em estabelecer a Conexão"+err,
        //   text: "Verifique a sua internet ou tente mais tarde",
        //   icon: "error",
        // });
      });
  };

  const VerOsComentarios = async () => {
    await noticias
      .getComentarios(codigo)
      .then((response) => {
        setVerComentario(response.data);
      })
      .catch(() => {
        // Swal.fire({
        //   title: "Erro em estabelecer a Conexão"
        // });
      });
  };

  useEffect(() => {
    VerAsNoticiasCodigo();
    VerOsComentarios();
  });

  const pages = Math.ceil(VerComentario.length);
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return (
    <>
      <Menu />

      <main id="main">
        <div className="view_eri">
          <div
            className="view_fundo_preto_cima"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${VerNoticiaCodigo.imgUrl})url("../components/imgs/animal-6.jpg")`,
            }}
          >
            <p>{VerNoticiaCodigo.titulo}</p>
          </div>
        </div>
        {/* <!-- ======= Blog Single Section ======= --> */}
        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up">
            <div className="row when-responsive">
              <div className="col-lg-8 entries when-responsive-1">
                <article className="entry entry-single view_noticia_branca">
                  <div
                    className="entry-img"
                    style={{
                      borderRadius: "10px 10px 0 0",
                      backgroundColor: "#b46739",
                    }}
                  >
                    <img
                      src={`${VerNoticiaCodigo.imgUrl}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <h2 className="entry-title">
                    <a href="#">{VerNoticiaCodigo.titulo}</a>
                  </h2>

                  <div className="entry-meta">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-person"></i>{" "}
                        <a href="#">CWAM</a>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>{" "}
                        <a href="#">
                          <time datetime="2020-01-01">
                            {new Date(VerNoticiaCodigo.date).getDate() === new Date().getDate() &&
                              new Date(VerNoticiaCodigo.date).getMonth() === new Date().getMonth() &&
                              new Date(VerNoticiaCodigo.date).getFullYear() ===
                              new Date().getFullYear() ? (
                              <>
                                Hoje às {new Date(VerNoticiaCodigo.date).getHours()}:
                                {new Date(VerNoticiaCodigo.date).getMinutes()} (
                                {new Date(new Date() - new Date(VerNoticiaCodigo.date)).getHours() - 1 >
                                  0 ? (
                                  <>
                                    {new Date(new Date() - new Date(VerNoticiaCodigo.date)).getHours() -
                                      1}
                                    h
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                {new Date(new Date() - new Date(VerNoticiaCodigo.date)).getMinutes()} min
                                atrás )
                              </>
                            ) : (
                              <>
                                {new Date(VerNoticiaCodigo.date).getDate()} de {" "}
                                {meses[new Date(VerNoticiaCodigo.date).getMonth()]} de{" "}
                                {new Date(VerNoticiaCodigo.date).getFullYear()} às{" "}
                                {new Date(VerNoticiaCodigo.date).getHours()}:
                                {new Date(VerNoticiaCodigo.date).getMinutes()}
                              </>
                            )}
                          </time>
                        </a>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-chat-dots"></i>{" "}
                        <a href="#">{pages} Comentarios</a>
                      </li>
                      {/* <li className="d-flex align-items-center">
                        <i className="bi bi-chat-dots"></i>{" "}
                        <Link className="read-more" to="noticias">
                          Voltar
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className="entry-content">
                    <p className="view_noticia_letra_preta">
                      {VerNoticiaCodigo.conteudo}
                    </p>
                  </div>
                </article>
                {/* <!-- End blog entry --> */}
                <Comentario noticiaCodigo={noticiaCodigo} />
                {/* <!-- End blog comments --> */}


              </div>
              {/* <!-- End blog entries list --> */}

              {/* <div class="direita">
            <PesquisarNoticiasCard />
          </div>  */}

              {/* <!-- End blog sidebar --> */}
            </div>
          </div>
        </section>

        {/* <!-- End Blog Single Section --> */}
      </main>
      {/* <!-- End #main --> */}
    </>
  );
};

export default ViewNoticias;
