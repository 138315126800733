import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const animal1 = require("./imgs/CWAM/IMG_9716.jpg") as string;
const animal2 = require("./imgs/CWAM/IMG_9718.jpg") as string;
const animal3 = require("./imgs/CWAM/IMG_9697.jpg") as string;
const animal4 = require("./imgs/CWAM/IMG_9700.jpg") as string;
// const animal4 = require("./imgs/CWAM/IMG_9717.jpg") as string;

// const animal1 = require("./imgs/Cultura Coop_Huíla.png") as string;
// const animal2 = require("./imgs/Cultura Coop_Cuando Cubango.png") as string;
// const animal3 = require("./imgs/Cultura Coop_Namibe.png") as string;
// const animal4 = require("./imgs/Cultura Coop_Cunene.png") as string;

export default class AutoPlay extends Component {
  render() {
    const settings = {
      slidesToShow: 1,
      autoplay: true,
      speed: 400,
      autoplaySpeed: 4000,
      pauseOnHover: false
    };
    return (
      <div>

        <Slider {...settings}>
          <div>
            <div style={{ height: "500px" }}>
              <img src={animal1} alt="" width="100%" height="690px" />
            </div>
          </div>
          <div>
            <div style={{ height: "690px" }}>
              <img src={animal2} alt="" width="100%" height="690px" />
            </div>
          </div>
          <div>
            <div style={{ height: "690px" }}>
              <img src={animal3} alt="" width="100%" height="690px" />
            </div>
          </div>
          <div>
            <div style={{ height: "690px" }}>
              <img src={animal4} alt="" width="100%" height="690px" />
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
