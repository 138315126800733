import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AnimalService from "../../services/animais";
import Menu from "../../components/menu/NavBar";

export function Animais() {
  const [VerAnimais, setVerAnimais] = useState([]);
  const [loading, setLoading] = useState(true);

  const [itensPorPage, setitensPorPage] = useState([8]);
  const [correntPage, setcorrentPage] = useState([0]);

  const pages = Math.ceil(VerAnimais.length / itensPorPage);
  const startItens = correntPage * itensPorPage;
  const endItens = startItens + itensPorPage;
  const correntItens = VerAnimais.slice(startItens, endItens);

  const verOsAnimais = async () => {
    AnimalService.getAnimais()
      .then((response) => {
        setVerAnimais(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Erro em estabelecer a Conexão",
          text: "Verifique a sua internet ou tente mais tarde",
          icon: "error",
        });
      });
  };
  const load = () => {
    if (loading) {
      return (
        <div style={{ color: "red", width: "300px", height: "300px" }}></div>
      );
    }
  };

  useEffect(() => {
    verOsAnimais();
    load();
    setLoading(false);
  }, []);

  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder is-4">Animais da CWAM</h1>
        </div>
      </section>

      <section className="animais">
        <div className="m-2 ani_p">
          {correntItens.map((animais) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(${animais.imgUrl})`,
                  borderRadius: "20px",
                }}
              ></div>
              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  Raça: {animais.raca.racaAnimal}
                </h2>
                <table>
                  <tr>
                    <td>Proveniente:</td>
                    <td>{animais.pais.nome}</td>
                  </tr>

                  <tr>
                    <td>Peso:</td>
                    <td>{animais.peso}kg</td>
                  </tr>
                </table>
                <p className="mb-1"></p>
                <Link
                  to={`/sobreAnimais/${animais.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Saber Mais
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="sect">
        {Array.from(Array(pages), (VerAnimais, index) => {
          return (
            <button
              className="pagB"
              value={index}
              onClick={(e) => setcorrentPage(Number(e.target.value))}
            >
              {index + 1}
            </button>
          );
        })}
      </section>
    </>
  );
}

export default Animais;
