import React, { useState, useEffect, useContext } from "react";
import CardComprasUserModal from "./CardComprasUserModal";
import CardComprasUserModalGeral from "./CardComprasUserModalGeral.js";
import { getLoteGeralCompras } from "../../../services/ComprasService";
import { Link } from "react-router-dom";
const ListaLoteCompra = (props) => {
  useEffect(() => {
    verLote();
    VerOId();
  });

  const verLote = () => {
    if (props.loteGeral) {
      props.tipo("geral");
    } else if (props.loteExistente) {
      props.tipo("existente");
    }
  };

  const VerOId = () => {
    props.idItem(props.loteCodigo);
  };
  const [verTipo, setverTipo] = useState();
  const VerOTipo = (data) => {
    setverTipo(data);
  };

  const [idDoLote, setidDoLote] = useState();
  const [verAnimais, setVerAnimais] = useState("");
  const VerModal = async (id) => {
    console.log(id);
    setidDoLote(id);
    await getLoteGeralCompras(id)
      .then((response) => {
        setVerAnimais(response.data[0]);
        // console.log(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fecharModal = () => {
    setVerAnimais("");
    setidDoLote("");
    console.log("limpou");
  };

  return (
    <>
      <tr
        className="verComprasInfoUser"
        style={{
          backgroundColor: "#fff",
          color: "#854a28",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <td data-label="Posição" className="qEp" id="fff">
          
        </td>
        <td data-label="Lote de animais" className="qEp" id="fff">
          Lote de {props.especie}
        </td>
        <td data-label="Quantidade" className="qEp" id="fff">
          1
        </td>
        <td data-label="Valor Unitário" className="qEp" id="fff">
          {props.preco}Kz
        </td>
        <td data-label="Valor Total" className="qEp" id="fff">
          {props.total}Kz
        </td>
        <td data-label="Detalhes" className="qEp" id="fff">
          <Link
            to={`/perfilcomprainfoItems/${props.loteCodigo}`}
            className="btn_ver_comp_user"
          >
            Ver
          </Link>
        </td>
      </tr>
      {/* MODAL */}
      <div className="modalDesp" id="modal2" tabindex="-1">
        <div className="modal__contentDesp2">
          <a
            href="#"
            // onClick={fechar_modal_cart}
            className="modal__close"
            aria-label="Fechar"
          >
            <span onClick={() => fecharModal()}>x {idDoLote}</span>
          </a>
          {/* <!--Conteudo--> */}
          <div className="container">
            <div className="content">
              {/* <!--FORMULÁRIO DE CADASTRO--> */}
              {verTipo === "existente" ? (
                <CardComprasUserModal />
              ) : verAnimais === 0 ? (
                ""
              ) : (
                <CardComprasUserModalGeral Animal={verAnimais} />
              )}

              {/* <!--FIM DO FORMULÁRIO DE CADASTRO--> */}
            </div>
          </div>
          {/* <!--Fim de conteudo--> */}
        </div>
      </div>
      {/* FIM MODAL */}
    </>
  );
};

export default ListaLoteCompra;
