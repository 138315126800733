import api from "./api";

const getLeilao = () => {
    return api.get("leilaos");
};

const getVerLoteRapido = (codigo) => {
    return api.get(`lotesRapidos/${codigo}`);
};

const getVerLoteRapidoPorId = (codigo) => {
    return api.get(`loteRapidoByLote/${codigo}`);
};

const getLoteExistente = (codigo) => {
    return api.get(`loteAnimal/${codigo}`);
};

const getLeilaoCodigo = (codigo) => {
    return api.get(`leilao/${codigo}`);
};

const getLance = (codigo) => {
    return api.get(`pegarleilao/${codigo}`);
};

//PARTICIPANTE
const createPegarPedente = (data) => {
    return api.post("pendenteParticipante", data);
  };

  const getParticipanteVisitanteExistente = (codigo) => {
    return api.get(`participanteVisitante/${codigo}`);
};


const getVerificarParticipanteVisitanteExistente = (codigo, leilao) => {
    return api.get(`verificarParticipante/${codigo}/${leilao}`);
};


const sendParticipantePedenteComprovativo = (data) => {
    return api.post(`pendenteParticipanteComprovativo`, data);
  };




// eslint-disable-next-line import/no-anonymous-default-export
export default { getLeilao, getLance, getLeilaoCodigo, getVerLoteRapido, getVerLoteRapidoPorId, getLoteExistente, createPegarPedente, getParticipanteVisitanteExistente, getVerificarParticipanteVisitanteExistente, sendParticipantePedenteComprovativo }