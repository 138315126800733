import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import VisitanteService from "../../services/visitante";
import LocalizacaoService from "../../services/localizacao";
import * as Yup from "yup";
import { registroSchema } from '../../components/validation/Schema';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Menu from "../../components/menu/NavBar";

export function Visitante() {
  const navigate = useNavigate();
  //
  const [visitante, setVisitante] = useState({
    nome: "",
    sobrenome: "",
    email: "",
    telefone: "",
    pais: {
      codigo: ""
    },
    gender: "",
    bi: "",
    senha: "",
    conf_senha: "",
    nascimento: "",
  });
  const {
    nome,
    sobrenome,
    email,
    bi,
    senha,
    conf_senha,
    pais,
    gender,
    nascimento,
  } = visitante;


  const [telefone, setTelefone] = useState('');
  const [errors, setErrors] = useState({});



  // Buscar por ID
  const [AddPais, setAddPais] = useState()
  const onInputChange = (e, errorMessage) => {
    setVisitante({ ...visitante, [e.target.name]: e.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: errorMessage || "",
    }));
    if (e.target.name === "pais") {
      setAddPais(e.target.value)

    }
  };


  const [VerPais, setVerPais] = useState([]);
  const VerOsPaises = async () => {
    await LocalizacaoService.getPais().then((response) => {
      setVerPais(response.data);
    });
  };
  useEffect(() => {
    VerOsPaises();
  }, []);


  //Botao Salvar
  const saveUpdatevisitante = async (e) => {
    e.preventDefault();
    const visitante = {
      nome,
      sobrenome,
      email,
      telefone,
      senha,
      pais: {
        codigo: AddPais
      },
      bi,
      gender,
      nascimento
    }
    try {
      registroSchema.validateSync(visitante, { abortEarly: false });
      const { data } = await VisitanteService.createVisitante(visitante);
      if (data === "saved") {
        Swal.fire({
          title: "Cadastrado com sucesso",
          text: "Acabamos de enviá-lo no seu e-mail, um link de activação, para certificar os seus dados.",
          icon: "success",
          confirmButtonColor: "#686d25",
        });
        navigate("/login");
        // navigate("/verificarEmail");

      } else if (data === "email_exists") {
        Swal.fire({
          title: "Email invalido",
          text: "Este email já foi cadastrado, Tente outro",
          icon: "warning",
          confirmButtonColor: "#686d25",
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      } else {
        Swal.fire({
          title: "Erro de conexão",
          text: "Verifique a sua internet e tente novamente",
          icon: "error",
          confirmButtonColor: "#686d25",
        });
      }
    }
  };
  //
  return (
    <>
      <Menu />

      <section
        id="section"
        className="verde sobre"
        style={{ flexDirection: "column" }}
      >
        <div style={{ width: "75%", margin: "30px 10px" }}>
          <h1
            className="title has-text-centeder"
            style={{
              color: "#fff",
              textAlign: "center",
            }}
          >
            Cadastre-se como visitante da CWAM
          </h1>
          <p style={{ textAlign: "justify" }}>
            Para abertura da sua conta visitante na CWAM, certifica-te de que
            todas as informações colocadas são válidas, porque só pode criar a
            conta uma vez e alguns dados não poderão ser alterados por questões
            de integridade.
          </p>
        </div>
        <div className="login_texto cadastrar_responsive">
          <div className="p_s is-flex">
            <form
              onSubmit={(e) => saveUpdatevisitante(e)}
            >
              <div className="cadastro">
                <div className="c_cima is-flex justify-space-between">
                  <div></div>
                </div>

                <div className="s_meio">
                  <p className="s_pp is-flex quebra_visitante">
                    <div>
                      <input
                        type="text"
                        placeholder="Nome"
                        name="nome"
                        value={nome}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.nome && (
                        <div className="error-text">{errors.nome}</div>
                      )}
                    </div>
                    <p className="linha_baixo_visi">
                    </p>
                    <div>
                      <input
                        type="text"
                        placeholder="Sobre Nome"
                        name="sobrenome"
                        value={sobrenome}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.sobrenome && (
                        <div className="error-text">{errors.sobrenome}</div>
                      )}
                    </div>
                  </p>
                  {/* */}
                  <span style={{ fontSize: "10px" }}>Data de Nascimento</span>
                  <p className="s_pp is-flex quebra_visitante">
                    <div>
                      <input
                        name="nascimento"
                        id="nascimento"
                        type="date"
                        value={nascimento}
                        onChange={(e) => onInputChange(e)}
                        placeholder="Sua data de Nascimento"
                      />
                      {errors.nascimento && (
                        <div className="error-text">{errors.nascimento}</div>
                      )}
                    </div>

                    <p className="linha_baixo_visi">
                    </p>
                    <div className="selectNE">
                      <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.email && (
                        <div className="error-text">{errors.email}</div>
                      )}
                    </div>
                  </p>
                 
                  <p className="s_pp is-flex quebra_visitante">
                    <div style={{ paddingLeft: "20px" }}>
                      <PhoneInput
                        defaultCountry="ao"
                        disableSearchIcon={true}
                        disableDropdown={true}
                        inputStyle={{
                          // width: "100%",
                          outline: "none",
                        }}
                        value={telefone}
                        onChange={(telefone) => setTelefone(telefone)}
                      />
                      {errors.telefone && (
                        <div className="error-text">{errors.telefone}</div>
                      )}
                    </div>
                    <p className="linha_baixo_visi"></p>
                    {/*  */}
                    <div className="selectNB">
                      <input
                        type="text"
                        placeholder="Nº Do Bilhete de Identidade"
                        name="bi"
                        value={bi}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.bi && (
                        <div className="error-text">{errors.bi}</div>
                      )}
                    </div>
                  </p>

                  <p className="s_pp is-flex quebra_visitante">
                    <div >
                      <select
                        name="pais"
                        value={pais}
                        onChange={(e) => onInputChange(e)}
                        id=""
                      >
                        <option>Selecione o pais</option>
                        {VerPais.map((pais) => (
                          <option key={pais.codigo}
                            value={pais.codigo}>{pais.nome}</option>
                        ))}
                      </select>
                      {errors.pais && (
                        <div className="error-text">{errors.pais}</div>
                      )}
                    </div>
                    {/*  */}
                    <p className="linha_baixo_visi"></p>
                    <div className="selectPG">
                      <select
                        name="gender"
                        value={gender}
                        onChange={(e) => onInputChange(e)}
                        required
                      >
                        <option>Selecione o sexo</option>
                        <option value="MASCULINO">Masculino</option>
                        <option value="FEMENINO">Femenino</option>
                      </select>
                      {errors.gender && (
                        <div className="error-text">{errors.gender}</div>
                      )}
                    </div>
                  </p>
                  {/*  */}
                  <p className="s_pp is-flex quebra_visitante">
                    <div>
                      <input
                        type="password"
                        placeholder="Senha"
                        name="senha"
                        value={senha}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.senha && (
                        <div className="error-text">{errors.senha}</div>
                      )}
                    </div>
                    <p className="linha_baixo_visi"></p>
                    <div>
                      <input
                        type="password"
                        placeholder="Confirmar Senha"
                        name="conf_senha"
                        value={conf_senha}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.conf_senha && (
                        <div className="error-text">{errors.conf_senha}</div>
                      )}
                    </div>
                  </p>
                  {/*  */}
                </div>
                <div className="s_bot is-flex">
                  <div></div>
                  <div>
                    <Link to="/login">
                      <button className="s_btn" style={{ marginRight: "30px" }}>
                        Cancelar
                      </button>
                    </Link>
                    <button className="s_btn" type="submit">
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
