import { useEffect, useState } from "react";
import { getTotalDeCompras } from "../../../services/ComprasService";
import { Link } from "react-router-dom";

function CardComprasUserModal(props) {
  useEffect(() => {
    verOTotal();
  });

  const [verAnimais, setVerAnimais] = useState();
  const verOTotal = async () => {
    // await getTotalDeCompras(props.id)
    //   .then((response) => {
    //     setVerAnimais(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

  };
  const cor = { color: "#fff" };

  return (
    <>
      <table className="responsiveTable">
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th style={cor}>Cod Lote</th>
            <th style={cor}>Especie</th>
            <th style={cor}>Raça</th>
            <th style={cor}>Peso</th>
            <th style={cor}>Sexo</th>
            <th style={cor}>Proveniencia</th>
            <th style={cor}>Fazenda</th>
          </tr>
        </thead>
        <tbody>
            <tr
              className="verComprasInfoUser"
              style={{
                backgroundColor: "#fff",
                color: "#854a28",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <td data-label="Lote" className="qEp" id="fff">Lote 5</td>
              <td data-label="Espécie" className="qEp" id="fff">Bovino</td>
              <td data-label="Raça" className="qEp" id="fff">Nelore</td>
              <td data-label="Peso" className="qEp" id="fff">150kg</td>
              <td data-label="Sexo" className="qEp" id="fff">Macho</td>
              <td data-label="Proveniêcia" className="qEp" id="fff">Inglaterra</td>
              <td data-label="Fazenda" className="qEp" id="fff">Fazenda Borges</td>
            </tr>
            <tr
              className="verComprasInfoUser"
              style={{
                backgroundColor: "#fff",
                color: "#854a28",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >

            </tr>

            <tr
              className="verComprasInfoUser"
              style={{
                backgroundColor: "#fff",
                color: "#854a28",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <td data-label="Lote" className="qEp" id="fff">Lote 5</td>
              <td data-label="Espécie" className="qEp" id="fff">Bovino</td>
              <td data-label="Raça" className="qEp" id="fff">Nelore</td>
              <td data-label="Peso" className="qEp" id="fff">150kg</td>
              <td data-label="Sexo" className="qEp" id="fff">Macho</td>
              <td data-label="Proveniêcia" className="qEp" id="fff">Inglaterra</td>
              <td data-label="Fazenda" className="qEp" id="fff">Fazenda Borges</td>
            </tr>
            <tr
              className="verComprasInfoUser"
              style={{
                backgroundColor: "#fff",
                color: "#854a28",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >

            </tr>

        </tbody>
      </table>
    </>
  );
}
export default CardComprasUserModal;
