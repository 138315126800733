import Menu from "../components/menu/NavBar";
import ReactPlayer from 'react-player/youtube'

export function About() {
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section className="fundo_entrar_sobre">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder" id="txt-inicio-sobre">
            Saiba mais sobre a nossa Cooperativa
          </h1>
        </div>
      </section>

      <div id="conteudo">
        <div className="meio">
          <div className="is-flex is-flex-direction-row is-justify-content-space-between m-auto mms">
            <div className="mp card-missao">
              <div className="m_laranja is-flex is-justify-content-center">
                <div
                  id="tresimg1"
                  className="m_img mr-auto ml-auto mt-20 mb-4"
                ></div>
              </div>
              <div className="m_letra verde_escuro p-2 m-t quadrado">
                <p>
                  A Missão da CWAM consiste na valorização do setor do agro-negócio de modos a contribuir para o crescimento de desenvimento sustentavel e nível reguinal e nacional.
                </p>
              </div>
            </div>
            <div className="mp card-missao">
              <div className="m_laranja is-flex is-justify-content-center">
                <div id="tresimg2" className="m_img mr-auto ml-auto mt-10 mb-4">
                  <img src="" alt="" width="100%" height="100%" />
                </div>
              </div>
              <div className="m_letra verde_escuro p-2 m-t quadrado">
                <p>
                  {" "}
                  A visão da CWAM consiste na possibilidade de oferecer aos consumidores angolanos uma produção agrícola diversificada e de qualidade assim como gerar oportunidades no agro-negócio.
                </p>
              </div>
            </div>
            <div className="mp card-missao">
              <div className="m_laranja is-flex is-justify-content-center">
                <div id="tresimg3" className="m_img mr-auto ml-auto mt-10 mb-4">
                  <img src="" alt="" width="100%" height="100%" />
                </div>
              </div>
              <div className="m_letra verde_escuro p-2 m-t quadrado">
                <p>
                  Os valores de CWAM consistem na possibilidade de criar oportunidades aos seus associados, promovendo um ambeinte de negócio e sustentabilidade de mercado atravéz do agro-negócio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        id="section"
        className="verde_escuro"
        style={{ marginTop: "-100px" }}
      >
        <div className="sobre_texto">
          <h1>OBJETIVOS DA</h1>
          <h1>COOPERATIVA CWAM</h1>
          <p className="texto">
            <ol>
              <li>Cooperação</li>
              <li>
                Responsabilidade
              </li>
              <li>Profissionalismo</li>
              <li>
                Ética
              </li>
              <li>
                Confiança
              </li>
            </ol>
          </p>
        </div>
      </section>
      <div className="dividir"></div>
      <section
        id="section"
        className="verde_escuro sobre2"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* <div className="img_chefe"></div> */}
        <div className="piramide">
          <div className="filho_piramide">
            <div className="neto_priramide pai_quadrado_piramide1">
              <div>
                <div className="img_piramide1"></div>
                <div className="legenda_piramide">Presidente</div>
                <div className="legenda_piramide">Piedade Pena</div>
              </div>
            </div>
            <div className="neto_priramide pai_quadrado_piramide2">
              <div>
                <div className="img_piramide5"></div>
                <div className="legenda_piramideVice menos">Vice-presidente</div>
                <div className="legenda_piramideVice menos">
                  Reginaldo Paiva
                </div>
              </div>
              <div>
                <div className="img_piramide2"></div>
                <div className="legenda_piramideVice mais">Secretária</div>
                <div className="legenda_piramideVice mais">Yolanda do Carmo</div>
              </div>{" "}
            </div>
            {/*  */}
            <div className="neto_priramide pai_quadrado_piramide3">
              <div>
                <div className="img_piramide6"></div>
                <div className="legenda_piramideVice menos">Diretor Geral</div>
                <div className="legenda_piramideVice menos">Henrique Miguel</div>
              </div>
              <div>
                <div className="img_piramide_avatar"></div>
                <div className="legenda_piramideVice">Sub-Diretor</div>
                <div className="legenda_piramideVice">Jorge Elias</div>
              </div>
              <div>
                <div className="img_piramide_avatar"></div>
                <div className="legenda_piramide">Secretário</div>
                <div className="legenda_piramide">Carlos Santos</div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="parceiros">Parceiros</h2>

        <div class="containerParceiros">

          <div class="cardParceiro">
            <div className="PP1">
              <div className="FotoParceiro1"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP2">
              <div className="FotoParceiro2"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP3">
              <div className="FotoParceiro3"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP4">
              <div className="FotoParceiro4"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP5">
              <div className="FotoParceiro5"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP6">
              <div className="FotoParceiro6"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP7">
              <div className="FotoParceiro7"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP8">
              <div className="FotoParceiro8"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP11">
              <div className="FotoParceiro11"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP10">
              <div className="FotoParceiro10"></div>
            </div>
          </div>

          <div class="cardParceiro">
            <div className="PP9">
              <div className="FotoParceiro9"></div>
            </div>
          </div>

        </div>

        <div className="t2">
          <div className="sobre_texto2">
            <h1>BEM-VINDO</h1>
            <h1 className="mb-5">COOPERATIVA CWAM</h1>
            <p className="texto2">
              A cooperativa Agrícola Wapoma e Mineira, tem sua sede na cidade do Lubango, província da Huíla, bairro comercial. Valoriza o setor do agro-negócio contribuindo para o crescimento e desenvolvimento sustentável, regional e nacional.
            </p>
          </div>
        </div>

        <div className="EspacoVideo">
          <section id="video">
            <div className="NoVideo" style={{ width: "980px", height: "320px", marginBottom: "60px" }}>
              <div className="videoPP">
                <ReactPlayer className="video" url='https://www.youtube.com/watch?v=VUeeBy6G9Rs' playing
                  width='100%'
                />
              </div>
            </div>
          </section>
        </div>

      </section>
      <div className="dividir2"></div>

      <section className="section">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2709.824280733739!2d13.493065682012642!3d-14.919777939994537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-PT!2sao!4v1716545996595!5m2!1spt-PT!2sao"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy">

        </iframe>
        {/* <iframe
          src="https://maps.app.goo.gl/QW2DqHwohZfwiiUv8"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe> */}
      </section>
      <div className="dividir2"></div>
    </>
  );
}