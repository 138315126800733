import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import UserPerfil from "../../services/UserPerfil";
import { Link, useNavigate, useParams } from "react-router-dom";
import VisitanteService from "../../services/visitante";
import { AuthContext } from "../../contexts/Auth";
import * as yup from "yup";
const licitadorima = require("../imgs/licitador.png");

const InfoUser = () => {
  const { user } = useContext(AuthContext);
  const { codigo } = useParams();

  const logado = user?.codigo;
  console.log(logado)

  const [VerInformacaoUser, setInformacaoUser] = useState([]);
  const [verificado, setVerificado] = useState("");
  const [btnState, setBtnState] = useState(false);
  //

  function fechar_modal_cart() {
    setBtnState((btnState) => false);
  }

  const VerAsInformacaoUser = async () => {
    VisitanteService.infoVisitante(logado).then((response) => {
      setInformacaoUser(response.data);
      setVerificado(response.data.is_verify);

      viewVisinteperfil(response.data.codigo);
      removerFoto(response.data.codigo);

      // console.log(response);
    });
    // .catch(() => {
    //   Swal.fire({
    //     title: "Erro em estabelecer a Conexão",
    //     icon: "error",
    //   });
    // });
  };

  useEffect(() => {
    VerAsInformacaoUser();
  }, []);

  //METODO LISTAR POR CODIGO IMAGEM IMAGEM
  const [Vervisitante, setVerVisitante] = useState({
    foto: "",
    visitante: {
      codigo: logado,
    },
  });

  const viewVisinteperfil = (id) => {
    UserPerfil.alterarFotoCodigo2(id).then((response) => {
      if (response.data) {
        setVerVisitante(response.data);
      } else {
        const dados = {
          foto: "../imgs/licitador.png",
          visitante: {
            codigo: logado,
          },
        };
        setVerVisitante(dados);
      }
    });
  };

  //METODO SALVAR
  const [foto, setFoto] = useState("");
  //
  const [fotoPefilUser, setFotoPefilUser] = useState({
    foto: "",
    visitante: {
      codigo: logado,
    },
  });

  //Limpar Campos
  const limpar = () => {
    setFotoPefilUser({
      foto: "",
    });
  };

  const VerNovaFoto = (e) => {
    setFoto(e.target.files[0]);
  };
  // Remover a imagem
  const removeSingleFile = () => {
    setFoto();
  };

  //Botao Salvar
  const saveUpdatefotoPefilUser = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("foto", foto);
    formData.append("visitante", logado);
    //
    await UserPerfil.alterarFoto(formData)
      .then((response) => {
        if (response.data === "saved") {
          Swal.fire({
            title: "Foto salvo com sucesso",
            icon: "success",
            confirmButtonColor: "#686d25",
          });
        }
        if (response.data === "visitante_existente") {
          console.log(response.data);
        }
        viewVisinteperfil(logado);
        limpar();
      })
      .catch((error) => {
        Swal.fire({
          title: "Erro de conexão",
          text: "Verifique a sua internet e tente novamente" + error,
          icon: "error",
          confirmButtonColor: "#686d25",
        });
      });
  };

  //
  //METODO APAGAR
  const removerFoto = () => {
    UserPerfil.deleteFotoVisitante(codigo)
      .then((response) => {
        console.log(response);
        console.log(response.data.codigo);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //
  return (
    <>
      <div className="card_filho">
        <div>
          <div className="foto-perfil">
            <div className="foto-user">
              {Vervisitante.foto === "../imgs/licitador.png" ? (
                <img className=" " src={licitadorima} alt="" />
              ) : (
                //
                <div className="tent">
                  <img
                    style={{ objectFit: "cover" }}
                    // src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirVisitanteFoto/${Vervisitante.foto}`}
                    src={`${Vervisitante.foto}`}
                  />
                </div>
              )}
              {/*  */}
            </div>
            {/*  */}
            <div className="nova-foto">
              <a href="#modal" id="pegar-nova-fotoPerfil"></a>
            </div>
          </div>

          <div className="dados_do_user">
            <h1>
              {VerInformacaoUser.nome} {VerInformacaoUser.sobrenome}
            </h1>
            <h3>E-mail: {VerInformacaoUser.email}</h3>
            <h3>Telefone: {VerInformacaoUser.telefone}</h3>
            <h3>Nº do BI: {VerInformacaoUser.bi}</h3>
            <h3>Genero: {VerInformacaoUser.gender}</h3>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <div className="modalDesp" id="modal" tabindex="-1">
        <div className="modal__contentDesp">
          <a
            href="#"
            onClick={fechar_modal_cart}
            className="modal__close"
            aria-label="Fechar"
          >
            x
          </a>
          {/* <!--Conteudo--> */}
          <div className="container">
            <div className="content">
              {/* <!--FORMULÁRIO DE CADASTRO--> */}
              <div id="cadastro">
                <form
                  encType="multipart/form-data"
                  onSubmit={(e) => saveUpdatefotoPefilUser(e)}
                >
                  <div className="cadastroP">
                    <label for="foto" id="pegar-nova-foto">
                      <h6 className="textoperfilFoto"> + Carregar a foto</h6>
                    </label>
                    <input
                      accept="image/*"
                      type="file"
                      id="foto"
                      onChange={(e) => VerNovaFoto(e)}
                      name="foto"
                      value={fotoPefilUser.foto}
                    />
                    {/*  */}
                    {foto && (
                      <div className="preview">
                        <img
                          className="image"
                          src={URL.createObjectURL(foto)}
                          alt="Thumb"
                        />
                        <button
                          onClick={removeSingleFile}
                          className="delete"
                        ></button>

                        <button type="submit" className="btnSalvar">
                          Salvar
                        </button>

                        {/* <button type="submit"
                          onClick={() => removerFoto(logado)}
                          className="btn btn-success"
                          style={{
                            border: "none",
                            outline: "none",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          Remover
                        </button> */}
                      </div>
                    )}
                    {/*  */}
                  </div>
                  {/* <!----> */}
                </form>
              </div>
              {/* <!--FIM DO FORMULÁRIO DE CADASTRO--> */}
            </div>
          </div>
          {/* <!--Fim de conteudo--> */}
        </div>
      </div>
      {/* FIM MODAL */}
    </>
  );
};

export default InfoUser;
