import api from "./api";

const getCooperadores = () => {
    return api.get("cooperadores");
};

const getPerfilCooperador = (codigo) => {
    return api.get(`cooperador/${codigo}`);
};

const getFazendaCooperador = (codigo) => {
    return api.get(`FazendaCooperador/${codigo}`);
};
const getIDCooperador = (userId) => {
    return api.get(`cooperadores/verificar/${userId}`);
};
const getIDVisitante = (userId) => {
    return api.get(`visitante/verificarVisitante/${userId}`);
};

const createCooperador = (data) => {
    return api.post("cadastrar_cooperador", data);  
  };

export default { getCooperadores, getPerfilCooperador, getFazendaCooperador, createCooperador,getIDCooperador, getIDVisitante }