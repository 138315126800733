import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import LanceService from "../../services/lance";
import Swal from "sweetalert2";
import Menu from "../../components/menu/NavBar";
import { AuthContext } from "../../contexts/Auth";
import LeilaoService from "../../services/leilao";

// const animal1 = require("../imgs/nullvaca32.jpg");
const licitadorima = require("../../components/imgs/licitador.png");

export function Lance() {
  const { user } = useContext(AuthContext);
  // ------------ Declarar as variaveis do sistema-----------------------

  const navigate = useNavigate();
  const [InfoItemLeilao, setInfoItemLeilao] = useState({});
  const [InfoItemLeilaolance, setInfoItemLeilaoLance] = useState([]);
  const [Licitadores, setLicitadores] = useState([]);
  const [ultimoValor, setultimoLance] = useState([]);
  const [VerLoteExistente, setVerLoteExistente] = useState([]);
  const [VerRaca, setVerRaca] = useState([]);
  const [VerFazendas, setVerFazendas] = useState([]);
  const [verpreco, setVerpreco] = useState([]);
  const [verDia, setVerDia] = useState([]);
  const [verHora, setVerHora] = useState([]);
  const [verMinuto, setVerMinuto] = useState([]);
  const [verSegundo, setVerSegundo] = useState([]);
  const [LeilaoCodigo, setLeilaoCodigo] = useState(0);
  const [VerAnimaisParaDArLance, setVerAnimaisParaDArLance] = useState({});

  const logado = user?.codigo;

  const minutosFinal = Math.floor((verSegundo % 3600) / 60);
  const segundosFinal = verSegundo % 60;

  // ------------ Executar as funções do sistema-----------------------

  const { codigo } = useParams();
  useEffect(() => {
    // if (totalTime === 0) {
    //   Swal.fire({
    //     title: "Este Leilão Terminou",
    //     text: "O vencedor Foi: huih21",
    //     icon: "info",
    //   });
    // } else {
    //   setTimeout(() => {
    //     // VerLicitador();
    //     // Ultimolance();
    //     // verLanceAtualizar();
    //     setTotalTime(totalTime - 1);
    //   }, 1000);
    // }
    VerLicitador();
    Ultimolance();
    verLance();
    VerPosicaoUser();
    VerificarPendenteParticipante();
    // VerAnimaisExistente();
  }, []);
  // -------------------------------------------------------------------------------

  //VERIFICAR USUARIO PEDENTE
  const VerificarPendenteParticipante = async () => {
    await LeilaoService.getVerificarParticipanteVisitanteExistente(logado, 1)
      .then((response) => {
        if (response.data !== "APROVADO") {
          // navigate("/leilao");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // ------------ Visualisar as Informações do animal a ser leiloado -----------------------

  const [VerLoteRapido, setVerLoteRapido] = useState({});
  const [VerLoteRapidoEspecie, setVerLoteRapidoEspecie] = useState({});

  const VerExistente = async () => {
    await LanceService.getLoteExistente(InfoItemLeilao.codigo).then(
      (response) => {
        setVerLoteExistente(response.data);
      }
    );
  };

  const [verApos, setVerApos] = useState(0);
  const VerPosicaoUser = async () => {
    await LanceService.getPostionUser(InfoItemLeilao.codigo).then(
      (response) => {
        for (var i = 0; i <= 3; i++) {
          // console.log(response?.data[i]?.visitante?.codigo)
          if (response.data[i].visitante.codigo === logado) {
            setVerApos(i + 1);
            return;
          } else {
            setVerApos(0);
          }
        }
      }
    );
  };

  // Descricao do animal lote
  const VerAnimaisDoLoteRapido = async (id) => {
    await LanceService.getLoteRapido(id).then((response) => {
      setVerLoteRapido(response.data[0]);

      setVerLoteRapidoEspecie(response.data[0].lote.especie);
    });
  };

  const VerRacasAnimaisDoLoteRapido = async (id) => {
    await LanceService.getRacaLoteRapido(id).then((response) => {
      setVerRaca(response.data);
    });
  };
  const verLance = async () => {
    await LanceService.getAnimalItem(codigo)
      .then((response) => {
        setVerAnimaisParaDArLance(response.data[0]);

        if (response.data.lote.geral) {
          VerAnimaisDoLoteRapido(response.data.lote.codigo);
          VerRacasAnimaisDoLoteRapido(response.data.lote.codigo);
        } else if (response.data.lote.existente) {
          VerExistente(response.data.lote.codigo);
        }

        setInfoItemLeilao(response.data.lote);
        console.log("Informacao leilao: " + response.data);
        setInfoItemLeilaoLance(response.data);
        setVerpreco(response.data.preco);
        setVerFazendas(response.data.lote.fazenda);
        setVerDia(response.data.dia);
        setLeilaoCodigo(response.data.leilao.codigo);
        setVerHora(response.data.hora);
        setVerMinuto(response.data.minuto);
        setVerSegundo(response.data.segundo);

        setTimeout(verLance, 1);
      })
      .catch((err) => {
        //Swal.fire({
        //  title: "Erro",
        //icon: "error"
        //})
        // navigate("/leilao");
      });
  };
  const verLanceAtualizar = async () => {
    await LanceService.getAnimalItem(codigo)
      .then((response) => {
        setVerpreco(response.data[0].preco);
        setVerDia(response.data[0].dia);
        setVerHora(response.data[0].hora);
        setVerMinuto(response.data[0].minuto);
        setVerSegundo(response.data[0].segundo);
      })
      .catch((err) => {
        //Swal.fire({
        //  title: "Erro",
        //icon: "error"
        //})
        // navigate("/leilao");
      });
  };
  var val = verpreco.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  // -------------------------------------------------------------------------------

  // --------- Ver Lista de Licitadores---------------------

  const VerLicitador = async () => {
    await LanceService.getLicitadores(codigo).then((response) => {
      setLicitadores(response.data);
      const pos_final = response.data;
      // const ver_pos = pos_final.findIndex(0)
      // console.log(ver_pos)

      pos_final.forEach((pos, index) => {
        // console.log(pos.visitante.codigo + " = " + user?.codigo)
        if (pos.visitante.codigo === logado) {
          // console.log("Posição: " + logado + " id: " + pos.visitante.codigo);
        } else {
          // console.log("A sua Posição é: 0 " + logado);
        }
      });
      setTimeout(VerLicitador, 1);
    });
  };

  // ------------------ Pegar o valor do ultimo Lance ----------------------------
  const Ultimolance = async () => {
    await LanceService.getUltimoLance(codigo)
      .then((response) => {
        setultimoLance(response.data);
      })
      .catch((err) => {
        setultimoLance(0);
      });
  };

  const lance_inicial_valor = [
    verpreco + verpreco * 0.1,
    verpreco + verpreco * 0.2,
    verpreco + verpreco * 0.3,
    verpreco + verpreco * 0.4,
    verpreco + verpreco * 0.5,
  ];

  const valores = [
    ultimoValor + ultimoValor * 0.1,
    ultimoValor + ultimoValor * 0.2,
    ultimoValor + ultimoValor * 0.3,
    ultimoValor + ultimoValor * 0.4,
    ultimoValor + ultimoValor * 0.5,
  ];
  // -------------------------------------------------------------------------------

  // Determinar a posição
  const array1 = [7, 12, 7, 130, 44, 130];

  const found = array1.findIndex((element) => element === 7);
  array1.splice(2, 1);
  const novofound = found;

  // ------------------------------------------------

  const [valorDoLance, setvalorDoLance] = useState(0);
  const onInputChange = (e) => {
    setvalorDoLance(e.target.value);
  };

  // ---------Ver Posoção do Usuário ------------------------
  const ver_pos = (pos) => {
    return pos.visitante.codigo === logado;
  };
  const posicao_final = Licitadores.findIndex(ver_pos);
  //   if (posicao_final === 0) {
  //     Licitadores.splice(2, 1);
  //   }
  //   if (posicao_final === 1) {
  //     Licitadores.splice(2, 1);
  //   }
  // ------------------------------------------
  const darLance = async (e) => {
    e.preventDefault();

    function ver_user(user) {
      return user.visitante.codigo === logado;
    }
    if (Licitadores.findIndex(ver_user) === 0) {
      Swal.fire({
        title: "Já esta em primero",
        text: "Não pode dar um lance maior porque já está em 1º Lugar",
        icon: "info",
      });
      return;
    }

    if (valorDoLance === 0) {
      Swal.fire({
        title: "Adicione um valor",
        icon: "info",
      });
      return;
    }
    if (ultimoValor === valorDoLance) {
      Swal.fire({
        title: "Este Valor Já foi adicionado",
        text: "Não pode dar um lance com o mesmo valor",
        icon: "info",
      });
      return;
    }
    await Swal.fire({
      title: "Deseja dar lance neste animal?",
      text:
        "Vais dar um lance com um valor de " +
        valorDoLance.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        }) +
        "Kz",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, dar lance",
    }).then((result) => {
      if (result.isConfirmed) {
        const datas = {
          leilaoItem: {
            codigo: codigo,
          },
          leilao: {
            codigo: LeilaoCodigo,
          },
          visitante: {
            codigo: logado,
          },
          valorLance: valorDoLance,
        };
        LanceService.postLance(datas)
          // LanceService.postLance(darOLance)
          .then((response) => {
            // console.log(response.data)
            if (response.data === "lance_present") {
              Swal.fire({
                title: "Este Lance Já existe",
                text: "Você já deu um lance com o mesmo valor",
                icon: "info",
              });
            }
            if (response.data === "presente") {
              Swal.fire({
                title: "Estou presente",
                text: "Você já deu um lance com o mesmo valor",
                icon: "info",
              });
            }
            if (response.data === "Lance_Max_is_active") {
              Swal.fire({
                title: "Já esta em primero",
                text: "Não pode dar um lance maior porque já está em 1º Lugar",
                icon: "info",
              });
              return;
            }
            if (response.data === "lance_max") {
              Swal.fire({
                title: "Já esta em primero",
                text: "Não pode dar um lance maior porque já está em 1º Lugar",
                icon: "info",
              });
              return;
            }
            if (response.data === "NoInit") {
              Swal.fire({
                title: "Leilao não iniciado!",
                text: "O Leilao não iniciou, Por favor faça a sua inscrição e aguarde a resposta da Cooperativa!",
                icon: "info",
              });
              return;
            }
            if (response.data === "NoExist") {
              Swal.fire({
                title: "Inscreva-se neste leilão para poder participar!",
                text: "É necessário fazer a submisão dos seu dados para poder participar, por favor inscreva-se.",
                icon: "info",
              });
              return;
            }
            if (response.data === "REPROVADO") {
              Swal.fire({
                title: "Inscrição Reprovada!",
                text: "Você não poderá fazer inscrições pois o seu pedido foi reprovado, visite os termos de participação de Leilao online para melhor esclarecimento.",
                icon: "warning",
              });
              navigate("LeilaoItem");
              return;
            }
            if (response.data === "salvo") {
              Swal.fire({
                title: "Lance Enviado",
                icon: "success",
              });
              VerLicitador();
              Ultimolance();
            } else {
              Swal.fire({
                title: "Oops!",
                text: "Ocooreu um erro " + response.data,
                icon: "warning",
              });
              VerLicitador();
              Ultimolance();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Erro de Conexão" + err,
              text: "Verifique a sua internet",
              icon: "error",
            });
            VerLicitador();
            Ultimolance();
          });
      }
    });
  };

  //-------------------------------------------- {minutos.toString().padStart(2, "0")}:{segundos.toString().padStart(2, "0")}
  return (
    <>
      <Menu />
      {/* <div id="borda_img_p"></div> */}
      <section
        className="fundo_entrar"
        style={{
          backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${VerFazendas.logo})`,
          boxShadow: "0 0 10px rgba(0, 0, 0, .3)",
        }}
      >
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder" id="nome-leilao-fazenda_lote">
            Leilão da {VerFazendas.nome} - {InfoItemLeilao.nome}
          </h1>
          <h2 id="tempo-restante">
            {/* {InfoItemLeilaolance.init === true ? (
              <span>
                Tempo Restante: {InfoItemLeilaolance.minuto}:
                {InfoItemLeilaolance.segundo}
                <div className="duracao"> 
                  <span>Duração {verHora}h</span>
                </div>
              </span>
            ) : (
              <span>Leilão ainda não inciou</span>
            )} */}

            {/*  */}
            {InfoItemLeilaolance.init === true ? (
              <span className="tamanho-bom">
                Tempo Restante: {InfoItemLeilaolance.horarestante}:
                {InfoItemLeilaolance.minuto}:{InfoItemLeilaolance.segundo}
                <div className="duracao">
                  <span>Duração {verHora}h</span>
                </div>
              </span>
            ) : (
              <div>
                {InfoItemLeilaolance.terminado === true ? (
                  <span> Leilao Terminado</span>
                ) : (
                  <span> O leilão ainda não inciou</span>
                )}
              </div>
            )}

            {/*  */}
          </h2>
        </div>
      </section>

      <section className="corpo f_corpo">
        {/*  */}
        {/* <Link to={`/LeilaoItem/${codigo[0]}`}>Voltar</Link> POR LINK VOLTAR */}
        {/*  */}
        <div className="fotografiaAnimal">
          <img
            className="img-animal"
            src={
              `https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemAnimal/` +
              InfoItemLeilao.imgUrl
            }
            alt=""
          />
        </div>

        {InfoItemLeilao.geral === true ? (
          <div
            className="conteudo"
            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, .3)" }}
          >
            <h2 id="detalhes-animal" style={{ textAlign: "center" }}>
              {VerLoteRapidoEspecie.especieAnimal}
            </h2>
            <div
              className="legenda_do_lance"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <p>
                    Idade Minima:{" "}
                    <strong>{VerLoteRapido.idademin} meses</strong>
                  </p>
                  <p>
                    Idade Maximo:{" "}
                    <strong>{VerLoteRapido.idademax} meses</strong>
                  </p>
                  <p>
                    Média de Idade:{" "}
                    <strong>
                      {(VerLoteRapido.idademax + VerLoteRapido.idademin) / 2}{" "}
                      meses
                    </strong>
                  </p>
                  <p>
                    {VerLoteRapido.numerosexoF === 1 ? (
                      <strong>{VerLoteRapido.numerosexoF} Femea</strong>
                    ) : (
                      <strong>{VerLoteRapido.numerosexoF} Femeas</strong>
                    )}
                  </p>
                  <p></p>
                </div>
                <div>
                  <p>
                    Peso Minimo: <strong>{VerLoteRapido.pesomin}Kg</strong>
                  </p>
                  <p>
                    Peso Maximo: <strong>{VerLoteRapido.pesomaxi}Kg</strong>
                  </p>
                  <p>
                    Media de Pesos:{" "}
                    <strong>
                      {(VerLoteRapido.pesomaxi + VerLoteRapido.pesomin) / 2}Kg
                    </strong>
                  </p>
                  <p>
                    {VerLoteRapido.numerosexoM === 1 ? (
                      <strong>{VerLoteRapido.numerosexoM} Macho</strong>
                    ) : (
                      <strong>{VerLoteRapido.numerosexoM} Machos</strong>
                    )}
                  </p>
                </div>
              </div>
              <div className="row">
                {VerRaca.map((rash, index) => (
                  <span
                    style={{
                      marginLeft: "20px",
                      padding: "5px",
                      border: "1px solid white",
                      width: "70px",
                      borderRadius: "8px",
                      backgroundColor: "#9ba236",
                    }}
                  >
                    {rash.raca.racaAnimal}
                  </span>
                ))}
              </div>
              <div className="valor-preco-inicial-leilao">
                <p>
                  Preço inicial: <strong>{val} Kz</strong>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {InfoItemLeilao.existente === true ? (
          <div
            className="conteudo"
            style={{
              boxShadow: "0 0 10px rgba(0, 0, 0, .3)",
              height: "auto",
              scrollbarWidth: "auto",
            }}
          >
            <h2 style={{ textAlign: "center" }}>
              {VerLoteRapidoEspecie.especieAnimal}
            </h2>
            <div
              className="legenda_do_lance"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h2>Itens do Lote</h2>

                  {VerLoteExistente.map((existentes) => (
                    <div key={existentes.codigo}>
                      <div
                        style={{
                          background: "#9ba236",
                          width: "100%",
                          height: "100%",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          className="img-animal"
                          src={
                            `https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemAnimal/` +
                            existentes.animal.imgUrl
                          }
                          // src={
                          //   existentes.animal.imgUrl
                          // }
                          style={{
                            width: "95px",
                            height: "95px",
                            position: "absolute",
                          }}
                          alt=""
                        />

                        <div style={{ marginLeft: "100px" }}>
                          <p>
                            Raça:{" "}
                            <strong>{existentes.animal.raca.racaAnimal}</strong>
                          </p>
                          <p>
                            Peso: <strong>{existentes.animal.peso} Kg</strong>
                          </p>
                          <p>
                            Sexo:{" "}
                            <strong>{existentes.animal.sexoAnimal}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#9ba236",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <p>
                  Preço inicial: <strong>{val} Kz</strong>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
      <section className="corpo">
        <div className="tabela dar-m">
          <div className="top">
            {/* <h3>{verApos}</h3> */}
            {posicao_final === -1 ? (
              <h3></h3>
            ) : (
              <h3 style={{ color: "#fff" }}>
                Sua Posição: {posicao_final + 1}º
              </h3>
            )}
            <h1 id="txt-licitadores">LICITADORES </h1>
            <div></div>
          </div>
          {InfoItemLeilaolance.terminado ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                className="sugestao-para-licitadores"
                style={{ transform: "translateY(-30px)" }}
              >
                {!InfoItemLeilaolance.visitanteVencedor ? (
                  <div>Leilão terminado, não teve vencedor</div>
                ) : InfoItemLeilaolance.visitanteVencedor?.codigo === logado ? (
                  <div>
                    Leilão terminado, Parabéns{" "}
                    <strong>
                      {InfoItemLeilaolance.visitanteVencedor?.nome}
                    </strong>{" "}
                    você foi o vencedor, com o valor de <strong>{ultimoValor}Kz</strong>
                  </div>
                ) : (
                  <div>
                    Leilão terminado, o vencedor foi{" "}
                    <strong>
                      {InfoItemLeilaolance.visitanteVencedor?.visitanteid}
                    </strong>{" "}
                    com o valor de <strong>{ultimoValor}Kz</strong>
                  </div>
                )}
              </h1>
            </div>
          ) : ultimoValor === 0 ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 className="sugestao-para-licitadores" style={{textAlign: "center"}}>
                Seja o primeiro a dar um Lance
              </h1>
            </div>
          ) : (
            <div>
              <div>
                {Licitadores.map((licitador, index) => (
                  <div className="hancking" key={licitador.codigo}>
                    <div className="hacking_img">
                      <img
                        src={licitadorima}
                        alt="Licitador"
                        className="foto-licitador"
                      />
                    </div>
                    <div
                      className={`nome-licitador ${
                        index === posicao_final ? "borda_primeiro" : ""
                      }`}
                    >
                      <p>
                        {index + 1}º Posição:{" "}
                        <strong>{licitador.visitante.visitanteid}</strong>
                      </p>
                    </div>
                    <div
                      className={`valor-licitador ${
                        index === 0 ? "cor_primeiro" : ""
                      }`}
                    >
                      <p
                        className={`${
                          index === posicao_final
                            ? "cor_verde_texto_primeiro"
                            : ""
                        }`}
                      >
                        {licitador.valorLance.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        Kz
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/*  */}

          {/*  */}
        </div>
      </section>
      {InfoItemLeilaolance.init && !InfoItemLeilaolance.terminado ? (
        <section className="lance">
          <form onSubmit={(e) => darLance(e)}>
            <div className="card-lance">
              <div className="barra-card-lance">
                <p>DAR UM LANCE</p>
              </div>
              {ultimoValor === 0 ? (
                <p>
                  <select
                    className="select_post_lance"
                    onChange={(e) => onInputChange(e)}
                    name="valorLance"
                  >
                    <option value="0">Selecione o Valor</option>
                    {lance_inicial_valor.map((valorI) => (
                      <option value={valorI}>
                        {valorI.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        Kz
                      </option>
                    ))}
                  </select>
                </p>
              ) : (
                <p>
                  <select
                    className="select_post_lance"
                    onChange={(e) => onInputChange(e)}
                    name="valorLance"
                  >
                    <option
                      value={
                        parseFloat(ultimoValor + ultimoValor * 0.1).toFixed(2) *
                        0
                      }
                    >
                      Selecione o Valor
                    </option>
                    {valores.map((valor, index) => (
                      <option value={valor} key={index}>
                        {valor.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        Kz
                      </option>
                    ))}
                  </select>
                </p>
              )}

              {/* <input type="number" className="input-lance" placeholder="VALOR :" /><br /> */}
              <div className="baixo_btn">
                <div></div>
                <div className="os_btn_lance">
                  <button className="btn-lance" type="submit">
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
export default Lance;
