import Menu from "../../components/menu/NavBar";
import { useContext} from "react";
import { AuthContext } from "../../contexts/Auth";
import { Link } from "react-router-dom";
const Confirmacao = () => {
  const { user } = useContext(AuthContext);
  return (
    <>
    <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">Comprovativo enviado</h1>
        </div>
      </section>

      <div className="">
        <div className="pagamento_confirmacao">
          <div>
            <div className="paga_final_1">
              <h1 style={{ color: "#fff" }}>O seu comprovativo foi enviado com sucesso</h1>
              <h2
                style={{
                  margin: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {user?.nome}, recebemos o seu compravativo, mais tarde entraremos em contacto consigo, pode verificar o estado da sua compra na sua página de perfil
              </h2>
              <p
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <label for="arquivo" id="ver_pdf_label">
                  <Link
                    to="/perfil"
                    style={{
                      backgroundColor: "#B46739",
                      padding: "10px",
                      border: "none",
                      fontSize: "15px",
                      outline: "none",
                      borderRadius: "6px",
                      color: "#fff",
                      fontWeight: "bold",
                      cursor: "pointer"
                    }}
                  >
                    Ver compras
                  </Link>
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Confirmacao;
