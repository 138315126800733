import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2"
import CooperadorService from "../../services/cooperador";




const UploadFotoPerfil = () => {
    // Sobre o botão
    const { userId } = useParams();
    const navigator = useNavigate();
    const [VerCooperador, setVerCooperador] = useState({
        imgUrl: "",
        nome: "",
        email: "",
        endereco: "",
        is_verify: "",
    });

    const dados = () => {
        if (userId) {
            CooperadorService.getIDCooperador(userId).then((response) => {
                setVerCooperador(response.data);
            });
        }
    };



    console.log(userId);
    const verificar = async (userId) => {
        await CooperadorService.getIDCooperador(userId)
            .then((response) => {
                Swal.fire({
                    width: 300,
                    icon: 'success',
                    text: 'Conta Verificada com sucesso ',
                    showConfirmButton: true,
                })
                navigator("/login")
            })
            .catch((error) => {
                Swal.fire({
                    width: 300,
                    icon: 'error',
                    text: 'Erro de Conexão ',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    };

    const icon = require("../imgs/logo01.png");
    const [btnState, setBtnState] = useState(true)
    const [btnVerif, setbtnVerif] = useState()


    let toggleClassCheck = btnState ? 'active_cart' : ""
    let toggleClassFilho = btnState ? 'produtos_da_compra_cart_filho_animation' : ""
    return (
        <>
            {/* <div className={`ver_compras_cart`} id="ver_compras_cart"><img src={icon} alt="não encontrado" /></div> */}
            <div className={`produtos_da_compra_cart ${toggleClassCheck}`}>
                <div className={`produtos_da_compra_cart_filho animate__animated animate__fadeInDownBig ${toggleClassFilho}`}>
                    <div>
                        <h1>Bem vindo a  C </h1>
                        <h2 className="title has-text-centered is-4 m-2" style={{ borderBottom: "1px solid #B46739", paddingBottom: "10px" }}>
                        </h2>
                    </div>
                    {VerCooperador.is_verify ?
                        <div className="maio_verificar_column">
                            <div>
                                <h1>Olá {VerCooperador.nome}, por favor verifique a sua conta</h1>
                            </div>
                            <div>
                                <button className="botao_verficar_user" onClick={() => verificar(userId)}> Verificar conta</button>
                            </div>
                        </div>
                        :
                        <div className="maio_verificar_column">
                            <div>
                                <h1>Adcione uma foto de Perfil</h1>
                            </div>
                            <div className="center"> 
                                    <label className="fotoperfil" for="fotoperfil">
                                         <input className="botao_verficar_bi" id="fotoperfil" type="file" name="fotoperfil" required/>
                                    </label>
                            </div>
                            <Link to="/uploadinf"> 
                                     <button className="submitbi">Seguinte</button>
                            </Link>
                            <Link to="/"> 
                                     <button className="pular">Pular</button>
                            </Link>
                        </div>
                    }


                </div>
            </div>

        </>
    )
}

export default UploadFotoPerfil
