import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import "./styles.css";
import { Link } from "react-router-dom";
import leilaoExclusivoService from "../../services/leilaoExclusivo";
import LeilaoService from "../../services/leilao";
import Menu from "../../components/menu/NavBar";

export function LeilaoExclusivo() {
  const [Leilao, setLeilao] = useState([]);
  const [VerItemExclusivo, setVerItemExclusivo] = useState([]);
  const [totalTime, setTotalTime] = useState([60 * 60]);

  const minutos = Math.floor(totalTime / 60);
  const segundos = totalTime % 60;

  useEffect(() => {
    if (totalTime === 0) {
      alert("O tempo acabou");
    } else {
      setTimeout(() => {
        // VerLeilao();
        setTotalTime(totalTime - 1);
      }, 1000);
    }
    VerLeilao();
  }, [totalTime]);

  // Pegar os animais
  const VerLeilao = async () => {
    await leilaoExclusivoService.getLeilaoExclusivo().then((response) => {
      setLeilao(response.data[0]);
      // setLeilao(response.data);
      // VerItemsLeilaoExclusivo(response.data[0].codigo);
      VerItemsLeilaoExclusivo(response.data[0].codigo);
      console.log(response.data);
    });
  };
  const VerItemsLeilaoExclusivo = (codigo) => {
    leilaoExclusivoService.getItemLeilaoExclusivo(codigo).then((response) => {
      setVerItemExclusivo(response.data);
    });
  };

  const logo = require("../../components/imgs/logo.png");

  return (
    <>
      <Menu />
      <div id="borda_img_p"></div>
      <section className="fundo_entrar">
        <div className="welcome-leilao">
          <h1 className="title has-text-centeder">
            Seja Bem Vindo ao Leilão Exclusivo da CWAM
          </h1>
        </div>
      </section>

      <section id="video">
        {/* <section> */}
        {/* <div
        // style={{ , margin: "20px", marginBottom: "70px"}}
        > */}
        <div className="player-wrapper">
 
            <ReactPlayer
              className="video"
              url={Leilao.link}
              playing
              controls
              playbackRate={2}
            />
    

          {/* <h1 style={{ color:"white" }}>Video</h1> */}
        </div>

        <div className="img-fundo-leilao">
          <h1>Leilão de {Leilao.dataLeilaoEx}</h1>
          <tr>
            <h1>
              <img src={logo} className="img_logo" alt="Imagem de logo" />
            </h1>
          </tr>
        </div>
        {/* </div> */}
      </section>
      <h1
        style={{
          color: "#fff",
          textAlign: "center",
          fontSize: "30px",
          margin: "20px 0",
        }}
      ></h1>

      {/* <section className="animais">
        <div className="m-2 ani_p">
          {VerItemExclusivo.map((ver) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${ver.lote.imgUrl})`,
                  // backgroundImage: `url(${ver.lote.imgUrl})`,
                  borderRadius: "20px",
                }}
              ></div>

              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {" "}
                  {ver.lote.quantidade} Animais da Especie{" "}
                  {ver.lote.especie.especieAnimal}
                </h2>
                <table>
                  <tr>
                    <td>Origem: </td>
                    <td>{ver.lote.fazenda.nome}</td>
                  </tr>
                  <tr>
                    <td>Valor Inicial:</td>
                    <td>{ver.preco}Kz</td>
                  </tr>
                </table>

                <Link
                  to={`/lanceExclusivo/${ver.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Dar Lance
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section> */}
     <section className="animais">
        <div className="m-2 ani_p">
          {VerItemExclusivo.map((ver) => (
            <div className="am_p">
              <div
                className="img_do_animal"
                style={{
                  backgroundImage: `url(https://webcwam-polished-dust-7716.fly.dev/api//exibirImagemCooperador/${ver.lote.imgUrl})`,
                  // backgroundImage: `url(${ver.lote.imgUrl})`,
                  borderRadius: "20px",
                }}
              ></div>

              <div className="mi">
                <h2
                  className="title has-text-centered is-4 m-2"
                  style={{
                    borderBottom: "1px solid #B46739",
                    paddingBottom: "10px",
                  }}
                >
                  {" "}
                  {ver.lote.quantidade} Animais da Especie{" "}
                  {ver.lote.especie.especieAnimal}
                </h2>
                <table>
                  <tr>
                    <td>Origem: </td>
                    <td>{ver.lote.fazenda.nome}</td>
                  </tr>
                  <tr>
                    <td>Valor Inicial:</td>
                    <td>{ver.preco}Kz</td>
                  </tr>
                </table>

                <Link
                  to={`/lanceExclusivo/${ver.codigo}`}
                  className="button botao_para_saber_mais is-link"
                  style={{ background: "#9ba236" }}
                >
                  Dar Lance
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>




  
    </>
  );
}

export default LeilaoExclusivo;
