import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
    // baseURL: "http://192.168.8.189:8080/api/",
    baseURL: "https://webcwam-polished-dust-7716.fly.dev/api/",
});

api.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

api.interceptors.request.use(async (config) => {
    const accessToken = getToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
    }
  
    return config;
  });

export default api;
