import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./components/home";
import LeilaoOnline from "./pages/leilao/LeilaoOnline";
import Animais from "./pages/animais/animais";
import { About } from "./pages/sobre";
import Login from "./components/login";
import { ForgetPassword } from "./components/forget_password";
import { ForgetPasswordPin } from "./pages/ForgetPasswordPin";
import { Cadastro } from "./components/cadastro";
import Cooperador from "./pages/cooperador/cooperador";
import TesteSo from "./pages/cooperador/teste";
import Fazendas from "./pages/fazendas/fazendas";
import Venda from "./pages/vendas/venda";
import Servicos from "./pages/servicos/servicos";
import Agenda from "./pages/agenda/agenda";
import SobreAnimais from "./pages/animais/sobre/SobreAnimais";
import Sobre_compra from "./pages/vendas/sobre_compras/sobre_compra";
import Sobre_produto from "./pages/vendas/sobre_compras/sobre_produto";
import Finalizar from "./pages/vendas/finalizar";
import Pagamentos from "./pages/pagamentos/pagamentos";
import Confirmacao from "./pages/pagamentos/Confirmacao";
import PerfilUser from "./pages/perfil/PerfilUser";
import PerfilUserComprasInfo from "./pages/perfil/PerfilUserComprasInfo";
import PerfilUserComprasInfoItems from "./pages/perfil/PerfilUserComprasInfoItems";
import ComprasUserInfoItemsProdutos from "./pages/perfil/PerfilUserComprasInfoItemsProdutos";
import PerfilUserLeilao from "./pages/perfil/PerfilUserLeilao";
import PerfilUserDados from "./pages/perfil/PerfilUserDados";
import TabCooperador from "./components/TabCooperador";
import Leilaoitem from "./pages/leilao/LeilaoItem";
import Lance from "./pages/lance/Lance";
import LanceExclusivo from "./pages/lanceExclusivo/LanceExclusivo";
import SobreFazneda from "./pages/fazendas/perfil/sobre_fazenda";
import SobreCooperador from "./pages/cooperador/perfil/sobre_cooperador";
import LeilaoExclusivo from "./pages/leilao/LeilaoExclusivo";
import LeilaoItemExclusivo from "./pages/leilao/LeilaoItemExclusivo";
import DestinoUser from "./pages/entregas/Destino";
import TransporteUser from "./pages/entregas/Transporte";
import EntregaFinalUser from "./pages/entregas/PaiEntrega";
import Verification from "./components/verificacao";
import VerificationCooperador from "./pages/verificao/visitante";
import UploadBi from "./components/verificacao/uploadBI";
import UploadFotoPerfil from "./components/verificacao/uploadfotoperfil";
import UploadInf from "./components/verificacao/uploadinf";
import { Visitante } from "./pages/visitante/visitante";
import { AuthProvider, AuthContext } from "./contexts/Auth";
import LanceExistente from "./pages/lance/LanceExistente";
import Noticias from "./pages/noticias/Noticias";
import ViewNoticias from "./pages/noticias/ViewNoticias";
import ServicoNoticia from "./pages/servicos/ServicoNoticia";
import Termos from "./components/Termos/termos";
import Politicas from "./components/Termos/politicas";
import VerificarEmail from "./pages/verificarEmail/VerificarEmail";


export function AppRoutes() {
  const Private = ({ children }) => {
    const { isAuthenticated, loading } = useContext(AuthContext);

    if (loading) {
      return (
        <div id="inicio-loader">
          <div className="loader"></div>
          <div className="loading">
            <div className="bola-0">C</div>
            <div className="bola-1">W</div>
            <div className="bola-2">A</div>
            <div className="bola-1">M</div>c
          </div>
        </div>
      );
    }

    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <div>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contactos" />
            <Route
              path="/leilao"
              element={
                <Private>
                  <LeilaoOnline />
                </Private>
              }
            />
            <Route path="/animais" element={<Animais />} />
            <Route path="/sobre" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/cooperadores" element={<Cooperador />} />
            <Route path="/fazendas" element={<Fazendas />} />
            <Route path="/visitante" element={<Visitante />} />
            <Route path="/venda" element={<Venda />} />
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route path="/forgetPin" element={<ForgetPasswordPin />} />
            <Route path="/servicos" element={<Servicos />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/lance/:codigo" element={<Lance />} />
            <Route
              path="/lanceExclusivo/:codigo"
              element={<LanceExclusivo />}
            />

            <Route path="/sobreAnimais/:codigo" element={<SobreAnimais />} />
            <Route path="/sobre_compra/:codigo" element={<Sobre_compra />} />
            <Route path="/sobre_produto/:codigo" element={<Sobre_produto />} />

            <Route path="/finalizar_compra" element={<Finalizar />} />
            {/* <Route path="/entregas" element={<Entregas />} /> */}
            <Route path="/pagamentos" element={<Pagamentos />} />
            <Route path="/confirmacao" element={<Confirmacao />} />
            <Route path="/perfil" element={<PerfilUser />} />
            <Route
              path="/perfilcomprainfo/:global"
              element={<PerfilUserComprasInfo />}
            />
            <Route
              path="/perfilcomprainfoItems/:lote"
              element={<PerfilUserComprasInfoItems />}
            />
            <Route
              path="/perfilComprasInfoItemsProdutos/:lote"
              element={<ComprasUserInfoItemsProdutos />}
            />
            <Route path="/perfilLeilao" element={<PerfilUserLeilao />} />
            <Route path="/perfilDados" element={<PerfilUserDados />} />
            <Route path="/tab" element={<TabCooperador />} />
            {/* <Route path="/leilaoitem/:codigo" element={<Leilaoitem />} /> */}
            <Route
              path="/leilaoitem/:codigo"
              element={
                <Private>
                  <Leilaoitem />
                </Private>
              }
            />
            <Route
              path="/leilaoitem_exclusivo/:codigo"
              element={<LeilaoItemExclusivo />}
            />
            <Route path="/sobre_fazenda/:codigo" element={<SobreFazneda />} />
            <Route
              path="/sobre_cooperador/:codigo"
              element={<SobreCooperador />}
            />
            <Route
              path="/leilao_exclusivo"
              element={
                <Private>
                  <LeilaoExclusivo />
                </Private>
              }
            />
            <Route path="/destino" element={<DestinoUser />} />
            <Route path="/transporte" element={<TransporteUser />} />
            <Route path="/entrega_final" element={<EntregaFinalUser />} />
            <Route path="/verification/:userId" element={<Verification />} />
            
            <Route
              path="/verificationCooperador/:userId"
              element={<VerificationCooperador />}
            />
            <Route path="/uploadbi" element={<UploadBi />} />
            <Route path="/uploadfoto" element={<UploadFotoPerfil />} />
            <Route path="/uploadinf" element={<UploadInf />} />
            <Route path="/LanceExistente" element={<LanceExistente />} />
            <Route path="/noticias" element={<Noticias />} />
            {/* <Route path="/viewnoticias/:codigo" element={<ViewNoticias />} /> */}
            <Route path="/viewnoticias/:codigo" element={<ViewNoticias />} />
            {/* <Route path="/leilao" element={<LeilaoNoticia />} /> */}
            <Route path="/servicos" element={<ServicoNoticia />} />
            <Route path="/termosdeultilizacao" element={<Termos />} />
            <Route path="/teste" element={<TesteSo />} />
            <Route path="/Politicas" element={<Politicas />} />
            <Route path="/Politicas" element={<VerificarEmail />} />
          </Routes>
        </AuthProvider>
        <Footer />
      </Router>
    </div>
  );
}

export default AppRoutes;
