import React, { useState, useEffect, useContext } from "react";
import {ExibirComprovativo} from "../../../services/ComprasService"
const BotaoComprvativo = (props) => {

    useEffect(()=>{
        getComprovativo()
    })

    const [VerComprovativo, setVerComprovativo] = useState({})
    const getComprovativo = async() =>{
        await ExibirComprovativo(props.global)
        .then((response)=> {
            setVerComprovativo(response.data[0])
        })
        .catch((error)=>{
            console.log(error.response)
        })
    }

  return (
    <>
      <div>
        <a href="#modalcomprovativo" className="btn_ver_comp_user_comprovativo">Ver Comprovativo</a>
      </div>
      {/* MODAL */}
      <div className="modalDesp" id="modalcomprovativo" tabindex="-1">
        <div className="modal__contentDesp2">
          <a
            href="#"
            // onClick={fechar_modal_cart}
            className="modal__close"
            aria-label="Fechar"
          >
            x
          </a>
          {/* <!--Conteudo--> */}
          <div className="container">
            <div className="content" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
              {/* <!--FORMULÁRIO DE CADASTRO--> */}
                <h1 style={{textAlign: "center"}}>Comprovativo da compra</h1>
                <iframe style={{margin: "auto"}} src={`${VerComprovativo.pdf}`} width="100%" height="500px"></iframe>
              {/* <!--FIM DO FORMULÁRIO DE CADASTRO--> */}
            </div>
          </div>
          {/* <!--Fim de conteudo--> */}
        </div>
      </div>
      {/* FIM MODAL */}
    </>
  );
};

export default BotaoComprvativo;
