import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import * as yup from "yup";
import Menu from "./menu/NavBar";

const Login = () => {
  const { authenticated, login, user } = useContext(AuthContext);

  const [values, setValues] = useState({
    email: "",
    senha: "",
  });

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  const handleChange = ({ currentTarget: input }) => {
    const { name, value } = input;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(await validate())) return;
    login(values);
  };

  //VAlidacao
  async function validate() {
    let schema = yup.object().shape({
      email: yup
        .string("O campo email é obrigatório!")
        .required("O campo email é obrigatório!")
        .email("e-mail inválido!"),
        
      senha: yup
        .string("O campo senha é obrigatório!")
        .required("O campo senha é obrigatório!")
    });
    try {
      await schema.validate(values);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  return (
    <>
      <Menu />
      <section id="section" className="verde sobre borda_img_log ">
        <div className="login_texto">
          <div className="p_s is-flex">
            <div className="login">
              <form onSubmit={handleSubmit}>
                <div className="s_cima is-flex justify-space-between">
                  <div></div>
                  {/* <div>x</div> */}
                </div>
                {status.type === "error" ? (
                  <p className="esconder" style={{ color: "##AD0406", marginLeft: "10px" }}>{status.mensagem}</p>
                ) : (
                  ""
                )}
                <div className="s_meio">
                  <p className="s_pp is-flex">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      // value={email}
                      onChange={handleChange}
                      minLength={2}
                      maxLength={60}
                    />
                  </p>
                  <p className="s_pp is-flex">
                    <input
                      type="password"
                      placeholder="Senha"
                      name="senha"
                      // value={senha}
                      onChange={handleChange}
                   //   minLength={6}
                     // maxLength={30}
                    />
                  </p>
                  <p style={{ marginTop: "10px" }}>
                    <Link to="/forget_password">
                      <span style={{ color: "#fff" }}>Esqueceu a sua senha?</span>
                    </Link>
                  </p>
                </div>
                <div className="s_bot is-flex">
                  <div></div>
                  <button className="s_btn" type="submit">
                    Entrar
                  </button>
                </div>
              </form>
            </div>
            <div className="importante">
              <div className="i_cima">
                <div className="text-imp">Cadastre-se como visitante</div>
              </div>
              <div className="i_meio">
                <p>Olá seja bem vindo a CWAM, se ainda não possui uma conta, crie uma como visitante.
                </p>
              </div> 
              <div className="s_bot is-flex">
                <div></div>{" "}
                <div> 
                  <Link to="/visitante">
                    <button className="s_btn">Criar conta</button>
                  </Link>
                  {/* <Link to="/cadastro">
                    <button className="s_btn">Cooperador</button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
