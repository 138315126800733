import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CooperadorService from "../../services/cooperador";

const Verification = () => {
  // Sobre o botão
  const { userId } = useParams();
  const navigator = useNavigate();
  const [VerCooperador, setVerCooperador] = useState({
    imgUrl: "",
    nome: "",
    email: "",
    endereco: "",
    is_verify: "",
  });
  useEffect(() => {
    dados();
    verificado();
  }, []);
  const dados = () => {
    if (userId) {
      CooperadorService.getIDCooperador(userId).then((response) => {
        setVerCooperador(response.data);
      });
    }
  };

  console.log(userId);
  const verificar = async (userId) => {
    await CooperadorService.getIDCooperador(userId)
      .then((response) => {
        Swal.fire({
          width: 300,
          icon: "success",
          text: "Conta Verificada com sucesso ",
          showConfirmButton: true,
        });
        navigator("/login");
      })
      .catch((error) => {
        Swal.fire({
          width: 300,
          icon: "error",
          text: "Erro de Conexão ",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  // const icon = require("../imgs/logo01.png");
  const [btnState, setBtnState] = useState(true);
  const [btnVerif, setbtnVerif] = useState();

  const verificado = () => {};

  let toggleClassCheck = btnState ? "active_cart" : "";
  let toggleClassFilho = btnState
    ? "produtos_da_compra_cart_filho_animation"
    : "";
  return (
    <>
      {/* <div className={`ver_compras_cart`} id="ver_compras_cart"><img src={icon} alt="não encontrado" /></div> */}
      <div className={`produtos_da_compra_cart ${toggleClassCheck}`}>
        <div
          className={`produtos_da_compra_cart_filho animate__animated animate__fadeInDownBig ${toggleClassFilho}`}
        >
          <div>
            <h1>Verificação de Conta </h1>
            <h2
              className="title has-text-centered is-4 m-2"
              style={{
                borderBottom: "1px solid #B46739",
                paddingBottom: "10px",
              }}
            ></h2>
          </div>
          {VerCooperador.is_verify ? (
            <div className="maio_verificar_column">
              <div>
                <h1>
                  Olá {VerCooperador.nome}, por favor verifique a sua conta
                </h1>
              </div>
              <div>
                <button
                  className="botao_verficar_user"
                  onClick={() => verificar(userId)}
                >
                  {" "}
                  Verificar conta
                </button>
              </div>
            </div>
          ) : (
            <div className="maio_verificar_column">
              <div>
                <h1>Esta conta já foi verificada, por favor faça login</h1>
              </div>
              <div>
                <Link to="/login">
                  <button className="botao_verficar_user">Fazer Login</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Verification;
