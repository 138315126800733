import React, { useContext, useEffect, useState } from 'react'
import noticias from "../../services/noticias";
import Swal from "sweetalert2";
import { useParams, Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../../contexts/Auth";

const Resposta = ({ idComentario }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  console.log(idComentario, "ID DO COMENTARIO")

  const logado = user?.codigo;
  const { codigo } = useParams();


  const [VerComentario, setVerComentario] = useState({});
  const [VerResposta, setVerResposta] = useState([]);

  const VerOAsRespostas = async () => {
    await noticias.getRespostas(idComentario)
      .then((response) => {
        setVerResposta(response.data);
      })
      .catch(() => {
        // Swal.fire({
        //   title: "Erro em estabelecer a Conexão"
        // });
      });
  };


  const VerOsComentarios = async () => {
    if (idComentario) {
      await noticias
        .getComentariosCodigo(idComentario).then((response) => {
          setVerComentario(response.data);
          // console.log(response.data, "Dados getview Comerntarios VEJA BEM");
        });
    }
  };


  useEffect(() => {
    VerOAsRespostas();
    VerOsComentarios();
  }, [idComentario]);



  const [resposta, setResposta] = useState({
    conteudo: "",
    comentario: {
      codigo: idComentario,
    },
    visitante: {
      codigo: logado
    },
  });

  // const { conteudo} = resposta;
  const [conteudo, setConteudo] = useState("");

  //Limpar Campos
  const handleClear = () => {
    setConteudo({
      conteudo: "",
    });
  };

  const onInputChange = (e) => {
    setResposta({ ...resposta, [e.target.name]: e.target.value });
  };

  const saveResposta = async (e) => {
    e.preventDefault();

    const resposta = {
      conteudo,
      comentario: {
        codigo: idComentario,
      },
      visitante: {
        codigo: logado
      },
    }

    //
    await noticias
      .createResposta(resposta)
      .then((response) => {
        if (response.data === "saved") {
          Swal.fire({
            title: "Comentário Enviado com sucesso",
            icon: "success",
            confirmButtonColor: "#686d25",
          });
        }
        handleClear();
        // console.log(VerComentario.data)
      })
      .catch(() => {
        Swal.fire({
          title: "Erro de conexão",
          icon: "error",
          confirmButtonColor: "#686d25",
        });
      });
  };




  const pages = Math.ceil(VerResposta.length);
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  //
  const modules = { toolbar: { container } };
  //
  return (
    <>
      {/* <!-- End blog entry --> */}
      <div
        className="blog-comments view_noticia_branca"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <h4 className="comments-count">{pages} Resposta</h4>

        <div
          id="comment-1"
          className="comment"
          style={{
            backgroundColor: "#b46739",
            borderRadius: "10px",
            margin: "10px",
            padding: "10px",
          }}
        >
          <div className="d-flex">
            <div className="comment-img">
              <img src="assets/img/blog/comments-1.jpg" alt="" />
            </div>
            <div>
              <h5>
                <p>
                  {/* <strong>Por :</strong> {VerComentario.visitante.nome} */}
                </p>
              </h5>
              <time datetime="2020-01-01">
                {new Date(VerComentario.date).getDate() === new Date().getDate() &&
                  new Date(VerComentario.date).getMonth() === new Date().getMonth() &&
                  new Date(VerComentario.date).getFullYear() ===
                  new Date().getFullYear() ? (
                  <>
                    Hoje às {new Date(VerComentario.date).getHours()}:
                    {new Date(VerComentario.date).getMinutes()} (
                    {new Date(new Date() - new Date(VerComentario.date)).getHours() - 1 >
                      0 ? (
                      <>
                        {new Date(new Date() - new Date(VerComentario.date)).getHours() -
                          1}
                        h
                      </>
                    ) : (
                      ""
                    )}{" "}
                    {new Date(new Date() - new Date(VerComentario.date)).getMinutes()} min
                    atrás )
                  </>
                ) : (
                  <>
                    {new Date(VerComentario.date).getDate()} de {" "}
                    {meses[new Date(VerComentario.date).getMonth()]} de{" "}
                    {new Date(VerComentario.date).getFullYear()} às{" "}
                    {new Date(VerComentario.date).getHours()}:
                    {new Date(VerComentario.date).getMinutes()}
                  </>
                )}
              </time>
              <p className="view_noticia_letra_preta" style={{ lineHeight: '1.5', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: VerComentario.conteudo }}>

              </p>

            </div>
          </div>
        </div>
      </div>
      {/* <!-- End blog entry --> */}
      {VerResposta.map((resposta) => (
        <div
          id="comment-1"
          className="comment"
          style={{
            backgroundColor: "#b46739",
            borderRadius: "10px",
            margin: "10px",
            padding: "10px",
          }}
        >
          <div className="d-flex">
            <div className="comment-img">
              <img src="assets/img/blog/comments-1.jpg" alt="" />
            </div>
            <div>
              <h5>
                <p>
                  <strong>Por :</strong> {resposta.visitante.nome}
                </p>
              </h5>
              <time datetime="2020-01-01">
                {new Date(resposta.date).getDate() === new Date().getDate() &&
                  new Date(resposta.date).getMonth() === new Date().getMonth() &&
                  new Date(resposta.date).getFullYear() ===
                  new Date().getFullYear() ? (
                  <>
                    Hoje às {new Date(resposta.date).getHours()}:
                    {new Date(resposta.date).getMinutes()} (
                    {new Date(new Date() - new Date(resposta.date)).getHours() - 1 >
                      0 ? (
                      <>
                        {new Date(new Date() - new Date(resposta.date)).getHours() -
                          1}
                        h
                      </>
                    ) : (
                      ""
                    )}{" "}
                    {new Date(new Date() - new Date(resposta.date)).getMinutes()} min
                    atrás )
                  </>
                ) : (
                  <>
                    {new Date(resposta.date).getDate()} de {" "}
                    {meses[new Date(resposta.date).getMonth()]} de{" "}
                    {new Date(resposta.date).getFullYear()} às{" "}
                    {new Date(resposta.date).getHours()}:
                    {new Date(resposta.date).getMinutes()}
                  </>
                )}
              </time>
              <p className="view_noticia_letra_preta" style={{ lineHeight: '1.5', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: resposta.conteudo }}>

              </p>
              {/*  */}
            </div>

          </div>

        </div>
      ))}
      {/*  */}
      <div className="reply-form">
        <form
          onSubmit={(e) => saveResposta(e)}
          style={{ textAlign: "center" }}
        >
          <div className="row"></div>
          <div className="row">
            <div className="col form-group">
              <ReactQuill
                theme="snow"
                modules={modules}
                className='custom-quill'
                name="conteudo"
                value={conteudo}
                onChange={setConteudo}
                style={{ maxHeight: '300px' }} // Defina a altura máxima conforme necessário
              />

              <br />
              {!isAuthenticated ?
                "Entra com sua conta para poder comentar"
                :
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#585e0e",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Responder
                </button>
              }

            </div>
          </div>
        </form>
      </div>


    </>
  );
};


//
let container = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ["blockquote", "code-block"],

  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

//
export default Resposta;

