import { useContext, useState, useEffect } from "react";
import "./menu";

import Swal from "sweetalert2";
import UserPerfil from "../../services/UserPerfil";

import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";

const logo_1 = require("../imgs/logoCWAM.png");
// const logo_2 = require("../imgs/logo-fenacop.png");
const menu = require("../imgs/menu.png");

const Novomenu = () => {
  const { logout, user } = useContext(AuthContext);
  const logado = user?.codigo;

  const handleLogout = () => {
    Swal.fire({
      text: "Deseja mesmo Sair?",
      showCancelButton: true,
      confirmButtonColor: "#9ba235",
      cancelButtonColor: "#b46739",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  const [btnState, setBtnState] = useState(false);

  function vermenu() {
    setBtnState((btnState) => !btnState);
  }
  let toggleClassCheck = btnState ? "open" : "";
  //console.log(authenticated)

  function fixar() {
    alert("fixo");
  }

  const [ver_copo_pesquisa, setver_copo_pesquisa] = useState(false);
  const onInputChange = (e) => {
    if (e.target.name) {
      if (e.target.value.length >= 3) {
        setver_copo_pesquisa(true);
      } else {
        setver_copo_pesquisa(false);
      }
    }
  };
  const chips = [
    "Cooperadores",
    "Animais",
    "Fazendas",
    "Serviços",
    "Leilão",
    "Agenda",
  ];
  const cooperadores = [
    { codigo: 1, nome: "Paulino Tyova" },
    { codigo: 2, nome: "Adriano Baltazar" },
  ];
  const animais = [
    { codigo: 1, nome: "Bovino Nelore" },
    { codigo: 2, nome: "Suínos" },
  ];
  const fazenda = [
    { codigo: 1, nome: "Fazenda Tyova" },
    { codigo: 2, nome: "Fazenda Borges" },
  ];
  const servicos = [
    { codigo: 1, nome: "Capim Fresco" },
    { codigo: 2, nome: "Vacinação de bois" },
  ];


  //METODO LISTAR POR CODIGO IMAGEM IMAGEM
  // const [Vervisitante, setVerVisitante] = useState({});
  //LISTAR
  // const viewVisinteperfil = () => {
  //   UserPerfil.geVisitanteFoto()
  //     .then((response) => {
  //       setVerVisitante(response.data);
  //     })
  //     .catch((error) => {
  //       console.log("Erro ao listar Imagem", error);
  //     });
  // };
  //
  useEffect(() => {
    viewVisinteperfil();
  }, [])

  //METODO LISTAR POR CODIGO IMAGEM IMAGEM
  const [Vervisitante, setVerVisitante] = useState({
    foto: "",
    visitante: {
      codigo: logado,
    },
  });

  const viewVisinteperfil = () => {
    UserPerfil.alterarFotoCodigo2(logado).then((response) => {
      if (response.data) {
        setVerVisitante(response.data);
      } else {
        const dados = {
          foto: "/img/avatar3.svg",
          visitante: {
            codigo: logado,
          },
        };
        setVerVisitante(dados);
        console.log(dados);
      }
    });
  };

  //
  return (
    <>
      <header>
        <nav id="nosso-menu">
          <label htmlFor="tab-nav" className="label">
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </label>
          <div
            className="mobile-menu-icon"
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: "103",
            }}
          >
            {/* <button>
              <img onClick={vermenu} src={menu} className="icon" />
            </button> */}
            <div className="novo_menu_novo" onClick={vermenu} src={menu}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          {/* FOTO DO PERFIL */}
          {user ? (
            <Link to={`/perfilDados`} className="avatar_do_usuario">
              {/* <img src="/img/avatar3.svg" alt="" width="50px" /> */}

              {Vervisitante.foto === "/img/avatar3.svg" ? (
                <img src="/img/avatar3.svg" alt="" width="50px" />
              ) : (
                //
                <div>
                  <span key={Vervisitante.codigo} className="foto-perfil-cima">
                    <img
                      src={`${Vervisitante.foto}`}
                    />
                  </span>
                </div>
              )}
              {/*  */}
            </Link>
          ) : (
            ""
          )}

          <div className="columns flex_dois_responsive">
            <div className="column">
              <Link to="/" className="logo">
                <img src={logo_1}
                  style={{ width: "130px", height: "100px" }}
                  className="img_logo" />
                {/* <img src={logo_2} className="img_logo" id="logo_2" /> */}
              </Link>
            </div>

            <div className="column">
              <div className="estilo_botao_pesquisa">
                <div
                  className="buscar estilo_botao_pesquisa_input"
                  style={{ position: "absolute", top: "-15px", right: "0" }}
                >
                  {/* <input
                    type="text"
                    placeholder="Procurar..."
                    name="pesquisa"
                    onChange={(e) => onInputChange(e)}
                  /> */}
                  {ver_copo_pesquisa ? (
                    <div className="estilo_botao_pesquisa_corpo">
                      <div className="chips_pesquisa">
                        {chips.map((chip, index) => (
                          <div>{chip}</div>
                        ))}
                      </div>
                      <div>
                        <div className="categoria_pesq cooperadores_pesq">
                          <h1>Cooperadores</h1>
                          <ul>
                            {cooperadores.map((cooperador) => (
                              <li>{cooperador.nome}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="categoria_pesq fazendas_pesq">
                          <h1>Fazendas</h1>
                          <ul>
                            {fazenda.map((fazenda) => (
                              <li>{fazenda.nome}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="categoria_pesq animais_pesq">
                          <h1>Animais</h1>
                          <ul>
                            {animais.map((animal) => (
                              <li>{animal.nome}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="categoria_pesq animais_pesq">
                          <h1>Serviços</h1>
                          <ul>
                            {servicos.map((servico) => (
                              <li>{servico.nome}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/*  */}
              <div
                className="buscar"
                style={{ position: "absolute", top: "50px", right: "0" }}
              >
                {/*  */}
              </div>

              {/*  */}
            </div>
          </div>

          <div className="columns" id="menu">
            <ul className="content_nav">
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <a>Cooperativa</a>

                <div className="sub_menu-1">
                  <ul>
                    <li>
                      <Link to="/cooperadores">Cooperado</Link>
                    </li>
                    <li>
                      <Link to="/fazendas">Fazendas</Link>
                    </li>
                    <li>
                      <Link to="/animais">Animais</Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* <li>
                <a>Leilão</a>
                <div className="sub_menu-1">
                  <ul>
                    <li style={{ width: "200px" }}>
                      <Link to="/leilao">Leilão nas Fazendas</Link>
                    </li>
                    <li style={{ width: "200px" }}>
                      <Link to="/leilao_exclusivo">Leilão Exclusivo</Link>
                    </li>
                  </ul>
                </div>
              </li> */}
              <li>
                <a>Actividades</a>
                <div className="sub_menu-1">
                  <ul>
                    <li>
                      <Link to="/venda">Comércio</Link>
                    </li>
                    <li>
                      <Link to="/servicos">Serviços</Link>
                    </li>
                    <li>
                      <Link to="/agenda">Agendas</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/sobre">Sobre</Link>
              </li>
              <li>
                <a href="#contato">Contacto</a>
              </li>
              {/* Modelo de teste */}
              {!user ? (
                <li>
                  <Link to="/login">Entrar</Link>
                </li>
              ) : (
                <li></li>
              )}
            </ul>
          </div>
        </nav>
        <div
          className={`menu-mobile ${toggleClassCheck} animate__animated animate__fadeInDown`}
        //className="menu-mobile"
        >
          <div className="logo_menu_mobile">
            <a className="logo logo-mobile">
              <img src={logo_1} className="img_logo|" />
              {/* <img src={logo_2} className="img_logo" id="logo_2" /> */}
            </a>
          </div>

          <ul className="content_nav">
            <li>
              <Link to="/" onClick={vermenu}>
                Inicio
              </Link>
            </li>
            <li>
              <a>Cooperativa</a>
              <div className="sub_menu-1">
                <ul>
                  <li>
                    <Link to="/cooperadores" onClick={vermenu}>
                      Cooperado
                    </Link>
                  </li>
                  <li>
                    <Link to="/fazendas" onClick={vermenu}>
                      Fazendas
                    </Link>
                  </li>
                  <li>
                    <Link to="/animais" onClick={vermenu}>
                      Animais
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/* <li>
              <a>Leilão</a>
              <div className="sub_menu-1">
                <ul>
                  <li>
                    <Link to="/leilao" onClick={vermenu}>
                      Leilão nas Fazendas
                    </Link>
                  </li>
                  <li>
                    <Link to="/leilao_exclusivo" onClick={vermenu}>
                      Leilão Exclusivo
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}
            {/* <li>
              <Link to="/leilao" onClick={vermenu}>
                Leilão
              </Link>
            </li> */}
            <li>
              <a>Actividades</a>
              <div className="sub_menu-1">
                <ul>
                  <li>
                    <Link to="/venda" onClick={vermenu}>
                      Comércio
                    </Link>
                  </li>
                  <li>
                    <Link to="/servicos" onClick={vermenu}>
                      Serviços
                    </Link>
                  </li>
                  <li>
                    <Link to="/agenda" onClick={vermenu}>
                      Agendas
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to="/sobre" onClick={vermenu}>
                Sobre
              </Link>
            </li>
            <li>
              <a href="#contactos" onClick={vermenu}>
                Contacto
              </a>
            </li>
            {!user ? (
              <li>
                <Link to="/login" onClick={vermenu}>
                  Entrar
                </Link>
              </li>
            ) : (
              <li></li>
            )}
          </ul>
        </div>
      </header>
    </>
  );
};
export default Novomenu;
